function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill="#39594D" />
      <g clipPath="url(#clip0_325_16157)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.184 13.536C9.61067 13.536 10.464 13.5147 11.6587 13.024C13.0453 12.448 15.776 11.424 17.76 10.3573C19.1467 9.61067 19.744 8.62933 19.744 7.30667C19.744 5.49333 18.272 4 16.4373 4H8.75733C6.13333 4 4 6.13333 4 8.75733C4 11.3813 6.00533 13.536 9.184 13.536Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4854 16.8C10.4854 15.52 11.2534 14.3467 12.448 13.856L14.8587 12.8533C17.312 11.8507 20 13.6427 20 16.288C20 18.336 18.336 20 16.288 20H13.664C11.9147 20 10.4854 18.5707 10.4854 16.8Z"
          fill="white"
        />
        <path
          d="M6.752 14.1547C5.23733 14.1547 4 15.392 4 16.9067V17.2693C4 18.7627 5.23733 20 6.752 20C8.26667 20 9.504 18.7627 9.504 17.248V16.8853C9.48267 15.392 8.26667 14.1547 6.752 14.1547Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_16157">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
