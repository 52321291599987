const { useRef, useState, useEffect } = require('react');
// Icons
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import styled from 'styled-components';

const ChipsArray = ({ className, chips, onRemove, capabilities }) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    checkScrollButtons();
  }, [chips]);

  const handleScroll = () => {
    checkScrollButtons();
  };

  const checkScrollButtons = () => {
    const container = containerRef.current;

    const scrollLeftPossible = container.scrollLeft > 0;
    setCanScrollLeft(scrollLeftPossible);

    const scrollRightPossible =
      container.scrollWidth > container.clientWidth + container.scrollLeft;
    setCanScrollRight(scrollRightPossible);
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const getIcon = (icon) => {
    const Icon = get(outlineIcons, icon);
    return Icon ? <Icon className={'h-6 w-6'} /> : null;
  };

  const getCapabilityIcon = (model) => {
    if (model.function_calls && model.function_calls.length) {
      // find capability
      let modelCapabilities = capabilities.filter((capability) =>
        model.function_calls
          .map((func) => func.capability)
          .includes(capability._id)
      );
      return (
        modelCapabilities.length > 0 && (
          <Shortcut onMouseEnter={() => setIsHovering(false)}>
            {getIcon(modelCapabilities[0].icon, 'w-4 h-4')}
            <Tooltip className="absolute font-medium flex flex-col items-center left-[calc(100%_-_2.5rem)] top-1/2">
              <div className="relative z-50 p-2 text-sm leading-none text-raisin-black bg-lavender bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                {modelCapabilities.map((capability, index) => (
                  <div key={index}>
                    Capability:{' '}
                    <span className="font-bold">{capability.name}</span>
                  </div>
                ))}
              </div>
            </Tooltip>
          </Shortcut>
        )
      );
    }
  };

  return (
    <div className={`${className} relative px-1`}>
      {canScrollLeft && (
        <div
          className="flex h-full items-center cursor-pointer bg-gradient-to-r from-lavender to-transparent absolute top-0 left-0 z-20"
          onClick={scrollLeft}
        >
          <ChevronDoubleLeftIcon
            className="w-5 h-5 mr-3"
            disabled={!canScrollLeft}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      )}
      <div
        className={`flex ${
          isHovering ? 'overflow-x-hidden' : 'overflow-x-clip'
        } whitespace-nowrap gap-1 font-figtree`}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {chips.map((item, index) => (
          <div
            key={index}
            className="relative select-none bg-ghost-white dark:bg-quicksilver font-figtree font-semibold text-sm text-cool-gray dark:text-crystal-bell px-2 py-[2px] rounded flex items-center"
          >
            <span>{item.abbreviation}</span>
            {getCapabilityIcon(item)}
            {chips.length > 1 && (
              <XMarkIcon
                className="cursor-pointer ml-1 w-4 h-4"
                onClick={() => onRemove(item)}
              />
            )}
          </div>
        ))}
      </div>
      {canScrollRight && (
        <div
          className="flex h-full items-center cursor-pointer bg-gradient-to-l from-lavender to-transparent absolute top-0 right-0 z-20"
          onClick={scrollRight}
        >
          <ChevronDoubleRightIcon
            className="w-5 h-5 ml-3"
            disabled={!canScrollRight}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      )}
    </div>
  );
};

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

export default ChipsArray;
