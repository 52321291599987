/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// SEO
import { Helmet } from 'react-helmet';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import {
  ArrowLeftIcon,
  ArrowLongRightIcon,
  BookOpenIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  FolderPlusIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  CurrencyDollarIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
// Libs
import moment from 'moment';
import { useAudioRecorder } from 'react-audio-voice-recorder';
// Context
import { useChat } from '../../Context/Chat/Chat.context';
// Hooks
import { useMediaQuery, useTheme } from '@mui/material';
import useCopyWithoutFormat from 'Hooks/useCopyWithoutFormat';
import useDebounce from 'Hooks/useDebounce';
import { toastReward } from 'utils/toastCall';
import { parseQueryString } from 'utils/urlParse';
// React router
import ModelDialog from 'Components/ModelDialog';
import { useLocation, useNavigate } from 'react-router-dom';
// MUI
// Toast
import toast from 'react-hot-toast';
// cosntants
import { highlightWordsTextAreaV2 } from 'Constants/chat';
// Axios
import LinearProgress from '@mui/material/LinearProgress';
import ActionsChat from 'Components/Chat/ActionsChat/ActionsChat';
import { useFoldersChatStore } from 'Components/Chat/DragAndDropFolders/store';
import useShareLinkTool from 'Components/Chat/PromptModal/store';
import useTextAreaFocus from 'Components/Chat/TextAreaHightlight/state';
import useChatStore, {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
  LOADING_FAV_TOOLS,
} from 'Components/Chat/store';
import useComingSoonModal from 'Components/Common/ComingSoonModal/store';
import ChatMessage from 'Components/Common/Markdown/ChatMessage';
import FileContext from 'Components/FileContext';
import ImageDialog from 'Components/ImageDialog';
import Loader from 'Components/Loader';
import TextSpeechDialog from 'Components/TextSpeechDialog';
import useDeviceInfo from 'Hooks/useDeviceInfo';
import ToolSvg from 'Theme/icons/Tool.svg';
import axios from 'axios';
import config from 'config';
import { motion } from 'framer-motion';
import Button from '../../Components/Button';
import ConversationSide from '../../Components/Chat/ConversationSide/ConversationSide';
import PromptSide from '../../Components/Chat/PromptSide/PromptSide';
import TextAreaHightlight from '../../Components/Chat/TextAreaHightlight/TextAreaHightlight';
import ToolsList from '../../Components/Chat/ToolsList/ToolsList';
import DeleteChat from './Components/DeleteChat';
import EditTitle from './Components/EditTitle';
import ShareChat from './Components/ShareChat/ShareChat';
import { Shortcut } from './styled';
import { formatTime, formatedDate } from './utils/format';

//New file icons
import TemplateModal from 'Components/Chat/TemplateModal';
import DeleteTemplateModal from 'Components/Chat/TemplateModal/components/DeleteTemplateModal';
import useTemplateModalStore from 'Components/Chat/TemplateModal/store';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import { buildDataTreeDNDChat } from 'Components/Chat/TreeDNDChat/Utils/functions';
import ChipsArray from 'Components/ChipsArray';
import ModelWarningDialog from 'Components/ModelWarningDialog';
import useDisableIOSZoom from 'Hooks/useDisableIOSZoom';
import useThemeStore from 'Theme/store';

import useChatMessageStore from 'Components/Common/Markdown/store';
import FavoriteMessages from 'Components/Common/Markdown/FavoriteMessages';

import CoinsAlertModal from 'Components/Chat/CoinsAlertModal';
import WordcapLimitsDialog from 'Components/WordcapLimitsDialog';
import AttachedFiles from './Components/AttachedFiles/AttachedFiles';

import useAlertCoinsStore from 'Components/Chat/CoinsAlertModal/store';
import PromptLibrary from 'Components/Chat/PromptLibrary';
import usePromptLibraryStore from 'Components/Chat/PromptLibrary/store';
import ScrollableToolsSection from 'Components/ScrollableToolsSection';
import CreateTemplatesForm from 'Features/CreateTemplatesForm';
import useCatalog from './logic/useCatalog';
const dummyId = () => {
  const hexaChars = '0123456789abcdef';
  let id = '';

  for (let i = 0; i < 24; i++) {
    const randomIndex = Math.floor(Math.random() * hexaChars.length);
    id += hexaChars.charAt(randomIndex);
  }

  return id;
};

let onNewChat;

const Chat = inject('store')(
  observer(({ store }) => {
    useCopyWithoutFormat();
    useDisableIOSZoom();

    const location = useLocation();
    const navigate = useNavigate();

    // Theme
    const theme = useTheme();
    // Media query for mobile
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [inputError] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [deleted, setDeleted] = useState(false);
    // const [refFile, setRefFile] = useState(undefined);
    const [showMultiFileToggled, setShowMultiFileToggled] = useState(false);
    const [showFileDialog, setShowFileDialog] = useState(false);
    const [showTextSpeechDialog, setShowTextSpeechDialog] = useState(false);
    // const [showModelsDialog, setShowModelsDialog] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [showLimitDialog, setShowLimitDialog] = useState(false);

    const [loadingBar, setLoadingBar] = useState(false);
    const [axiosSource, setAxiosSource] = useState(false);
    const [cancel, setCancel] = useState(false);

    const userMessage = useRef(null);
    const dummy = useRef(null);
    const chatId = useRef(null);

    // const [selectedModels, setSelectedModelsHook] = useState([]);

    const [scriptValue, setScriptValue] = useState(null);

    const [welcomeMessage, setWelcomeMessage] = useState(['', '']);
    const stopped = useRef(false);

    const setSelectedModels = (models) => {
      oldSelectedModels.current = sortByAttribute(models, 'abbreviation');
      updateSelectedModels((_) => sortByAttribute(models, 'abbreviation'));
      localStorage.setItem('currentModels', JSON.stringify(models));

      // For editing a message
      setEditMessChange();
    };

    const defaultOptions = [
      { name: 'New Chat', isHidden: false },
      { name: 'Select Model', isHidden: false },
      { name: 'Text To Speech', isHidden: false },
      { name: 'Open prompt templates menu', isHidden: false },
    ];

    const [filteredOptions, setFilteredOptions] = useState(defaultOptions);

    useEffect(() => {
      if (!inputValue) {
        setFilteredOptions(defaultOptions);
      } else {
        const sanitizedInput = inputValue
          .toLowerCase()
          .replace(/[^a-z0-9 ]/g, '');
        const updatedOptions = defaultOptions.map((option) => ({
          ...option,
          isHidden: !option.name.toLowerCase().includes(sanitizedInput),
        }));
        setFilteredOptions(updatedOptions);
      }
    }, [inputValue]);

    const { setEditMessChange } = useChatMessageStore();

    const [capabilitiesList, setCapabilitiesList] = useState([]);

    const sortByAttribute = (arr, attribute) => {
      return arr.sort((a, b) => {
        if (a[attribute] < b[attribute]) {
          return -1;
        }
        if (a[attribute] > b[attribute]) {
          return 1;
        }
        return 0;
      });
    };

    // Url params control
    const setChatUrlValue = (value) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('chat', value);
      const newSearch = `?${searchParams.toString()}`;
      navigate(`${location.pathname}${newSearch}`, { shallow: true });
    };
    const removeChatUrlValue = () => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('chat');
      const newSearch = `?${searchParams.toString()}`;
      navigate(`${location.pathname}${newSearch}`, { shallow: true });
    };

    // activate delete dialog
    const {
      openDeleteChatModal,
      setOpenDeleteChatModal,
      toDelete: toDeleteDND,
      setToDelete: setToDeleteDND,
    } = useTreeDNDChatStore();

    useEffect(() => {
      (async () => {
        await getCapabilities();
      })();
    }, []);

    const getCapabilities = async () => {
      try {
        const response = await store.api.get(`/capability`);
        setCapabilitiesList(response.data.capabilities);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (openDeleteChatModal) {
        setShowDeleteDialog(true);
        setOpenDeleteChatModal(false);
      }
    }, [openDeleteChatModal]);

    useEffect(() => {
      if (toDeleteDND) {
        setToDelete(toDeleteDND);
      }
    }, [toDeleteDND]);

    // Context
    const {
      lastestPrompt,
      lastestTool,
      latestRequestId,
      cleanLastestFields,
      setValuesAfterInsert,
      tools,
      showPromptSide,
      promptListRef,
      textareaRef,
      toggleShowPromptSide,
      getToolsAndFilter,
      activePromptIndex,
      setActivePromptIndex,
      selectedTool,
      setSelectedTool,
      selectedPrompt,
      setSelectedPrompt,
      optionsPrompt,
      setOptionsPrompt,
      getPromptsByTool,
      showPromptModal,
      toggleShowPromptModal,
      openShowPromptModal,
      showPromptList,
      setShowPromptList,
      showMobileChats,
      setShowMobileChats,
      showMobilePrompts,
      setShowMobilePrompts,
      promptValue,
      setPromptValue,
      handleShowMobilePrompts,
      sidebarTools,
      getToolsAndFilterSidebar,
      promptListByTool,
      showConversationsSide,
      setShowConversationsSide,
      setOnSendRef,
    } = useChat();

    /**
     * This is the logic to manage open the template modal
     */
    const { setOpenModal: setOpenModalTemplate } = useTemplateModalStore();
    useEffect(() => {
      setOpenModalTemplate(showPromptModal);
    }, [showPromptModal]);

    const chatList = useChatStore((state) => state.chatList);
    const selectedChat = useChatStore((state) => state.selectedChat);
    const loadingChats = useChatStore((state) => state.loadingChats);
    const setChatsList = useChatStore((state) => state.setChatsList);
    const setSelectedChat = useChatStore((state) => state.setSelectedChat);
    const cleanSelectedChat = useChatStore((state) => state.cleanSelectedChat);
    const selectChat = useChatStore((state) => state.selectChat);
    const chatWords = useChatStore((state) => state.chatWords);
    const setChatWords = useChatStore((state) => state.setChatWords);
    const refFile = useChatStore((state) => state.refFile);
    const setRefFile = useChatStore((state) => state.setRefFile);
    const updateRefFile = useChatStore((state) => state.updateRefFile);

    const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);
    const selectedModels = useChatStore((state) => state.selectedModels);
    const updateSelectedModels = useChatStore(
      (state) => state.updateSelectedModels
    );
    const showModelsDialog = useChatStore((state) => state.showModelsDialog);
    const setShowModelsDialog = useChatStore(
      (state) => state.setShowModelsDialog
    );
    const runEvent = useChatStore((state) => state.runEvent);
    const addEvent = useChatStore((state) => state.addEvent);
    const currentEvent = useChatStore((state) => state.currentEvent);
    // Folder manage STATE
    const { treeData, setTreeData, page, setPage, setTotalPage } =
      useTreeDNDChatStore();
    const { setModalOpen } = useFoldersChatStore();

    // Images variables
    const [imagesPreview, setImagesPreview] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [params, setParams] = useState({});
    const [behaviours, setBehaviours] = useState([]);
    const [attrs, setAttrs] = useState({});
    const [currentPrompt, setCurrentPrompt] = useState(null);
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    //id of chat
    const [xchatId, setXchatId] = useState(null);

    const dummyMessage = useRef(null);
    const lastModel = useRef(null);
    const toolData = useRef(null);
    const streamCode = useRef(null);
    const loadingResponse = useRef(false);

    //--------------------AUTO SCROLL CHAT-----------------------------------------
    const chatContainer = useRef(null);
    const [autoScroll, setAutoScroll] = useState(true);
    const messageRefs = useRef({ byId: {}, byDate: {} }); // Refs for messages

    const handleScroll = () => {
      if (!chatContainer.current) return;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { scrollTop, scrollHeight, clientHeight } = chatContainer?.current;
      if (scrollHeight - scrollTop === clientHeight) {
        setAutoScroll(true);
      } else {
        setAutoScroll(false);
      }
    };

    useEffect(() => {
      if (currentEvent) {
        eventActions(currentEvent);
      }
    }, [currentEvent]);

    const eventActions = (event) => {
      switch (event.name) {
        case EVENT_NEW_CHAT:
          runEvent(onNewChat);
          break;
        case EVENT_SELECT_CHAT:
          runEvent(onSelectChat);
          break;
        case SCRIPT_FOR_TTS:
          runEvent(handleScriptForTTS);
          break;
      }
    };

    useEffect(() => {
      const container = chatContainer.current;

      if (autoScroll && container) {
        container.scrollTop = container.scrollHeight + 100;
      }

      const handleUserScroll = () => {
        if (!container) return;
        const { scrollTop, scrollHeight, clientHeight } = container;
        const atBottom = scrollHeight - scrollTop === clientHeight;
        if (!atBottom) {
          setAutoScroll(false);
        }
      };

      if (container) {
        container.addEventListener('scroll', handleUserScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleUserScroll);
        }
      };
    }, [autoScroll]);
    //------------------------------AUTO SCROLL CHAT----------------------------------------

    const scrollBottom = () => {
      if (chatContainer.current) {
        chatContainer.current.scrollTop =
          chatContainer.current.scrollHeight + 100;
      }
    };

    // Capabilities variables
    const [capabilities, setCapabilities] = useState(
      JSON.parse(localStorage.getItem('capabilities')) || []
    );

    useEffect(() => {
      (async () => {
        try {
          let defaultModel = localStorage.getItem('multimodelDefault');

          if (!defaultModel) {
            localStorage.removeItem('currentModels');
          }
          let currentModels = localStorage.getItem('currentModels');
          const responseMultiModal = await store.api.get(
            `/model/multimodelDefault`
          );
          //TODO cuando lo llama al principio
          if (currentModels === responseMultiModal.data.models) {
            setSelectedModels(JSON.parse(currentModels));
          } else {
            if (responseMultiModal.data.models.length === 0) {
              const response = await store.api.get(`/model/default`);
              setSelectedModels([response.data.model]);
              localStorage.setItem(
                'currentModels',
                JSON.stringify([response.data.model])
              );
              localStorage.setItem(
                'multimodelDefault',
                JSON.stringify([response.data.model])
              );
            } else {
              setSelectedModels(responseMultiModal.data.models);

              localStorage.setItem(
                'multimodelDefault',
                JSON.stringify(responseMultiModal.data.models)
              );

              localStorage.setItem(
                'currentModels',
                JSON.stringify(responseMultiModal.data.models)
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
        setWelcomeMessage(getHello());
      })();
    }, []);

    useEffect(() => {
      setAvatar(store.profile.avatar.svg);
      setShowConversationsSide(true);
      if (!isMobile) {
        toggleShowPromptSide();
      }

      const eventHandler = (event) => {
        if (dummy.current && !stopped.current && loadingResponse.current) {
          if (dummyMessage.current && dummyMessage.current.length > 1) {
            const index = dummyMessage.current.findIndex(
              (m) => m.modelRef == event.modelRef
            );
            dummyMessage.current[index] = event;
          } else {
            dummyMessage.current = event;
          }

          setSelectedChat({
            ...dummy.current,
            messages: [dummyMessage.current, ...dummy.current.messages],
          });

          if (
            !chatId ||
            (event.chatId !== null && event.chatId !== chatId.current)
          ) {
            chatId.current = event.chatId;
          }
        }
      };

      store.listenEvent('writing', eventHandler);
      return () => {
        store.removeListener('writing', eventHandler);
      };
    }, []);

    const oldSelectedModels = useRef(null);
    useEffect(() => {
      updateDummyMessage(oldSelectedModels.current ?? []);
      streamCode.current = btoa(JSON.stringify(oldSelectedModels.current));
    }, [selectedModels]);

    const updateDummyMessage = (models) => {
      if (models.length == 0) {
        dummyMessage.current = null;
      } else if (models.length > 1) {
        dummyMessage.current = models.map((model) => {
          return {
            model: model.abbreviation,
            modelRef: model._id,
            data: '',
          };
        });
      } else {
        dummyMessage.current = {
          model: models[0].abbreviation,
          modelRef: models[0]._id,
          data: '',
        };
      }
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    const getChats = async () => {
      setLoadingBar(true);
      setLoading(true);
      try {
        const res = await store.api.get(`/chat/v1/folders?page_number=${1}`);
        const data = await res.data;
        const chats = data.chats;

        setPage(data.currentPage);
        setTotalPage(data.totalPages);

        const newTree = buildDataTreeDNDChat(chats);
        setTreeData(newTree);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoading(false);
        setLoadingBar(false);
      }
    };

    /**
     * Get chat by hash from url
     * @param {string} hash
     * @returns
     */
    const isFirstLoad = useRef(true);

    useEffect(() => {
      if (isFirstLoad.current) {
        const hash = parseQueryString(location.search)?.chat;
        if (hash !== selectedChat?.chat?._id) {
          if (hash && chatList?.length > 0) {
            getChat(hash);
            selectChat(hash);
            setShowMobileChats(false);
          } else if (hash) {
            getChat(hash);
          }
        }

        isFirstLoad.current = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Open prompt modal when user select a tool
    const handleSelectPrompt = async (id) => {
      if (sidebarTools.length === 0) await getToolsAndFilterSidebar('');
      const selectedToolLocal = sidebarTools.filter(
        (tool) => tool?._id === id
      )[0];
      setSelectedTool(selectedToolLocal);
      openShowPromptModal();
      setShowPromptList(false);
    };

    useEffect(() => {
      const hash = new URLSearchParams(location.search).get('selectedTool');
      if (hash && sidebarTools?.length > 0) {
        handleSelectPrompt(hash);
      }
    }, [sidebarTools]);

    const getChat = async (hash, messageIdentifier = null, isUser = null) => {
      setLoadingBar(true);
      setChatWords(0);

      try {
        let hashShared = null;
        if (sessionStorage.getItem('redirectUrl')) {
          const res = await store.api.post(`/chat/clone/${hash}`);
          const data = await res.data;
          hashShared = data?.chat?._id;
          sessionStorage.removeItem('redirectUrl');
        }
        const localHash = hashShared ?? hash;
        // add query params url
        setChatUrlValue(localHash);
        /* 
        This is important when change chat 
        remove the animation to fix the bug
        */
        setLoading(false);
        //
        const res = await store.api.get('/chat/' + localHash);
        setChat(res.data.chat);
        setXchatId(localHash);
        // setSelectedChat(res.data.chat);
        // setChatSelected(res.data.chat);
        if (res.data.chat.files) {
          setRefFile(res.data.chat.files);
        }

        if (res.data.chat.messages && res.data.chat.messages.length) {
          countTotalWords(res.data.chat.messages);
          try {
            const lastMessage = res.data.chat.messages[0];
            const modelRef = Array.isArray(lastMessage)
              ? lastMessage.map((m) => m.modelRef)
              : lastMessage.modelRef;
            const response = await store.api.get('/model/byId', {
              params: {
                default_model_template: modelRef,
              },
            });
            if (response.data.model.length > 0) {
              if (store.profile.plan == 'free') {
                try {
                  const response2 = await store.api.get(`/model`);
                  let availableModels = response2.data.models;

                  const res = await store.api.get('/user/constant', {
                    params: {
                      type: 'princing_plans',
                    },
                  });
                  let freePlanModels = []; // Array to store models allowed for free users

                  freePlanModels = res.data.rules[0].models;

                  const modelsInFreePlan = []; // Array to store models available for free users
                  const otherModels = []; // Array to store models not available for free users

                  availableModels.forEach((model) => {
                    if (freePlanModels.includes(model.model)) {
                      modelsInFreePlan.push(model);
                    } else {
                      otherModels.push(model);
                    }
                  });

                  let filterModels = modelsInFreePlan.filter((model) =>
                    response.data.model.map((m) => m._id).includes(model._id)
                  );

                  if (filterModels.length >= 1) {
                    setSelectedModels(response.data.model);
                  } else {
                    setSelectedModels([availableModels[0]]);
                  }
                } catch (error) {
                  console.error(error);
                }
              } else {
                setSelectedModels(response.data.model);
              }
              localStorage.setItem(
                'currentModels',
                JSON.stringify(response.data.model)
              );
            } else {
              let defaultModel = localStorage.getItem('defaultModel');
              if (defaultModel) {
                setSelectedModels([JSON.parse(defaultModel)]);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }

        // If messageIdentifier is passed, scroll to that message
        if (messageIdentifier !== null && isUser !== null) {
          scrollToMessage(messageIdentifier, isUser);
        } else {
          goToDown();
        }
      } catch (error) {
        removeChatUrlValue();
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    };

    const countTotalWords = (messages) => {
      let chatWordsCount = 0;

      messages.forEach((item) => {
        // Get selected message
        let temp = Array.isArray(item)
          ? item.filter((m) => m.selected == true)
          : [item];
        let msg = temp.length > 0 ? temp[0] : null;

        if (msg && msg.data && msg.data.content) {
          if (typeof msg.data.content == 'string') {
            const wordsContent = store.countWords(msg.data.content);
            chatWordsCount += wordsContent;
          } else {
            for (let content of msg.data.content) {
              if (content.type == 'text') {
                const wordsContent = store.countWords(content.text);
                chatWordsCount += wordsContent;
              } else if (content.type == 'image_url') {
                const visionModel = selectedModels.some(
                  (model) => model.type == 'vision'
                );
                chatWordsCount += visionModel ? content.image_url.words : 0;
              }
            }
          }
        }
      });
      setChatWords(chatWordsCount);
    };
    const goToDown = () => {
      if (chatContainer && chatContainer.current) {
        const containerElement = chatContainer.current;
        const scrollPosition =
          containerElement.scrollHeight - containerElement.offsetHeight;

        const animateScroll = () =>
          containerElement.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });

        requestAnimationFrame(animateScroll);
      }
    };

    const scrollToMessage = (identifier, isUserMessage) => {
      if (isUserMessage === null) {
        return;
      }

      const messageElement = isUserMessage
        ? messageRefs.current.byDate[identifier]
        : messageRefs.current.byId[identifier];

      if (messageElement && chatContainer.current) {
        const containerElement = chatContainer.current;
        const scrollPosition =
          messageElement.offsetTop - containerElement.offsetTop;

        const animateScroll = () =>
          containerElement.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });

        requestAnimationFrame(animateScroll);
      }
    };

    // cancel chat
    const cancelChat = () => {
      if (!stopped.current && userMessage && userMessage.current) {
        toast('Switching windows interrupts response', {
          icon: '📢',
        });
      }
      if (axiosSource) {
        setCancel(true);
        userMessage.current = null;
        axiosSource.cancel();
      }
    };
    // redirect to path with query
    const redirectToPathWithQuery = (query) => {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams({
            ...parseQueryString(location.search, query),
          }).toString(),
        },
        { shallow: true }
      );
    };
    // go back chat
    const goBackChat = () => {
      redirectToPathWithQuery(location.pathname, parseQueryString('chat'));
      dummy.current = { _id: null, messages: [] };
      cleanSelectedChat();
      setRefFile(null);
    };
    // set chat
    const setChat = (chat) => {
      setSelectedChat(chat);
      dummy.current = chat;
      // moveDown();
    };

    const containsVisionModel = () => {
      return (
        selectedModels.length &&
        selectedModels.some((model) => model.type === 'vision')
      );
    };

    const {
      setOpenModal: setCoinsAlertOpen,
      dissmissed: dissmissedAlertCoins,
    } = useAlertCoinsStore();

    const handleConfirmAlertCoins = () => {
      onSend(null, undefined, undefined, undefined, true);
      setCoinsAlertOpen(false);
    };
    const handleCloseAlertCoins = () => {};

    const continueResponse = () => {
      onSend(null, 'continue', undefined, undefined, true);
    };

    // on send message
    const onSend = async (
      e,
      inputText = undefined,
      modelsId = undefined,
      files,
      proceedCoins = false
    ) => {
      let inputVal = inputValue;

      if (!dissmissedAlertCoins) {
        // Alert user if the message is over 1000 coins
        const coins = calculateCoins(getChatWords(inputValue));

        if (coins >= 1000) {
          setCoinsAlertOpen(true);
          if (!proceedCoins) {
            return;
          }
        }
        // ------------------------------
      }

      if (inputText) {
        inputVal = inputText;
      }
      stopped.current = false;
      oldSelectedModels.current = selectedModels;
      // Validate if is loading
      if (loading)
        return toast('Wait a moment, the chat is being processed', {
          icon: '📢',
        });

      // Validate if the user message is not null or empty
      if (!inputVal || inputVal.trim() === '') {
        toast("Don't forget: Messages need text too!", {
          icon: '😱',
        });
        return;
      }

      // Send the message logic
      onCloseBoth();
      setLoading(true);
      loadingResponse.current = true;

      const date = moment().utc();
      const intputSend = containsVisionModel()
        ? visionInput(inputVal)
        : inputVal;
      const oldInputValue = inputVal;
      const oldImagesPreview = imagesPreview;
      try {
        const userInput = {
          user: store.profile._id,
          data: { role: 'user', content: intputSend },
          date: date,
        };

        userMessage.current = userInput;
        setChat({
          ...selectedChat?.chat,
          messages: [userInput, ...(selectedChat?.chat?.messages ?? [])],
        });

        const tool = lastestTool;
        const prompt = lastestPrompt;
        cleanLastestFields();
        setInputValue('');
        setImagesPreview([]);
        setAutoScroll(true);

        if (
          tool?._id == '65805947d92e370a3d5625c6' || // Dall-E 3
          tool?._id == '66c786d7e26442bc593e575d' // Flux Pro
        ) {
          toast(
            'Note: Image generation may take a few minutes. Thank you for your patience.',
            {
              icon: '⏳',
            }
          );
        }

        const notNew = Boolean(selectedChat.chat._id);
        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);
        let selectedModelsId = selectedModels.map((model) => model._id);
        let filesTosend = null;
        if (refFile) {
          filesTosend = refFile;
        } else if (!refFile && typeof files !== 'undefined') {
          if (files.length > 0) {
            filesTosend = files;
          }
        }
        if (modelsId) {
          selectedModelsId = modelsId;
        }
        const response = await store.api.post(
          `/ai/chat`,
          {
            message: intputSend,
            idTool: tool?._id,
            hash: selectedChat?.chat?._id,
            date: date,
            files: filesTosend,
            idModel: selectedModelsId,
            coins: calculateCoins(getChatWords(inputVal)),
            params: params,
            behaviours: behaviours,
            currentPrompt: currentPrompt ? currentPrompt.name : null,
            capabilities: capabilities.map((capability) => capability._id),
            ...attrs,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;
        if (!notNew || selectedChat?.chat?.isShared) await getChats();

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/coin.gif',
              time: 10000,
            });
          }
        } else {
          if (tool && prompt) {
            await store.api.post(`/chat-analyze/create`, {
              tool: tool?._id,
              prompt: promptListByTool?.filter(
                (p) => p.name === prompt.title
              )[0]?._id,
              chat: response.data.hash,
            });
          }

          setChatUrlValue(response.data.hash);
          setXchatId(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message].map((m) => {
                    return {
                      ...m,
                      selected:
                        lastModel.current && lastModel.current == m.modelRef,
                    };
                  })
                : { ...response.data.message },
              {
                user: store.profile._id,
                data: { role: 'user', content: intputSend },
                date: date,
                tool: tool ? tool._id : null,
              },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          // Flux Pro: NSFW content detection
          if (tool?._id == '66c786d7e26442bc593e575d') {
            if (Array.isArray(response?.data?.message?.data?.content)) {
              const contentArray = response.data.message.data.content;
              const hasNsfw = contentArray.some((item) => item?.nsfw === true);

              if (hasNsfw) {
                toast(
                  'Warning: NSFW content detected. Please revise your prompt and try again.',
                  {
                    icon: '🔞',
                  }
                );
              }
            }
          }

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }
        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
          setInputValue(oldInputValue);
          setImagesPreview(oldImagesPreview);
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(chatId.current);
          setLoading(false);
          loadingResponse.current = false;
        }
        checkUseTool();
        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
      }
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const waitNewChat = () =>
      new Promise((resolve, reject) => {
        let rawChats;
        const checkChatId = async () => {
          try {
            const res = await store.api.get(`/chat`);
            rawChats = res.data.chats;

            if (rawChats && rawChats[0]._id === chatId.current) {
              onSelectChat(rawChats[0]);
              resolve();
            } else {
              await delay(1000);
              checkChatId();
            }
          } catch (error) {
            reject(error);
          }
        };

        checkChatId();
      });

    const additionalConfig = async (lastMessage) => {
      if (!lastMessage.additional_config) {
        return;
      }

      let { attr, params, behaviours } = lastMessage.additional_config;
      let userMessage = selectedChat?.chat?.messages[0];
      let tool = (await getPromptsByTool(userMessage.tool))[0];
      setCurrentPrompt(tool);
      setAttrs(attr);
      setParams(params);
      setBehaviours(behaviours);
    };

    // on regenerate message
    const onRegenerate = async () => {
      onCloseBoth();
      const date = moment().utc();
      stopped.current = false;

      try {
        let lastMessage = selectedChat?.chat?.messages.shift();
        await additionalConfig(lastMessage);
        setChat({
          ...selectedChat?.chat,
          messages: [...(selectedChat?.chat?.messages ?? [])],
        });
        setLoading(true);
        loadingResponse.current = true;

        setAutoScroll(true);

        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        const response = await store.api.post(
          `/ai/chat/regenerate`,
          {
            message: selectedChat?.chat?.messages[0].data.content,
            hash: selectedChat?.chat?._id,
            date: date,
            files: refFile ? refFile : null,
            idModel: selectedModels.map((model) => model._id),
            coins: calculateCoins(
              getChatWords(selectedChat?.chat?.messages[0].data.content)
            ),
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/coin.gif',
              time: 10000,
            });
          } else {
            toast.error(response.data.error);
          }
        } else {
          setChatUrlValue(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message]
                : { ...response.data.message },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }

        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(selectedChat.chat._id);
          setLoading(false);
          loadingResponse.current = false;
        }

        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
      }
    };

    // Only when a message is edited
    const calculateCoinsEdit = (words, imagesPreviewEdit) => {
      let totalCoins = 0;
      const currentEditModels = JSON.parse(
        localStorage.getItem('currentModels')
      );
      currentEditModels.forEach((model) => {
        const pricing = model.pricing;
        const input = words > model.words_text ? model.words_text : words;
        let imagesCost = 0;
        if (model.type == 'vision') {
          for (let img of imagesPreviewEdit) {
            imagesCost += img.image_url.words;
          }
        }
        const coins =
          (pricing.words > 0 ? (input + imagesCost) / pricing.words : 0) *
          pricing.coins;

        totalCoins += currentPrompt
          ? parseFloat(handleBehaviour(coins))
          : parseFloat(coins.toFixed(2));
      });

      return totalCoins.toFixed(2);
    };

    // Only when a message is edited
    const additionalConfigEdit = async (aiResponse, userMessage) => {
      if (!aiResponse.additional_config) {
        return;
      }

      let { attr, params, behaviours } = aiResponse.additional_config;
      let tool = (await getPromptsByTool(userMessage.tool))[0];
      setCurrentPrompt(tool);
      setAttrs(attr);
      setParams(params);
      setBehaviours(behaviours);
    };

    // Function to edit a message prompt
    const onRegenerateEdit = async (
      editText,
      datePrompt,
      currentEditChat,
      imagesEdit,
      countWordsEdit,
      previousChat
    ) => {
      onCloseBoth();
      const date = moment().utc();
      stopped.current = false;

      try {
        let selectedMessage = currentEditChat.messages.find(
          (message) => message.date === datePrompt
        );

        let selectedMessageIndex = currentEditChat.messages.findIndex(
          (message) => message.date === datePrompt
        );
        let aiIndexResponse = selectedMessageIndex - 1;

        let aiResponse = currentEditChat.messages[aiIndexResponse];

        await additionalConfigEdit(aiResponse, selectedMessage);

        setLoading(true);
        loadingResponse.current = true;

        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        let currentLocalModels = JSON.parse(
          localStorage.getItem('currentModels')
        );

        currentEditChat.messages = currentEditChat.messages.slice(
          aiIndexResponse + 1
        );

        if (Array.isArray(currentEditChat.messages[0].data.content)) {
          currentEditChat.messages[0].data.content = [];
          currentEditChat.messages[0].data.content.push(...imagesEdit);
          currentEditChat.messages[0].data.content.push({
            type: 'text',
            text: editText,
          });
        } else {
          currentEditChat.messages[0].data.content = editText;
        }

        currentEditChat.messages[0].date = new Date(date);

        setChat({ ...currentEditChat });

        const response = await store.api.post(
          `ai/chat/editmessage`,
          {
            coins: calculateCoinsEdit(countWordsEdit, imagesEdit),
            hash: currentEditChat._id,
            files: refFile ? refFile : null,
            idModel: currentLocalModels.map((model) => model._id),
            messageEdited: editText,
            date: date,
            imagesEdit: imagesEdit,
            capabilities: capabilities.map((capability) => capability._id),
            datePrompt: datePrompt,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            setChat({ ...previousChat });
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/coin.gif',
              time: 10000,
            });
          } else {
            toast.error(response.data.error);
          }
        } else {
          setChatUrlValue(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message]
                : { ...response.data.message },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }

        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(selectedChat.chat._id);
          setLoading(false);
          loadingResponse.current = false;
        }

        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
      }
    };

    // on Stop message
    const onStop = () => {
      store.stopStreamChat(
        selectedChat?.chat._id ? selectedChat?.chat._id : chatId.current
      );
      axiosSource.cancel();
      stopped.current = true;
    };

    const visionInput = (textValue = undefined) => {
      const imagesMessages = [];
      for (let image of imagesPreview) {
        imagesMessages.push({
          type: 'image_url',
          image_url: { url: image.url, words: image.words },
        });
      }
      return [
        ...imagesMessages,
        { type: 'text', text: textValue ? textValue : inputValue },
      ];
    };

    /**
     * This is the logic to manage the shared tools
     */
    const cloneToolShare = async (id) => {
      try {
        const res = await store.api.post('/tool/clone/' + id);
        await getToolsAndFilterSidebar('');
        const data = await res.data;
        const toolId = data?.tool?._id;
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('selectedTool', toolId);
        const toolSharedData = JSON.parse(
          sessionStorage.getItem('toolShareData') ?? '{}'
        );
        Object.entries(toolSharedData).forEach(([key, value]) => {
          urlParams.set(key, value);
        });
        sessionStorage.removeItem('toolShareData');
        await navigate(
          {
            pathname: location.pathname,
            search: urlParams.toString(),
          },
          { shallow: true }
        );
        toast.dismiss('loading tool');
        toast(
          "Check out the 'SHARED WITH ME' folder; you'll find the newly added template there.",
          {
            icon: '🥳',
            duration: 10000,
          }
        );
      } catch (error) {
        toast.dismiss('loading tool');
        console.error(error);
      }
    };

    const selectTool = async (toolId) => {
      // set url param to open the tool
      const urlParams = await new URLSearchParams(location.search);
      urlParams.set('selectedTool', toolId);
      await navigate(
        {
          pathname: location.pathname,
          search: urlParams.toString(),
        },
        { shallow: true }
      );
    };

    useEffect(() => {
      const cookiesData = sessionStorage.getItem('toolClone')
        ? JSON.parse(sessionStorage.getItem('toolClone'))
        : null;
      const isPublic = cookiesData?.toolIsPublic;
      const toolId = cookiesData?.toolClone;

      // if the tool is custom tool clone it
      if (sessionStorage.getItem('toolClone')) {
        toast.loading('Shared template is loading ...', { id: 'loading tool' });
        if (toolId && !isPublic) {
          cloneToolShare(toolId);
          sessionStorage.removeItem('toolClone');
        }
        // if the tool is public open it
        if (isPublic) {
          selectTool(toolId);
        }
      }
      //
      if (showPromptModal) {
        toast.dismiss('loading tool');
        if (isPublic) {
          toast('This template is public search in the template section', {
            icon: '🥳',
            duration: 10000,
          });
          sessionStorage.removeItem('toolClone');
        }
      }
    }, [sessionStorage.getItem('toolClone'), showPromptModal]);
    // ---------------------------------------------------

    // ----------------------------
    //  Modals functions
    // ----------------------------
    const onDelete = async () => {
      const arrCopy = Array.from(chatList);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === toDelete);
      arrCopy.splice(objWithIdIndex, 1);
      try {
        removeChatUrlValue();
        await store.api.delete('/chat/' + toDelete);
        cleanSelectedChat();
        setChat({ _id: null, messages: [] });
        setTreeData(
          treeData.filter((item) => item.id !== selectedChat.chat._id)
        );
        setChatsList(arrCopy);

        dummy.current = { _id: null, messages: [] };
      } catch (error) {
        console.error(error);
      } finally {
        onNewChat();
        setToDeleteDND(null);
        setShowDeleteDialog(false);
        setDeleted(true);
        setToDelete(null);
      }
    };
    const onEdit = (e) => {
      if (e.title) {
        const arrCopy = Array.from(chatList);
        const objWithIdIndex = arrCopy.findIndex(
          (obj) => obj._id === selectedChat?.chat?._id
        );
        const copyData = {
          ...arrCopy[objWithIdIndex],
          title: e.title,
        };

        setChatsList(copyData);
        setChat({ ...selectedChat?.chat, title: e.title });
      }
      setShowEditDialog(false);
    };
    // ----------------------------
    // Debounce
    const debouncedValueText = useDebounce(inputValue, 200);

    /**
     * Update prompt chatList visibility when user type a command or text
     */
    useEffect(() => {
      updatePromptListVisibility(debouncedValueText);
    }, [debouncedValueText]);

    // updatePromptListVisibility
    const updatePromptListVisibility = useCallback(async (text) => {
      const match = text.match(/^\/.*/);
      if (match) {
        const requestId = Date.now();
        latestRequestId.current = requestId;
        getToolsAndFilter(text, requestId);
        if (!isMobile) console.warn();
        else {
          setShowMobileChats(false);
        }
      }
      setShowPromptList(match);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     * IMPORTANT to close when user click outside
     */
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (
          promptListRef?.current &&
          !promptListRef?.current.contains(e.target)
        ) {
          setShowPromptList(false);
        }
      };

      window.addEventListener('click', handleOutsideClick);

      return () => {
        window.removeEventListener('click', handleOutsideClick);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle key press
    const onEnterPress = (e) => {
      if (showPromptList) {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            setActivePromptIndex((prevIndex) =>
              Math.min(prevIndex + 1, tools?.length)
            );
            break;
          case 'ArrowUp':
            e.preventDefault();
            setActivePromptIndex((prevIndex) => Math.max(prevIndex - 1, -3));
            break;
          case 'Tab':
            e.preventDefault();
            setActivePromptIndex((prevIndex) =>
              prevIndex < tools?.length ? prevIndex + 1 : -3
            );
            break;
          case 'Enter':
            e.preventDefault();
            handleInitModal();
            break;
          case 'Escape':
            e.preventDefault();
            setShowPromptList(false);
            break;
          default:
            setActivePromptIndex(0);
            break;
        }
      } else if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setCancel(false);
        onSend();
      }
    };
    const { setShareLink } = useShareLinkTool();
    const getShareLink = async (id) => {
      try {
        const res = await store.api.get(`/shareTools/byId/${id}`);
        const data = await res.data;
        let url = `${config.baseFrontUrl}/share/tool/${data?._id}`;
        if (store.profile.nickname_fpr)
          url = `${url}?fpr=${store.profile.nickname_fpr}`;

        setShareLink(url);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    useEffect(() => {
      if (selectedTool) {
        getPromptsByTool(selectedTool._id).then((prompts) => {
          setOptionsPrompt(prompts);
          if (prompts.length === 1) {
            setPromptValue('');
            setSelectedPrompt(
              selectedTool.prompts.filter(
                (prompt) => prompt.title === prompts[0].name
              )[0]
            );
            const value = prompts[0].value;
            setPromptValue((prevContent) => {
              let updatedContent;
              if (prevContent.includes('/')) {
                updatedContent = prevContent?.replace(/^\/.*/, value);
              } else {
                updatedContent = value;
              }
              return updatedContent;
            });
          }
        });
        getShareLink(selectedTool._id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTool]);
    // handleSendPrompt
    const { focus } = useTextAreaFocus();
    const checkTemplateModel = async (
      updatedVariables,
      prompt,
      files,
      runSampleAuto
    ) => {
      // Send fields context to the chat
      if (files.length > 0) await addReferenceFile(files);
      toolData.current = { updatedVariables, prompt };
      let tool;
      const filtered = sidebarTools.filter((item) => item._id == prompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      let isSelected = false;

      if (tool) {
        if (tool.useTool) {
          isSelected = true;
        } else if (tool.multi_default_models.length > 0) {
          isSelected = selectedModels.some((m) =>
            tool.multi_default_models.some(
              (defaultModel) => m._id == defaultModel.id
            )
          );
        } else if (tool.default_model) {
          isSelected = selectedModels.some(
            (m) => m._id == tool.default_model.id
          );
        }
      }

      if (selectedModels.length == 1) {
        isSelected = true;
      }

      if (isSelected) {
        handleSubmitModal(updatedVariables, prompt, runSampleAuto, files);
      } else {
        setShowWarningDialog(true);
      }
    };
    const handleSubmitModal = (
      updatedVariables,
      prompt,
      runSampleAuto = false,
      files
    ) => {
      // console.log(updatedVariables, prompt, runSampleAuto, files);
      setShowWarningDialog(false);
      toolData.current = null;

      let tool;
      const filtered = sidebarTools.filter((item) => item._id == prompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      let newContent = tool && tool.useTool ? prompt.label : promptValue;
      setInputValue('');
      setParams({});
      setBehaviours([]);
      setAttrs({});
      setCurrentPrompt(prompt);

      updatedVariables.forEach(([key, value]) => {
        const attrs = prompt.attr.filter((item) =>
          typeof item == 'object' ? item.name == key : item == key
        );

        if (attrs.length > 0) {
          const attr = attrs[0];

          if (typeof attr == 'object' && attr.config) {
            switch (attr.config) {
              case 'param':
                setParams((prev) => ({ ...prev, [key]: value }));
                break;
              case 'attr':
                setAttrs((prev) => ({ ...prev, [key]: value }));
                break;
            }
          }

          if (typeof attr == 'object' && attr.behaviour) {
            setBehaviours((prev) => [...prev, { target: key, value: value }]);
          }
        }

        if (typeof value !== 'object' && value !== '' && value) {
          newContent = newContent?.replaceAll(
            '${' + key + '}',
            `"'${value.trim()}'"`
          );
        }
      });

      setInputValue(newContent);
      focus();
      // This line sends the values to track the prompt usages
      setValuesAfterInsert(selectedPrompt, selectedTool);
      if (runSampleAuto) {
        let modelsIds =
          selectedTool?.multi_default_models.length > 0
            ? selectedTool?.multi_default_models.map((model) => model.id)
            : selectedTool?.default_model
            ? [selectedTool?.default_model?.id]
            : undefined;
        console.log(modelsIds);
        onSend(null, newContent, modelsIds, files);
      }
    };

    // handleCloseTemplateModal
    const handleCloseTemplateModal = (defaultParams) => {
      // remove the states
      setSelectedTool(null);
      setSelectedPrompt(null);
      setActivePromptIndex(null);
      toggleShowPromptModal();

      // Create params
      const params = new URLSearchParams(location.search);
      params.delete('selectedTool');

      // delete the params
      if (defaultParams) {
        Object.entries(defaultParams).forEach(([key]) => {
          params.delete(key);
        });
      }

      // remove the selected tool from the url
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { shallow: true }
      );
      fetchFavs();
      addEvent(LOADING_FAV_TOOLS);
    };

    //Model by template default model
    // TODO hanlde multimodal
    const handleTemplateModel = async (modelsId) => {
      if (modelsId && modelsId.length > 0) {
        // Construct the query string with the array of model IDs
        const queryString = modelsId
          .map((model) => `default_model_template=${model.id}`)
          .join('&');

        try {
          const response = await store.api.get(`/model/byId?${queryString}`);
          // Assuming response.data.model is the array of models returned from the API
          setSelectedModels(response.data.model);

          localStorage.setItem(
            'currentModels',
            JSON.stringify(response.data.model)
          );
        } catch (error) {
          console.log(error);
        }
      }
    };

    const visibleOptions = filteredOptions.filter((option) => !option.isHidden);
    const totalItems = visibleOptions.length;

    const functionMap = visibleOptions.reduce((acc, option, idx) => {
      let actualIndex = -totalItems + idx + 1;
      acc[actualIndex] = (() => {
        if (option.name === 'New Chat') {
          return onNewChat;
        } else if (option.name === 'Select Model') {
          return () => setShowModelsDialog(true);
        } else if (option.name === 'Text To Speech') {
          return () => handleScriptForTTS([]);
        } else {
          return handleShowMobilePrompts;
        }
      })();
      return acc;
    }, {});

    // handle init modal
    const handleInitModal = () => {
      if (activePromptIndex in functionMap) {
        functionMap[activePromptIndex]();
      } else if (activePromptIndex > 0 && activePromptIndex <= tools.length) {
        const selectedToolLocal = tools[activePromptIndex - 1];
        setSelectedTool(selectedToolLocal);
        toggleShowPromptModal();
        setShowPromptList(false);
      } else {
        if (activePromptIndex - 1 === -1) handleShowMobilePrompts();
        setShowPromptList(false);
      }
    };

    // open chat btn
    const onOpenChat = () => {
      setShowMobileChats(!showMobileChats);
      setShowMobilePrompts(false);
    };
    const onCloseBoth = () => {
      setShowMobileChats(false);
      setShowMobilePrompts(false);
    };

    // create new chat
    onNewChat = async () => {
      setInputValue(''); // clean the input value
      lastModel.current = null;
      loadingResponse.current = false;
      cancelChat();
      setChat({ _id: null, messages: [] });
      setChatWords(0);
      removeChatUrlValue();
      userMessage.current = null;
      // setUserMessage(null);
      setShowMobileChats(false);
      setShowMobilePrompts(false);
      dummy.current = { _id: null, messages: [] };
      cleanSelectedChat();
      focus();
      setRefFile(null);
      let defaultModel = localStorage.getItem('multimodelDefault');
      //TODO cuando lo llama al principio
      if (defaultModel) {
        setSelectedModels(JSON.parse(defaultModel));
      }
      setStraicoChoiceTools(shuffleArray(straicoChoiceTools));

      checkUseTool();
    };

    const checkUseTool = () => {
      if (!currentPrompt) {
        return;
      }

      let tool;
      const filtered = tools.filter((item) => item._id == currentPrompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (tool && tool.useTool) {
        setInputValue('');
        setParams({});
        setBehaviours([]);
        setAttrs({});
        setCurrentPrompt(null);
      }
    };
    // on open tool
    const onOpenTool = () => {
      setShowMobilePrompts(!showMobilePrompts);
      setShowMobileChats(false);
    };
    // on select chat
    const onSelectChat = (chat, messageIdentifier = null, isUser = null) => {
      cancelChat();
      // Rest methods
      userMessage.current = null;
      selectChat(chat?._id);
      setXchatId(chat?._id);
      getChat(chat?._id, messageIdentifier, isUser);
      setShowMobileChats(false);
      setRefFile(null);
      checkUseTool();
      // Stop the stream current chat
      if (loadingResponse.current) {
        store.stopStreamChat(
          selectedChat?.chat._id ? selectedChat?.chat._id : chatId.current
        );
      }
      loadingResponse.current = false;
    };

    // If the input value is empty, clean the latest fields
    useEffect(() => {
      if (inputValue === '') {
        cleanLastestFields();
        if (!loading) {
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
        }
      }
    }, [inputValue]);

    const addReferenceFile = async (files) => {
      setLoadingBar(true);
      //TODO to send the multi extensions files
      if (
        refFile &&
        Array.isArray(refFile) &&
        refFile.length > 0 &&
        Array.isArray(files) &&
        files.length > 0
      ) {
        for (let i = 0; i < files.length; i++) {
          const attachment = files[i];
          let searchFile = refFile.find(
            (file) => file.metadata.name === attachment.name
          );

          if (searchFile) {
            // File with the same name already exists
            setShowFileDialog(false);
            setLoadingBar(false);
            toast(
              'You already uploaded a file with the name ' + attachment.name,
              {
                icon: '😱',
              }
            );
            continue;
          }
        }
      }

      for (let i = 0; i < files.length; i++) {
        let e = files[i].metadata ? files[i].metadata : files[i];
        try {
          setShowFileDialog(false);
          let response;
          if (typeof e === 'string') {
            const youtubeUrlPattern =
              /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;
            if (youtubeUrlPattern.test(e)) {
              // Upload YouTube video first time
              toast('The upload process can take some seconds. Please wait.', {
                icon: '⌛️',
              });
              response = await store.api.post(`/file/youtube`, {
                url: e,
                enabled: true,
              });
            } else {
              // Upload Web page first time
              response = await store.api.post(`/file/webpage`, {
                url: e,
                enabled: true,
              });
              if (response.data.file.words <= 10) {
                toast(
                  'This web page has additional technology that unables us to access its content',
                  {
                    icon: '⛔️',
                  }
                );
                continue;
              }
            }
          } else if (e.type == 'pdf' || e.type == 'application/pdf') {
            e.type = 'pdf';
            // Load previous pdf
            response = await store.api.post(`/file`, e);
            if (response.data.file.words === 0) {
              toast(
                `The PDF (${e.name}) you are inserting is empty or couldn't be read`,
                {
                  icon: '⛔️',
                }
              );
              continue;
            }
          } else if (e.type == 'image') {
            // Load images allways
            response = await store.api.post(`/file`, e);
          } else if (e.type == 'youtube') {
            // Load previos youtube
            response = await store.api.post(`/file/youtube`, e);
          } else if (e.type == 'webpage') {
            // Load previos webpage
            response = await store.api.post(`/file/webpage`, e);
            if (response.data.file.words <= 10) {
              toast(
                'This web page has additional technology that unables us to access its content',
                {
                  icon: '⛔️',
                }
              );
              continue;
            }
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            e.type === 'docx'
          ) {
            e.type = 'docx';
            response = await store.api.post(`/file`, e);
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            e.type === 'pptx'
          ) {
            e.type = 'pptx';
            response = await store.api.post(`/file`, e);
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            e.type === 'xlsx'
          ) {
            toast(
              'When querying an Excel Document file, formulas nor macros will be taken into account',
              {
                icon: '👀',
                duration: 12000, // 12 seconds in milliseconds
              }
            );
            e.type = 'xlsx';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/plain' || e.type === 'txt') {
            e.type = 'txt';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/html' || e.type === 'html') {
            e.type = 'html';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'application/json' || e.type === 'json') {
            e.type = 'json';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/csv' || e.type === 'csv') {
            e.type = 'csv';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'audio/mpeg' || e.type === 'mp3') {
            e.type = 'mp3';
            response = await store.api.post(`/file/multimedia`, e);
          } else if (e.type === 'video/mp4' || e.type == 'mp4') {
            if (!('words' in e)) {
              toast(
                'The upload process can take several minutes. Please wait.',
                {
                  icon: '⌛️',
                }
              );
            }

            e.type = 'mp4';
            response = await store.api.post(`/file/multimedia`, e);
          } else {
            // Load core documen text/plain
            response = await store.api.post(`/file`, e);
          }

          if (e.type == 'image') {
            setImagesPreview((prevImagesPreview) => {
              if (Array.isArray(prevImagesPreview)) {
                if (
                  !prevImagesPreview.some((file) => file === response.data.file)
                ) {
                  // If it's not in the array, add the new file to it
                  return [...prevImagesPreview, response.data.file];
                } else {
                  // If it's already in the array, return the current array
                  return prevImagesPreview;
                }
              } else {
                // If it's not an array, create a new array with the current and new file
                let newarray = [response.data.file];
                return newarray;
              }
            });
          } else {
            // setRefFile(response.data.file);
            updateRefFile((prevRefFile) => {
              if (Array.isArray(prevRefFile)) {
                // Check if the file is already in the array
                if (
                  !prevRefFile.some(
                    (file) => file.file === response.data.file._id
                  )
                ) {
                  // If it's not in the array, add the new file to it
                  return [...prevRefFile, convertFile(response.data.file)];
                } else {
                  // If it's already in the array, return the current array
                  return prevRefFile;
                }
              } else {
                // If it's not an array, create a new array with the current and new file
                let newarray = [convertFile(response.data.file)];
                return newarray;
              }
            });
            toast.success(
              `The file ${e.name ? 'named ' + e.name : ''} is attached.`
            );
          }
        } catch (error) {
          if (error?.response?.data?.error?.message) {
            toast.error(error?.response?.data?.error?.message);
          }
        } finally {
          if (i === files.length - 1) setLoadingBar(false);
        }
      }
    };

    const convertFile = (file) => {
      return { file: file._id, enabled: true, metadata: { ...file } };
    };

    // -------------------------------------
    const getLabelFile = (type) => {
      switch (type) {
        case 'pdf':
          return 'PDF';
        case 'youtube':
          return 'YouTube';
        case 'webpage':
          return 'Web page';
        case 'docx':
          return 'Word Document';
        case 'pptx':
          return 'Power Point Presentation';
        case 'xlsx':
          return 'Excel Document';
        case 'txt':
          return 'TXT';
        case 'mp3':
          return 'mp3 audio';
        case 'mp4':
          return 'mp4 video';
        case 'html':
          return 'HTML Document';
        case 'csv':
          return 'CSV';
        case 'json':
          return 'JSON';
        default:
          return 'Tipo de archivo desconocido';
      }
    };

    // -------------------------------------
    // TTS
    // -------------------------------------
    const handleScriptForTTS = (value = []) => {
      setScriptValue(value);
      setShowTextSpeechDialog(true);
    };
    const getHello = () => {
      let name = JSON.parse(localStorage.getItem('profile')).fname;
      let hiArray = [
        'Hello',
        'Hi',
        'Hi there',
        'Greetings',
        'Welcome back',
        "What's up",
        'Hey',
        'Good to see you',
      ];

      let questionArray = [
        "Here's where AI meets imagination – Let's create something amazing together!",
        'Ready to elevate your ideas with AI-powered productivity?',
        'Your next big idea starts here - Straico is here to help!',
        "Let's turn your thoughts into action!",
        'Ready to outshine? Strike up a chat and let your AI assistant do the rest',
        'What are you willing to explore with multimodal AI today?',
      ];

      // Generate random indices for greetings and questions
      const randomHiIndex = Math.floor(Math.random() * hiArray.length);
      const randomQuestionIndex = Math.floor(
        Math.random() * questionArray.length
      );

      // Get random greeting and question
      const randomHi = hiArray[randomHiIndex];
      const randomQuestion = questionArray[randomQuestionIndex];

      // Concatenate the greeting with the name
      const greetingWithUsername = `${randomHi}, ${name}`;

      // Return both values
      return [greetingWithUsername, randomQuestion];
    };

    // -------------------------------------
    // Audio input
    // -------------------------------------
    const {
      startRecording,
      stopRecording,
      recordingBlob,
      isRecording,
      recordingTime,
    } = useAudioRecorder();

    useEffect(() => {
      if (!recordingBlob) return;
      getAudio(recordingBlob);
    }, [recordingBlob]);

    const getAudio = async (blob) => {
      setLoadingBar(true);
      const data = new FormData();
      data.append('file', blob, '2260c8a3-5c42-4a1b-8503-dedd931cc835.webm');
      try {
        await store.api.post('/auth/whisper', data, {}).then(({ data }) => {
          setInputValue(data.text);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    };
    // -------------------------------------
    const calculateChatCenterColumns = () => {
      return showPromptSide && showConversationsSide
        ? 2
        : !showPromptSide && showConversationsSide
        ? 3
        : showPromptSide && !showConversationsSide
        ? 3
        : 4;
    };

    const calculateCoins = (words) => {
      let totalCoins = 0;
      const wordcapLimits = store.profile.wordcap_limits;
      selectedModels.forEach((model) => {
        const pricing = model.pricing;

        let maxWords = wordcapLimits ? wordcapLimits.words : 0;
        if (maxWords === 0 || maxWords > tokensToWords(model.context_length)) {
          maxWords = Math.round(tokensToWords(model.context_length));
        }

        const input = words > maxWords ? maxWords : words;
        let imagesCost = 0;
        if (model.type == 'vision') {
          for (let img of imagesPreview) {
            imagesCost += img.words;
          }
        }
        const coins =
          (pricing.words > 0 ? (input + imagesCost) / pricing.words : 0) *
          pricing.coins;

        totalCoins += currentPrompt
          ? parseFloat(handleBehaviour(coins))
          : parseFloat(coins.toFixed(2));
      });

      return totalCoins.toFixed(2);
    };

    const tokensToWords = (tokens) => {
      return (tokens * 3) / 4;
    };

    const handleBehaviour = (coins) => {
      let tool;
      const filtered = tools.filter((item) => item._id == currentPrompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (!tool || (tool && !tool.useTool)) {
        return coins.toFixed(2);
      }

      const data = currentPrompt.attr.filter(
        (item) => typeof item == 'object' && item.behaviour
      );

      if (!data || data.length == 0) {
        return coins.toFixed(2);
      }

      let finalCoins = tool.coins;
      let clonedTool = JSON.parse(JSON.stringify(tool));
      for (let behaviour of data) {
        const behaviourData = behaviours.filter(
          (obj) => obj.target == behaviour.name
        )[0];
        switch (behaviour.behaviour) {
          case 'call_times': {
            finalCoins = clonedTool.coins * behaviourData.value;
            clonedTool.coins = finalCoins;
            break;
          }
          case 'price_adjustment': {
            const rule = behaviour.rules.filter(
              (item) => item.value == behaviourData.value
            )[0];
            finalCoins = rule
              ? clonedTool.coins * rule.factor
              : clonedTool.coins;
            clonedTool.coins = finalCoins;
            break;
          }
        }
      }

      return finalCoins.toFixed(2);
    };

    const handleBehaviourLoader = () => {
      if (!tools.length) {
        getToolsAndFilter('');
      }

      let tool;
      const filtered = tools.filter((item) => item._id == currentPrompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (!tool || (tool && !tool.useTool)) {
        return null;
      }

      const data = currentPrompt.attr.filter(
        (item) =>
          typeof item == 'object' &&
          item.behaviour &&
          item.behaviour == 'call_times'
      );

      if (data) {
        const behaviourData = behaviours.filter(
          (obj) => obj.target == data[0].name
        )[0];
        return Array(behaviourData.value).fill({
          type: 'image_url',
          image_url: {
            url: 'https://prompt-rack.s3.amazonaws.com/images/1703813690569_straico_loading-vanilla.gif',
            words: 0,
          },
          no_actions: true,
          loading: true,
        });
      }
      return [
        {
          type: 'image_url',
          image_url: {
            url: 'https://prompt-rack.s3.amazonaws.com/images/1703813690569_straico_loading-vanilla.gif',
            words: 0,
          },
          no_actions: true,
          loading: true,
        },
      ];
    };

    const changeOutput = async (element) => {
      if (element._id) {
        await selectedOutput(element._id);
        countTotalWords(selectedChat.chat.messages);

        // check last message
        if (selectedChat.chat.messages[0]._id == element._id) {
          lastModel.current = element.modelRef;
        }
      } else {
        lastModel.current = element.modelRef;
      }
    };

    const selectedOutput = async (hash) => {
      await store.api.post(`/chat/message/${hash}`, {
        chat: selectedChat.chat._id ? selectedChat.chat._id : chatId.current,
      });
      setEditMessChange();
    };

    const getVisibleModel = () => {
      let refId;
      if (lastModel.current) {
        refId = lastModel.current;
      } else {
        let lastMsg =
          (selectChat?.chat?.messages?.length ?? 0) > 0
            ? selectChat?.chat?.messages[0]
            : null;
        if (lastMsg) {
          let filterd = Array.isArray(lastMsg)
            ? lastMsg.filter((m) => m.selected == true)
            : null;
          refId =
            filterd && filterd.length
              ? filterd[0].modelRef
                ? filterd[0].modelRef
                : null
              : lastMsg.modelRef
              ? lastMsg.modelRef
              : null;
        }
      }

      let filteredModels = refId
        ? selectedModels.filter((model) => model._id == refId)
        : null;
      return filteredModels && filteredModels.length
        ? filteredModels[0]
        : selectedModels[0];
    };

    const getChatWords = (text) => {
      let total = 0;
      if (refFile) {
        refFile.forEach((file) => {
          if (file.metadata.words && file.enabled) {
            total += file.metadata.words;
          }
        });
      }
      return total + chatWords + store.countWords(text);
    };
    const checkLimit = (text) => {
      if (
        !store.profile.wordcap_limits ||
        store.profile.wordcap_limits.words == 0
      )
        return false;
      return getChatWords(text) > store.profile.wordcap_limits.words;
    };
    const { isIOS } = useDeviceInfo();
    const { openModal } = useComingSoonModal();

    // handle delete of a message
    const handleMessageDelete = (messageId) => {
      let previousUserMessageIndex = -1;

      // Map through messages to hide the target and keep track of the previous user message index
      const updatedMessages = selectedChat.chat.messages.map(
        (message, index) => {
          if (Array.isArray(message)) {
            const idx = message.findIndex((msg) => msg._id === messageId);
            if (idx !== -1) {
              if (previousUserMessageIndex === -1)
                previousUserMessageIndex = index - 1;
              // Hide all messages in this array
              return message.map((msg) => ({ ...msg, hidden: true }));
            }
          } else if (message._id === messageId) {
            previousUserMessageIndex = index - 1;
            return { ...message, hidden: true };
          }
          return message;
        }
      );

      // Hide also the previous message
      if (
        previousUserMessageIndex >= 0 &&
        !Array.isArray(updatedMessages[previousUserMessageIndex])
      ) {
        updatedMessages[previousUserMessageIndex] = {
          ...updatedMessages[previousUserMessageIndex],
          hidden: true,
        };
      }

      // Create the updated chat object
      const updatedChat = {
        ...selectedChat.chat,
        messages: updatedMessages,
      };

      setXchatId(selectedChat.chat._id);
      setSelectedChat(updatedChat);
      getChat(selectedChat.chat._id);
    };
    /**
     * Memo to render messages
     */
    const renderMessages = useMemo(() => {
      const filteredMessages = (selectedChat?.chat?.messages ?? []).reduce(
        (acc, msg) => {
          if (Array.isArray(msg)) {
            // Filter out hidden messages in nested arrays
            const validSubMessages = msg.filter((subMsg) => !subMsg.hidden);
            if (validSubMessages.length) {
              acc.push(validSubMessages);
            }
          } else if (!msg.hidden) {
            acc.push(msg);
          }
          return acc;
        },
        []
      );

      const renderComplexLogic = (selectedChat?.chat?.messages ?? []).map(
        (item, index) => {
          let temp = Array.isArray(item)
            ? item.filter((m) => m.selected == true)
            : [item];
          let realItem = temp.length ? temp[0] : null;
          let realKey = realItem && realItem._id ? realItem._id : dummyId();

          const isUserMessage =
            !Array.isArray(item) && item.data.role === 'user';

          return (
            <>
              <ChatMessage
                key={index == 0 ? streamCode.current : realKey}
                item={item}
                index={index}
                avatar={avatar}
                chatId={xchatId}
                formatedDate={formatedDate}
                onCapturedScript={handleScriptForTTS}
                onDelete={handleMessageDelete}
                onLoading={(e) => setLoadingBar(e)}
                onOpenFull={(content) => {
                  setDialogContent(content);
                  setShowImageDialog(true);
                }}
                changeOutput={changeOutput}
                capabilities={capabilitiesList}
                onRegenerateEdit={onRegenerateEdit}
                loading={loading}
                calculateCoinsEdit={calculateCoinsEdit}
              />

              {/* DIV FOR REFERENCE */}
              <div
                key={index}
                ref={(el) => {
                  if (el) {
                    if (isUserMessage) {
                      messageRefs.current.byDate[item.date] = el;
                    } else {
                      messageRefs.current.byId[realKey] = el;
                    }
                  }
                }}
              ></div>
            </>
          );
        }
      );

      return <>{renderComplexLogic}</>;
    }, [selectedChat, loading]);

    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const removeImage = (img) => {
      let modified = imagesPreview.filter((item) => item._id !== img._id);
      setImagesPreview([...modified]);
    };

    const handleBadInteraction = (event) => {
      if (event.reason == 'bad model') {
        setShowFileDialog(false);
        setShowModelsDialog(true);
      }
    };

    const removeModel = (model) => {
      updateSelectedModels((prev) =>
        prev.filter((item) => item._id !== model._id)
      );
      localStorage.setItem(
        'currentModels',
        JSON.stringify(selectedModels.filter((item) => item._id !== model._id))
      );
      setEditMessChange();
    };
    //Pricing
    const [rulesPlans, setRulesPlans] = useState([]);
    useEffect(() => {
      getRulesPlans();
    }, []);
    const getRulesPlans = async () => {
      try {
        setLoading(true);
        const res = await store.api.get('/user/constant', {
          params: {
            type: 'princing_plans',
          },
        });
        setRulesPlans(res.data.rules);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      }
    };

    const handleCapabilities = (capabilities) => {
      localStorage.setItem('capabilities', JSON.stringify(capabilities));
      setCapabilities(capabilities);
      setShowModelsDialog(false);
    };

    // render
    // Prompts Fav Handler
    const [straicoChoiceTools, setStraicoChoiceTools] = useState([]);

    useEffect(() => {
      (async () => {
        try {
          const res = await store.api.get(`/tool/straico_choice_tools`);
          setStraicoChoiceTools(shuffleArray(res.data.tools));
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);

    const [favTools, setFavTools] = useState([]);

    const fetchFavs = async () => {
      try {
        const userId = store.profile._id;
        const response = await store.api.get(`/tool/favorite-tools/${userId}`);
        if (response.data && response.data.tools) {
          setFavTools(sortArrayAlphabetically(response.data.tools));
        } else {
          // Handle scenario when no tools are found or response is not as expected
          console.error(
            'No favorite tools found or unexpected format:',
            response
          );
        }
      } catch (error) {
        console.error('Failed to fetch favorite tools:', error);
      }
    };

    useEffect(() => {
      fetchFavs();
    }, []);

    function sortArrayAlphabetically(array) {
      return array.sort((a, b) => a.title.localeCompare(b.title));
    }
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    const { theme: themeValue } = useThemeStore();

    const { setOpenPromptLibrary, openPromptLibrary } = usePromptLibraryStore();
    const togglePromptLibrary = () => setOpenPromptLibrary(!openPromptLibrary);

    // GET CATLOG TO FILTERS HOOK
    useCatalog(store);

    return (
      <>
        <Helmet>
          <title>Chat - Straico</title>
        </Helmet>

        <CoinsAlertModal
          handleConfirm={handleConfirmAlertCoins}
          customClose={handleCloseAlertCoins}
        />
        <CreateTemplatesForm />
        {/* Chat */}
        <div
          className={`flex flex-col h-full w-full flex-1 items-center overflow-auto`}
        >
          {loadingBar && <LinearProgress />}

          <div className="flex-1 flex flex-col w-full h-full lg:max-w-4xl overflow-auto font-figtree">
            <div
              className={`h-full w-full bg-seasalt dark:bg-lead flex-1 flex flex-col overflow-auto ${
                powerPanelOpen
                  ? 'md:max-w-[calc(100vw_-_16rem)]'
                  : 'md:max-w-[calc(100vw_-_3.5rem)]'
              }`}
            >
              {!((selectedChat?.chat?.messages.length || 0) > 0) && (
                <AttachedFiles
                  files={refFile}
                  onFileChange={updateRefFile}
                  setShowFileDialog={setShowFileDialog}
                />
              )}
              {(selectedChat.chat?.messages?.length || 0) > 0 ||
              selectedChat.chat?._id ? (
                <div
                  className={`flex flex-col flex-1 max-w-full h-full ${
                    themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
                  }`}
                >
                  {/* Title chat - Top Bar */}
                  {(selectedChat?.chat?.messages?.length > 0 ||
                    selectedChat?.chat?.title) && (
                    <div className="flex text-violet-blue dark:text-tropical-indigo bg-lavender h-8 rounded dark:bg-ship-grey">
                      <div
                        onClick={goBackChat}
                        className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white rounded cursor-pointer lg:hidden"
                      >
                        <ArrowLeftIcon className="h-5 w-5" />
                      </div>
                      <div className="w-full flex mx-4 items-center">
                        <div className="w-6 p-1 pl-0 flex items-center justify-center">
                          <ChatBubbleLeftEllipsisIcon className={'h-5 w-5'} />
                        </div>
                        <div className="flex-grow truncate w-40">
                          <p className="text-base font-medium">
                            {selectedChat.chat.title}
                          </p>
                        </div>
                      </div>
                      {!selectedChat?.chat?.isShared && (
                        <>
                          <ShareChat
                            chat={selectedChat.chat}
                            renderChat={renderMessages}
                          />
                          <div
                            className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white cursor-pointer"
                            onClick={() => setShowEditDialog(true)}
                          >
                            <PencilSquareIcon className={'h-5 w-5'} />
                          </div>
                          <div
                            className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white hover:rounded-r cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleted(false);
                              setShowDeleteDialog(true);
                              setToDelete(selectedChat?.chat?._id);
                            }}
                          >
                            <TrashIcon className={'h-5 w-5'} />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <AttachedFiles
                    files={refFile}
                    onFileChange={updateRefFile}
                    setShowFileDialog={setShowFileDialog}
                  />
                  {/* Messages between AI and user */}
                  <div
                    ref={chatContainer}
                    className="overflow-auto flex no-scrollbar flex-col-reverse"
                    onScroll={handleScroll}
                  >
                    {loading &&
                      (currentPrompt && currentPrompt.type == 'image' ? (
                        <ChatMessage
                          item={{
                            data: {
                              role: 'assistant',
                              content: handleBehaviourLoader(),
                            },
                            model: currentPrompt.abbreviation,
                            date: moment().utc(),
                          }}
                          formatedDate={formatedDate}
                        />
                      ) : (
                        <div className="py-8 w-full flex items-center justify-center">
                          <Loader className="h-8" />
                        </div>
                      ))}
                    {/** Render the memo messages */}
                    {renderMessages}
                  </div>
                </div>
              ) : (
                <>
                  {/* Slides */}
                  <div
                    className={`flex  ${
                      themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
                    } overflow-auto flex-col h-full font-figtree px-9 justify-around`}
                  >
                    <section className="mb-6">
                      <article>
                        <h2
                          className={`text-[28px] font-bold leading-[28px] ${
                            themeValue == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          {welcomeMessage[0]}
                        </h2>
                        <p
                          className={`text-xl ${
                            themeValue == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          {welcomeMessage[1]}
                        </p>
                      </article>
                    </section>

                    {isMobile ? (
                      favTools.length > 0 ? (
                        <ScrollableToolsSection
                          title={
                            favTools.length === 0
                              ? 'No favorite templates'
                              : favTools.length === 1
                              ? 'Your favorite template'
                              : 'Your favorite templates'
                          }
                          tools={favTools}
                          isSmallMobile={true}
                          subTitle={
                            favTools.length === 0
                              ? "To add a prompt template to your favorites, simply click on the star ⭐️ on the template's description."
                              : ''
                          }
                        />
                      ) : (
                        <ScrollableToolsSection
                          title="Prompt templates to try today"
                          tools={straicoChoiceTools}
                          isSmallMobile={true}
                        />
                      )
                    ) : (
                      <>
                        <ScrollableToolsSection
                          title={
                            favTools.length === 0
                              ? 'No favorite templates'
                              : favTools.length === 1
                              ? 'Your favorite template'
                              : 'Your favorite templates'
                          }
                          tools={favTools}
                          isSmallMobile={true}
                          subTitle={
                            favTools.length === 0
                              ? "To add a prompt template to your favorites, simply click on the star ⭐️ on the template's description."
                              : ''
                          }
                        />
                        <ScrollableToolsSection
                          title="Prompt templates to try today"
                          tools={straicoChoiceTools}
                          isSmallMobile={false}
                        />
                      </>
                    )}
                    <div className="flex flex-col w-full justify-end text-nue-blue mt-2 items-end dark:text-tropical-indigo cursor-pointer">
                      <div className="flex items-center gap-3">
                        <p onClick={togglePromptLibrary}>
                          Explore our prompt collection
                        </p>
                        <ArrowLongRightIcon className="h-4 w-4" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Input field */}
            <div
              className={`relative m-auto w-full p-3 pb-2 bg-seasalt dark:bg-lead ${
                !isSmallMobile ? 'flex items-start' : 'block'
              }`}
            >
              {isRecording ? (
                <div className="flex flex-col">
                  <div className="p-0">
                    <img src="/rec.gif" className="h-8 m-auto justify-center" />
                  </div>
                  <div className="px-1 font-figtree font-extralight text-cool-gray dark:text-quicksilver text-center">
                    {formatTime(recordingTime)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {isSmallMobile && (
                <div className="flex justify-between mb-2">
                  <div className="flex gap-2">
                    {!refFile && (
                      <Shortcut
                        className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group text-violet-blue dark:text-stargate-shimmer"
                        onClick={
                          () =>
                            setShowFileDialog(
                              true
                            ) /* fileInputRef.current.click() */
                        }
                      >
                        <PlusCircleIcon className="w-6 h-6" />
                      </Shortcut>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Shortcut
                      className={`h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group ${
                        themeValue == 'dark'
                          ? 'dark:text-stargate-shimmer'
                          : 'text-nue-blue'
                      }`}
                      onMouseDown={() => {
                        if (!isIOS) startRecording();
                        else openModal();
                      }}
                      onMouseUp={() => {
                        if (!isIOS) stopRecording();
                        else openModal();
                      }}
                      onMouseLeave={() => {
                        if (!isIOS) stopRecording();
                        else openModal();
                      }}
                    >
                      <MicrophoneIcon className="w-6 h-6" />
                    </Shortcut>
                    <Shortcut
                      className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-nue-blue/70 relative group flex flex-col items-center group text-white bg-nue-blue"
                      onClick={onSend}
                    >
                      <PaperAirplaneIcon className="w-6 h-6 text-white" />
                    </Shortcut>
                  </div>
                </div>
              )}
              {!isSmallMobile && (
                <Shortcut
                  className={`h-12 px-3 fle justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group ${
                    themeValue == 'dark'
                      ? 'dark:text-stargate-shimmer'
                      : 'text-violet-blue'
                  }`}
                  onClick={
                    () =>
                      setShowFileDialog(true) /* fileInputRef.current.click() */
                  }
                >
                  <PlusCircleIcon className="w-6 h-6" />
                </Shortcut>
              )}
              <ActionsChat
                loading={loading}
                onRegenerate={onRegenerate}
                continueResponse={continueResponse}
                chat={selectedChat?.chat}
                onStop={onStop}
              />
              <label
                htmlFor="user-message"
                className="-mt-1 px-2 relative transition text-cool-gray focus-within:text-raisin-black block w-full"
              >
                {inputValue?.length > 0 && (
                  <div
                    className="absolute top-3 right-4 z-50 cursor-pointer"
                    onClick={() => setInputValue('')}
                  >
                    <XMarkIcon className="w-4 text-nue-blue dark:text-stargate-shimmer" />
                  </div>
                )}
                <TextAreaHightlight
                  classNameContainer={` outline-none focus:outline-none text-md bg-white rounded w-full font-regular ${
                    inputError ? 'border-red-400' : 'border-nue-blue'
                  } font-figtree text-raisin-black shadow-none text-base`}
                  classNameMainContainer={'bg-white'}
                  textareaRef={textareaRef}
                  autoFocus
                  minRows={1}
                  maxRows={7}
                  onKeyDown={onEnterPress}
                  value={inputValue}
                  onChange={(value) => {
                    setInputValue(value);
                  }}
                  highlightWords={highlightWordsTextAreaV2}
                  inputWarning={checkLimit(inputValue)}
                  warningAction={onNewChat}
                  onInteraction={(e) => addReferenceFile(e)}
                  models={selectedModels}
                >
                  <div className="flex gap-3 px-[0.625rem]">
                    {imagesPreview.length > 0 &&
                      imagesPreview.map((image, index) => (
                        <div
                          key={index}
                          className="mt-3 relative cursor-pointer"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img
                            src={image.thumbnail ? image.thumbnail : image.url}
                            alt={'Preview-' + index}
                            className="w-16 h-16 rounded object-cover"
                          />
                          <div
                            className={`absolute -top-[0.625rem] -right-[0.625rem] ${
                              hoveredIndex === index ? 'visible' : 'invisible'
                            }`}
                          >
                            <XCircleIcon
                              className={`w-5 h-5 ${
                                themeValue == 'dark'
                                  ? 'dark:text-crystal-bell'
                                  : 'text-raisin-black'
                              }`}
                              onClick={() => removeImage(image)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </TextAreaHightlight>
                {showPromptList && (
                  <div className="absolute bottom-20 w-full h-56">
                    <ToolsList
                      ref={promptListRef}
                      setShowModelsDialog={setShowModelsDialog}
                      inputValue={inputValue}
                      filteredOptions={filteredOptions}
                      handleScriptForTTS={handleScriptForTTS}
                    />
                  </div>
                )}
                <TemplateModal
                  onClose={handleCloseTemplateModal}
                  handleSubmitModal={checkTemplateModel}
                  handleTemplateModel={handleTemplateModel}
                />
                <DeleteTemplateModal />
                {selectedModels.length > 0 && (
                  <div className="flex gap-1 mt-1 font-figtree text-xs items-center justify-end">
                    <div
                      className={`${
                        themeValue == 'dark'
                          ? 'dark:text-sonic-silver '
                          : 'text-cool-gray '
                      } font-light`}
                    >
                      <span
                        className={`${
                          getChatWords(inputValue) >
                            getVisibleModel().words_text && 'text-red-600'
                        }`}
                      >
                        {getChatWords(inputValue).toLocaleString('en-US')}
                      </span>
                      /{getVisibleModel().words_text.toLocaleString('en-US')}
                    </div>
                    <Shortcut
                      className={`rounded-full cursor-pointer relative group flex flex-col items-center group ${
                        checkLimit(inputValue)
                          ? 'text-red-600'
                          : 'text-cool-gray dark:text-sonic-silver'
                      }`}
                      onClick={() => setShowLimitDialog(true)}
                    >
                      <InformationCircleIcon className="w-4 h-4" />
                    </Shortcut>
                    <div
                      className={`flex flex-row items-center px-[2px] ${
                        checkLimit(inputValue)
                          ? 'bg-red-600'
                          : 'bg-cool-gray dark:bg-sonic-silver'
                      } rounded-[20px] text-white font-semibold py-[2px]`}
                    >
                      <CurrencyDollarIcon className="w-4 h-4" />
                      <span className="mx-1">
                        {calculateCoins(getChatWords(inputValue))}
                      </span>
                    </div>
                  </div>
                )}
              </label>
              {!isSmallMobile && (
                <div className="flex gap-2">
                  <Shortcut
                    className={`h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group ${
                      themeValue == 'dark'
                        ? 'dark:text-stargate-shimmer'
                        : 'text-violet-blue'
                    }`}
                    onMouseDown={() => startRecording()}
                    onMouseUp={() => stopRecording()}
                    onMouseLeave={() => stopRecording()}
                  >
                    <MicrophoneIcon className="w-6 h-6" />
                  </Shortcut>
                  <Shortcut
                    className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-nue-blue/70 relative group flex flex-col items-center group text-white bg-nue-blue"
                    onClick={onSend}
                  >
                    <PaperAirplaneIcon className="w-6 h-6 text-white" />
                  </Shortcut>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* </MainBody> */}
        {showDeleteDialog && (
          <DeleteChat
            deleted={deleted}
            setDeleted={setDeleted}
            onDelete={onDelete}
            onClose={() => setShowDeleteDialog(false)}
          />
        )}
        {showWarningDialog && (
          <ModelWarningDialog
            data={toolData.current}
            onContinue={(data) =>
              handleSubmitModal(data.updatedVariables, data.prompt)
            }
            onClose={() => setShowWarningDialog(false)}
          />
        )}
        <WordcapLimitsDialog
          open={showLimitDialog}
          exceeded={checkLimit(inputValue)}
          onClose={() => setShowLimitDialog(false)}
        ></WordcapLimitsDialog>
        {showEditDialog && (
          <EditTitle chat={selectedChat.chat} onInteraction={onEdit} />
        )}
        {showFileDialog && (
          <FileContext
            onInteraction={(e) => addReferenceFile(e)}
            onClose={() => setShowFileDialog(false)}
            models={selectedModels}
            onBadInteraction={handleBadInteraction}
          />
        )}
        {showTextSpeechDialog && (
          <TextSpeechDialog
            onClose={() => setShowTextSpeechDialog(false)}
            script={scriptValue}
          />
        )}
        {showModelsDialog && (
          <ModelDialog
            selectedModels={selectedModels}
            onInteraction={(e) => {
              setSelectedModels(e);
              setShowModelsDialog(false);
            }}
            onClose={() => setShowModelsDialog(false)}
            onCapabilities={handleCapabilities}
            selectedCapabilities={capabilities}
          />
        )}
        {showImageDialog && (
          <ImageDialog onClose={() => setShowImageDialog(false)}>
            {dialogContent}
          </ImageDialog>
        )}
        <PromptLibrary />
        <FavoriteMessages
          onSelectChat={onSelectChat}
          avatar={avatar}
          onLoading={(e) => setLoadingBar(e)}
        />
      </>
    );
  })
);
export { onNewChat };
export default Chat;
