import React, { useLayoutEffect } from 'react';
import { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Button from '../../Components/Button';

import MainBody from '../../Components/Body';
import Subheader from '../../Components/Subheader';
import FeedbackForCoins from '../../Components/FeedbackForCoins';

const Upgrade = inject('store')(
  observer(({ store, onInteraction }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [quantity, setQuantity] = useState(-1);

    const [loadingBar, setLoadingBar] = useState(false);

    useEffect(() => {
      freeCoinsAvailability();
    }, []);

    const freeCoinsAvailability = async () => {
      try {
        let data = await store.api
          .post('user/remaining/freecoins', {})
          .then(async ({ data }) => {
            setQuantity(data.quantity);
          });
      } catch (err) {
        console.log(err);
      }
    };

    useLayoutEffect(() => {
      function updateSize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }

      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }, []);

    const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
    const [modeDialogQualifyExperience, setModeDialogQualifyExperience] =
      useState('tool_suggestions');

    onInteraction = (str) => {
      freeCoinsAvailability();
      if (str == 'close') {
        setOpenFeedbackForm(false);
      }
    };

    return (
      <>
        <Helmet>
          <title>{`Coins - promptrack`}</title>
        </Helmet>

        <Subheader title="Coins" loading={loadingBar}></Subheader>

        <MainBody className="px-4 md:px-28 py-5 flex-1 font-figtree">
          {quantity < 2 && quantity >= 0 ? (
            <div className="flex flex-col items-center justify-center h-[calc(100%_-_7.95rem)] px-4 py-4 m-auto">
              <h2 className="text-2xl font-bold text-center text-nue-blue mb-4">
                Exciting News!
              </h2>
              <div className="w-full lg:w-3/4 px-4 py-6 rounded-lg mb-8">
                <p className="text-2xl text-center text-cool-gray mb-4">
                  Our subscription service will be available for you to use with
                  Stripe starting on April 28th (or earlier).
                </p>
                <p className="text-xl text-battleship-gray mb-8 text-center">
                  If you require coins before our subscription service is
                  released, please take our short survey and receive a reward of
                  4,000 coins 🪙 (equivalent to $10).
                </p>
                <div className="flex justify-center">
                  <Button
                    className="text-lg py-2 px-4 rounded-full font-bold"
                    color={'bg-nue-blue'}
                    title={'Coins for Thoughts'}
                    onClick={() => {
                      setOpenFeedbackForm(true);
                    }}
                  />
                </div>
              </div>
              <p className="text-lg text-center text-battleship-gray mt-8">
                You can still apply for more sets 🪙🪙 of free coins.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center justify-center h-[calc(100%_-_7.95rem)] px-4 py-4 m-auto">
                <h2 className="text-2xl font-bold text-center text-nue-blue mb-4">
                  Exciting News!
                </h2>
                <div className="w-full lg:w-3/4 px-4 py-6 rounded-lg mb-8">
                  <p className="text-2xl text-center text-cool-gray mb-4">
                    Our subscription service will be available for you to use
                    with Stripe starting on April 28th (or earlier).
                  </p>
                  <p className="text-xl text-battleship-gray mb-8 text-center">
                    We're sorry, but you have reached the maximum limit for
                    requesting free coins 🪙. However, you can subscribe to one
                    of our plans soon 😄.
                  </p>
                </div>
                <p className="text-lg text-center text-battleship-gray mt-8">
                  Need help? Drop us an email at{' '}
                  <a href="mailto:sup@promptrack.com">sup@promptrack.com</a>.
                  We're here to assist you.
                </p>
              </div>
            </>
          )}
        </MainBody>

        {openFeedbackForm ? (
          <FeedbackForCoins
            modal={modeDialogQualifyExperience}
            onInteraction={onInteraction}
          ></FeedbackForCoins>
        ) : (
          <></>
        )}
      </>
    );
  })
);

export default Upgrade;
