/* eslint-disable no-constant-condition */
import { useEffect, useMemo, useState } from 'react';
// react-router-dom
import { useLocation } from 'react-router-dom';
// Mobx
import { inject, observer } from 'mobx-react';
// Moment
import moment from 'moment';
// Store
import usePublicLayoutStore from 'Layouts/PublicLayout/State';
// MUI
import { Box, useMediaQuery } from '@mui/material';
// Icons
import ToolSvg from 'Theme/icons/Tool.svg';
import { UserCircleIcon } from '@heroicons/react/24/outline';
// Components
import ContentMessage from 'Components/Common/Markdown/ContentMessage';
import ImageDialog from 'Components/ImageDialog';
import Loader from 'Components/Loader';
import Subheader from 'Components/Subheader';
import { MAX_TITLE_LENGTH } from 'Constants/chat';
import useDisableIOSZoom from 'Hooks/useDisableIOSZoom';
import Input from 'Layouts/PublicLayout/Components/Input';
import ModalRegister from 'Layouts/PublicLayout/Components/ModalRegister';
import PromptSide from 'Layouts/PublicLayout/Components/PromptSide';
import PDFFileUpload from 'Theme/icons/pdfFileUpload';
import UrlFileUpload from 'Theme/icons/urlFileUpload';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import styled from 'styled-components';

const ShareChatPage = inject('store')(
  observer(({ store }) => {
    useDisableIOSZoom();
    const router = useLocation();
    // State
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    // Capabilities variables
    const [capabilities, setCapabilities] = useState(
      JSON.parse(localStorage.getItem('capabilities')) || []
    );

    // Get share chat
    const onGetShare = async (id) => {
      setLoading(true);
      try {
        const res = await store.api.get(`/shareChats/${id}`);
        const data = await res.data;
        setData(data);
      } catch (err) {
        setError(true);
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (router.pathname.split('/').pop()) {
        onGetShare(router.pathname.split('/').pop());
      }
    }, []);

    useEffect(() => {
      (async () => {
        await getCapabilities();
      })();
    }, []);

    const getCapabilities = async () => {
      try {
        const response = await store.api.get(`/capability`);
        setCapabilities(response.data.capabilities);
      } catch (error) {
        console.error(error);
      }
    };

    // Format date
    const formatDate = useMemo(
      () => moment(data?.created).format('MMMM D, YYYY'),
      [data]
    );

    // Global state management
    const {
      registerModal,
      closeRegisterModal,
      showPromptSidebar,
      togglePromptSidebar,
      openRegisterModal,
      toggleContinueConversation,
    } = usePublicLayoutStore();

    // media querys
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Continue this conversation
    const setRedirectUrl = () => {
      sessionStorage.setItem('redirectUrl', `/chat?chat=${data?.chat?._id}`);
    };
    const continueConversation = () => {
      openRegisterModal();
      toggleContinueConversation();
    };

    const getTabs = (message) => {
      return Array.isArray(message) ? message : [message];
    };

    const isSelected = (item, ele) => {
      if (!Array.isArray(item) || (ele._id && ele.selected == true)) {
        return true;
      }

      return false;
    };

    const handleOutput = async (item, element, index, ind) => {
      if (!Array.isArray(item)) {
        return;
      }

      let copy = JSON.parse(JSON.stringify(data));
      if (element._id) {
        for (let i = 0; i < item.length; i++) {
          // console.log(data.chat.messages[ind][i]);
          copy.chat.messages[ind][i].selected = i == index ? true : false;
        }
        setData(copy);
      }
    };

    const getCapabilityState = (func) => {
      const capability = capabilities.find((cap) => cap._id == func.capability);
      return (
        capability && (
          <div className="flex rounded py-1 px-2 my-2 gap-2 bg-lavender text-battleship-gray w-fit text-xs items-center">
            {getIcon(capability.icon, 'h-4 w-4')}
            <span>{capability.label_states[func.state]}</span>
          </div>
        )
      );
    };

    const getIcon = (icon, className = 'h-6 w-6') => {
      const Icon = get(outlineIcons, icon);
      return Icon ? <Icon className={className} /> : null;
    };

    const getLabelMinutes = (seconds) => {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;
      let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
      let secondsStr =
        remainingSeconds < 10
          ? '0' + remainingSeconds
          : remainingSeconds.toString();
      return minutesStr + ':' + secondsStr;
    };

    const checkCapability = (ele) => {
      if (!ele.data.function) {
        return;
      }

      const capability = capabilities.find(
        (cap) => cap._id == ele.data.function.capability
      );

      return (
        capability && (
          <Shortcut>
            {getIcon(capability.icon, 'h-4 h-4')}
            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex left-[calc(100%_-_0.5rem)] top-1/2">
              <div className="p-2 text-sm leading-none text-cool-gray bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                Capability: <span className="font-bold">{capability.name}</span>
              </div>
            </Tooltip>
          </Shortcut>
        )
      );
    };

    const isHidden = (item) => {
      return Array.isArray(item) ? item.some((ele) => ele.hidden) : item.hidden;
    };

    if (error)
      return (
        <div className="flex-1 h-[calc(100vh_-_48px)] bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell w-full flex justify-center items-center font-figtree">
          <div className="text-center">
            <h1 className="text-3xl font-bold">
              Oops, Trouble Accessing Shared Content!
            </h1>
            <p className="text-xl">
              This shared chat seems to be currently unavailable. For the best
              experience, we recommend reaching out to the original creator to
              request a new share link.
            </p>
          </div>
        </div>
      );

    return (
      <>
        {loading ? (
          <div className="h-[calc(100vh_-_48px)] w-full flex justify-center items-center bg-seasalt dark:bg-lead">
            <Loader />
          </div>
        ) : (
          <div className="h-[calc(100vh_-_48px)] w-full bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell flex flex-col font-figtree">
            <Subheader
              title={`Shared chat • Model: ${data?.modelList?.join(', ')}`}
              icon="ChatBubbleLeftRightIcon"
            />
            <div className="relative h-[calc(100vh_-_5.85rem)] flex-1 flex flex-col">
              <div className="flex h-full">
                <div
                  className={`flex flex-col h-full w-[${
                    showPromptSidebar ? (isMobile ? 100 : 70) : 100
                  }%] relative`}
                >
                  <div className="flex md:h-[91.7%] h-[100%] relative flex-col overflow-y-auto pb-[4rem]">
                    <div className="mx-auto w-full p-4 md:max-w-2xl lg:max-w-xl lg:px-0 xl:max-w-3xl">
                      <div className="mb-1 border-b border-platinum pt-3 sm:mb-2 sm:pb-6 sm:pt-8">
                        <h1 className="truncate text-3xl font-semibold leading-tight sm:text-4xl overflow-hidden whitespace-nowrap lg:whitespace-normal">
                          {data?.title.length > MAX_TITLE_LENGTH
                            ? data?.title.substring(0, MAX_TITLE_LENGTH) + '...'
                            : data?.title}
                        </h1>
                        <div className="pt-3 text-base text-cool-gray dark:text-battleship-gray sm:pt-4">
                          {formatDate}
                        </div>
                        <div>
                          {data?.chat?.files?.length > 0 && (
                            <>
                              <div className="px-3 py-2 w-[calc(100%_-_1.5rem)] bg-lavender z-10 rounded-md mt-2">
                                <h3 className="text-sm text-battleship-gray">
                                  {`Attached file${
                                    data?.chat?.files.length > 1 ? 's' : ''
                                  }`}
                                </h3>
                                {data?.chat?.files &&
                                  data?.chat?.files.map((file, index) => {
                                    return (
                                      <div
                                        className="pt-3 flex gap-2 items-center"
                                        key={index}
                                      >
                                        {file.metadata?.type == 'youtube' ? (
                                          <img
                                            src={file.metadata?.thumbnail}
                                            className="w-16 h-10 mt-1"
                                          />
                                        ) : file.metadata?.type == 'pdf' ? (
                                          <PDFFileUpload />
                                        ) : (
                                          <UrlFileUpload />
                                        )}
                                        <div className="text-battleship-gray ml-2 flex-grow">
                                          <a
                                            className="text-nue-blue font-medium text-sm"
                                            target="_blank"
                                            href={file.metadata?.url}
                                            rel="noreferrer"
                                          >
                                            {file.metadata?.name}
                                          </a>
                                          {file.metadata?.type == 'youtube' ? (
                                            <p className="text-xs font-regular">
                                              {getLabelMinutes(
                                                file?.metadata?.size
                                              )}{' '}
                                              | {file?.metadata?.channel}
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Chat render */}
                    {data?.chat?.messages.map(
                      (item, ind) =>
                        !isHidden(item) &&
                        getTabs(item).map(
                          (message, index) =>
                            isSelected(item, message) && (
                              <div
                                key={message._id ? message._id : 'user' + index}
                              >
                                <div
                                  className={`w-full text-token-text-primary border-b border-black/10 gizmo:border-0`}
                                >
                                  <div className="p-4 justify-center text-base md:gap-6 md:py-6 pl-0 pr-4 m-auto">
                                    <div className="flex flex-1 gap-4 text-base mx-auto md:gap-6 gizmo:gap-3 gizmo:md:px-5 gizmo:lg:px-1 gizmo:xl:px-5 md:max-w-2xl lg:max-w-[38rem] gizmo:md:max-w-3xl gizmo:lg:max-w-[40rem] gizmo:xl:max-w-[48rem] xl:max-w-3xl }">
                                      <div className="flex-shrink-0 flex flex-col relative items-end">
                                        <div className={`rounded`}>
                                          <div className="relative p-1 rounded-sm h-9 w-9 flex items-center justify-center">
                                            <UserCircleIcon
                                              className={`w-6 h-6 transition dark:text-palladium text-cool-gray`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 gizmo:w-full md:gap-3 lg:w-[calc(100%-115px)] text-cool-gray">
                                        <div className="flex flex-grow flex-col gap-3 max-w-full">
                                          <div className="min-h-[20px] overflow-y-hidden flex flex-col items-start gap-3 overflow-x-auto break-words">
                                            <div className="relative flex flex-1 flex-wrap gap-2">
                                              {message.data.role !== 'user' &&
                                                getTabs(item).map(
                                                  (ele, idx) => (
                                                    <p
                                                      key={idx}
                                                      className={`w-fit font-semibold rounded-lg px-1 text-[11px] cursor-pointer select-none ${
                                                        isSelected(item, ele)
                                                          ? 'bg-nue-blue text-white dark:text-crystal-bell'
                                                          : 'bg-platinum text-cool-gray dark:bg-ship-grey dark:text-tropical-indigo'
                                                      } flex gap-1 items-center`}
                                                      onClick={() =>
                                                        isSelected(item, ele)
                                                          ? null
                                                          : handleOutput(
                                                              item,
                                                              ele,
                                                              idx,
                                                              ind
                                                            )
                                                      }
                                                    >
                                                      <span className="uppercase">
                                                        {ele.model}
                                                      </span>
                                                      {checkCapability(ele)}
                                                    </p>
                                                  )
                                                )}
                                            </div>
                                            <div className="empty:hidden gizmo:empty:block text-raisin-black dark:text-crystal-bell">
                                              {getTabs(item).map(
                                                (ele, idx) =>
                                                  isSelected(item, ele) &&
                                                  ele?.data?.content && (
                                                    <>
                                                      {ele.data.function &&
                                                        getCapabilityState(
                                                          ele.data.function
                                                        )}
                                                      <ContentMessage
                                                        key={idx}
                                                        message={
                                                          ele.data.content
                                                        }
                                                        userType={ele.data.role}
                                                        onOpenFull={(
                                                          content
                                                        ) => {
                                                          setDialogContent(
                                                            content
                                                          );
                                                          setShowImageDialog(
                                                            true
                                                          );
                                                        }}
                                                      />
                                                    </>
                                                  )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                    )}
                  </div>
                  <div className="flex flex-col relative">
                    <Box
                      sx={{
                        animation: 'jump 3s infinite ease-in-out',
                        '@keyframes jump': {
                          '0%, 20%, 50%, 80%, 100%': {
                            transform: 'translateY(0)',
                          },
                          '40%': {
                            transform: 'translateY(-15px)',
                          },
                          '60%': {
                            transform: 'translateY(-15px)',
                          },
                        },
                      }}
                      className={`absolute top-[-80px] left-0 right-0 mx-auto mb-12 w-[${
                        showPromptSidebar ? (isMobile ? 100 : 70) : 100
                      }%] md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient  !bg-transparent dark:md:bg-vert-dark-gradient pt-2 md:pl-2`}
                    >
                      <div className="relative flex h-full w-full flex-1 items-center justify-center gap-2">
                        <div
                          onClick={continueConversation}
                          className="flex items-center justify-center px-4 py-2 text-base font-semibold text-white bg-nue-blue rounded hover:bg-lavender"
                        >
                          <div className="flex w-full gap-2 items-center justify-center">
                            Continue conversation
                          </div>
                        </div>
                      </div>
                    </Box>
                    <Input />
                  </div>
                </div>
                {!showPromptSidebar && (
                  <div
                    className="z-10 fixed top-[40px] md:top-20 right-0 flex flex-col "
                    onClick={togglePromptSidebar}
                  >
                    <div className="mr-2.5 mt-3 mb-2 px-2 py-2 transition rounded-md transform text-white cursor-pointer bg-nue-blue">
                      <div className="flex">
                        <div className="flex items-center justify-center m-auto">
                          <ToolSvg className="h-6 w-6 cursor-pointer stroke-white" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    showPromptSidebar ? 'flex' : 'hidden'
                  } flex-col md:w-[${showPromptSidebar ? 30 : 0}%]`}
                >
                  <div className="flex flex-col h-full">
                    <PromptSide />
                  </div>
                </div>
              </div>
            </div>
            <ModalRegister
              isLoggedIn={store.isLoggedIn}
              open={registerModal}
              onClose={closeRegisterModal}
              setRedirectUrl={setRedirectUrl}
              url={`/chat?chat=${data?.chat?._id}`}
            />
          </div>
        )}
        {showImageDialog && (
          <ImageDialog onClose={() => setShowImageDialog(false)}>
            {dialogContent}
          </ImageDialog>
        )}
      </>
    );
  })
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

export default ShareChatPage;
