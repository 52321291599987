import { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/Modal';
// Icons
import { TrashIcon } from '@heroicons/react/24/solid';

const DeleteChats = inject('store')(
  observer(({ onClose, onDelete, deleted, setDeleted }) => {
    useEffect(() => {
      setDeleted(false);
    }, [setDeleted]);

    return (
      <>
        <Modal
          open={true}
          title="DELETE CHATS"
          iconTitle={
            <TrashIcon
              className="h-5 w-5 text-violet-blue"
              aria-hidden="true"
            />
          }
          onClose={onClose}
          onConfirm={onDelete}
          buttonConfirmProps={{
            disabled: deleted,
          }}
          onConfirmBtnText="Delete"
          onConfirmBtnIcon={<></>}
        >
          <div className="my-4 font-figtree">
            <div className="dialog-content-container-grade overflow-auto pr-2">
              <div className="mt-4">
                <label className="text-sm">
                  Are you sure you want to delete these chats?
                </label>
              </div>
            </div>
            {deleted ? (
              <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                Information deleted
              </div>
            ) : null}
          </div>
        </Modal>
      </>
    );
  })
);

export default DeleteChats;
