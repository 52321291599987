import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { observer, inject } from 'mobx-react';
import CreatePrompt from './CreatePrompt';

const TutorialModal = inject('store')(
  observer(({ mode, onInteraction }) => {
    const navigate = useNavigate();

    const [openDialogCreatePrompt, setOpenDialogCreatePrompt] = useState(false);

    const onTravel = (to) => {
      if (to == 'history') {
        navigate('/history');
        onInteraction('close');
      } else if (to == 'review') {
        navigate('/ai/develop-self-care-plan');
        onInteraction('close');
      } else if (to == 'create') {
        setOpenDialogCreatePrompt(true);
      }
    };

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50 font-figtree">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-raisin-black opacity-75"></div>
          </div>

          <div className="relative bg-seasalt rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col">
            <div className="px-6 overflow-auto relative">
              <div className="sticky top-0 bg-white">
                <div className="flex items-center pt-6">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-ghost-white">
                    {
                      {
                        save_history: (
                          <ClockIcon
                            className="h-6 w-6 text-cool-gray"
                            aria-hidden="true"
                          />
                        ),
                        create_prompt: (
                          <LightBulbIcon
                            className="h-6 w-6 text-cool-gray"
                            aria-hidden="true"
                          />
                        ),
                        review_prompt: (
                          <LightBulbIcon
                            className="h-6 w-6 text-cool-gray"
                            aria-hidden="true"
                          />
                        ),
                      }[mode]
                    }
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-raisin-black">
                      {
                        {
                          save_history: <>Save your history</>,
                          create_prompt: <>Create your own tool</>,
                          review_prompt: <>Review my tool</>,
                        }[mode]
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="dialog-content-container overflow-auto pr-2">
                <div className="mt-4">
                  {
                    {
                      save_history: (
                        <p className="text-sm text-cool-gray text-justify">
                          Improve your experience with Straico by unlocking its
                          power to save your work. You can either choose from
                          suggested tags or input your own keywords. Save your
                          work and query it whenever you need to.
                        </p>
                      ),
                      create_prompt: (
                        <p className="text-sm text-cool-gray text-justify">
                          Utilize the potential of Straico to effortlessly
                          create personalized prompts for all your writing
                          requirements.
                        </p>
                      ),
                      review_prompt: (
                        <p className="text-sm text-cool-gray text-justify">
                          Check and use your personalized prompt.
                        </p>
                      ),
                    }[mode]
                  }
                </div>
                <div className="mt-4 mb-2 p-2">
                  {
                    {
                      save_history: (
                        <img src="https://promptrack.com/wp-content/uploads/2023/03/history.gif"></img>
                      ),
                      create_prompt: (
                        <img src="https://promptrack.com/wp-content/uploads/2023/03/prompt_create.gif"></img>
                      ),
                      review_prompt: (
                        <img src="https://promptrack.com/wp-content/uploads/2023/03/prompt_review.gif"></img>
                      ),
                    }[mode]
                  }
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full px-6 pb-6 bottom-0">
              <Button title={'Close'} onClick={() => onInteraction('close')} />
              {
                {
                  save_history: (
                    <Button title={'Go'} onClick={() => onTravel('history')} />
                  ),
                  create_prompt: (
                    <Button title={'Go'} onClick={() => onTravel('create')} />
                  ),
                  review_prompt: (
                    <Button title={'Go'} onClick={() => onTravel('review')} />
                  ),
                }[mode]
              }
            </div>
          </div>
        </div>

        {openDialogCreatePrompt ? (
          <CreatePrompt onInteraction={onInteraction}></CreatePrompt>
        ) : (
          <></>
        )}
      </>
    );
  })
);

export default TutorialModal;
