import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from 'Components/Common/Modal/Modal';
import { TrashIcon } from '@heroicons/react/24/solid';

const DeleteMessage = inject('store')(
  observer(({ onClose, onDelete, open, deleted, setDeleted }) => {
    useEffect(() => {
      setDeleted(false);
    }, [setDeleted]);

    return (
      <Modal
        open={open}
        title="DELETE MESSAGE"
        iconTitle={
          <TrashIcon className="h-5 w-5 text-violet-blue" aria-hidden="true" />
        }
        onClose={onClose}
        onConfirm={onDelete}
        buttonConfirmProps={{
          disabled: deleted,
        }}
        onConfirmBtnText="Delete"
      >
        <div className="my-4">
          <div className="dialog-content-container-grade overflow-auto pr-2">
            <div className="mt-4">
              <label className="text-[14px] font-figtree">
                Deleting this output will also remove its paired input.
              </label>
            </div>
          </div>
          {deleted ? (
            <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
              Information deleted
            </div>
          ) : null}
        </div>
      </Modal>
    );
  })
);
export default DeleteMessage;
