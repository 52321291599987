// CustomNode.js
import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
  FolderIcon,
  FolderOpenIcon,
  ChatBubbleLeftEllipsisIcon,
  Bars2Icon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  InformationCircleIcon,
  TrashIcon as TrashIconSolid,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/solid';
import { useMediaQuery, Tooltip } from '@mui/material';
import useChatStore from 'Components/Chat/store';
import { useUpdateFolderChatModal } from 'Components/Chat/UpdateModalFolderChat/store';
import { useDeleteFolderChatModal } from 'Components/Chat/DeleteModalFolderChat/store';
import moment from 'moment';
import useTreeDNDChatStore from '../../Store';
import useThemeStore from 'Theme/store';

const CustomNode = ({ isOpen, ...props }) => {
  const { droppable, text, id, data } = props.node;

  // Zustand store
  const [selectedChat] = useChatStore((state) => [state.selectedChat]);
  const [isHovered, setIsHovered] = useState(false);
  const { selectedChatIds, setSelectedChatIds } = useTreeDNDChatStore();

  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const onClick = (e) => {
    handleToggle(e);
    if (props.node.data.type === 'chat') {
      props.onClickChat(data);
    }
  };

  const { setOpenShareChatModal, setOpenDeleteChatModal, setToDelete, search } =
    useTreeDNDChatStore();

  const { setSelectedFolder, setModalOpen } = useUpdateFolderChatModal();
  const { setSelectedFolder: setDeleteFolder, setModalOpen: setModalDelete } =
    useDeleteFolderChatModal();

  // onEdit folder
  const onEditFolder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFolder(props.node);
    setModalOpen(true);
  };

  // onDelete folder
  const onDeleteFolder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteFolder(props.node);
    setModalDelete(true);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const { theme: themeValue } = useThemeStore();

  const isSelected = (node) => {
    return selectedChatIds.includes(node.id);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation(); // Prevent onClick from being triggered
    const newSelectedChatIds = isSelected(props.node)
      ? selectedChatIds.filter((item) => item !== props.node.id)
      : [...selectedChatIds, props.node.id];

    setSelectedChatIds(newSelectedChatIds);
  };

  const truncateMessage = (message, searchTerm, contextWords = 5) => {
    if (typeof message !== 'string') {
      return '';
    }

    const escapedSearchTerm = searchTerm.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&'
    );

    const regex = new RegExp(
      `(?:\\S+\\s+){0,${contextWords}}(${escapedSearchTerm})(?:\\s+\\S+){0,${contextWords}}`,
      'i'
    );

    const fullSentenceRegex = new RegExp(
      `[^.!?]*?(${escapedSearchTerm})[^.!?]*[.!?]`,
      'i'
    );

    const fullSentenceMatch = message.match(fullSentenceRegex);

    if (fullSentenceMatch) {
      const highlightedSentence = fullSentenceMatch[0].replace(
        new RegExp(`(${escapedSearchTerm})`, 'ig'),
        '<span style="color: orange;">$1</span>'
      );
      return `...${highlightedSentence}...`;
    }

    const match = message.match(regex);

    if (match) {
      const highlighted = match[0].replace(
        new RegExp(`(${escapedSearchTerm})`, 'ig'),
        '<span style="color: orange; font-weight: bold;">$1</span>'
      );
      return `...${highlighted}...`;
    }

    const words = message.split(' ');
    const index = words.findIndex((word) =>
      word.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const start = Math.max(0, index - contextWords);
    const end = Math.min(words.length, index + contextWords + 1);
    const snippet = words.slice(start, end).join(' ');

    return (
      snippet.replace(
        new RegExp(`(${escapedSearchTerm})`, 'ig'),
        '<span style="color: orange; font-weight: bold;">$1</span>'
      ) + '...'
    );
  };

  const handleMessageClick = (message) => {
    props.onClickChat(data);
  };

  const messageStyles = `
  .message-hover:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Ajusta el color y la opacidad según tu preferencia */
  }
`;

  return (
    <>
      <div
        className="group tree-node flex items-center h-[32px] box-border w-full cursor-pointer"
        style={{ paddingInlineStart: indent }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          onClick={onClick}
          className="flex items-center justify-between font-figtree text-cool-gray py-[5px] px-[6px] w-full"
        >
          <div
            className={`flex items-center justify-center gap-[4px] py-[2px] px-[6px] w-full group-hover:bg-ghost-white dark:group-hover:bg-quicksilver group-hover:rounded ${
              selectedChat?.chat?._id === id && !droppable
                ? ' bg-ghost-white dark:bg-quicksilver rounded'
                : ''
            }`}
          >
            <div
              className={`block md:hidden cursor-grabbing drag-handle col-span-1`}
              ref={isMobile ? props.handleRef : null}
            >
              <Bars2Icon className="w-6 h-6 cursor-grabbing" />
            </div>
            <div>
              {' '}
              {!droppable ? (
                isHovered || isSelected(props.node) ? (
                  <Checkbox
                    className={'flex items-center mt-1'}
                    checked={isSelected(props.node)}
                    size="small"
                    disableRipple
                    sx={{
                      padding: 0,
                      borderRadius: '5px',
                    }}
                    onClick={handleCheckboxClick}
                    style={{
                      alignSelf: 'center',
                      transform: 'translateY(-1px)',
                    }}
                  />
                ) : !isMobile ? (
                  <ChatBubbleLeftEllipsisIcon
                    className={`w-5 h-5 ${
                      selectedChat?.chat?._id === id && !droppable
                        ? 'text-violet-blue'
                        : ''
                    }`}
                  />
                ) : (
                  <Checkbox
                    className={'flex items-center mt-1'}
                    checked={isSelected(props.node)}
                    size="small"
                    disableRipple
                    sx={{
                      padding: 0,
                      borderRadius: '5px',
                    }}
                    onClick={handleCheckboxClick}
                    style={{
                      alignSelf: 'center',
                      transform: 'translateY(-1px)',
                    }}
                  />
                )
              ) : (
                <>
                  {isOpen ? (
                    <FolderOpenIcon className="text-violet-blue w-6 h-6" />
                  ) : (
                    <FolderIcon className="w-6 h-6" />
                  )}
                </>
              )}
            </div>
            <p
              className={`truncate w-full ${
                themeValue == 'dark'
                  ? 'dark:text-quicksilver'
                  : 'text-cool-gray'
              } text-sm ${
                !droppable
                  ? ' line-height-[1.2]' +
                    (selectedChat?.chat?._id === id
                      ? ' font-bold text-battleship-gray dark:text-quicksilver'
                      : '')
                  : 'uppercase font-[500]' +
                    (isOpen
                      ? ' text-raisin-black font-bold dark:text-crystal-bell'
                      : '')
              }`}
            >
              {text}
            </p>

            {droppable ? (
              <div
                className={`col-span-3  lg:hidden flex-[0.2] ${
                  themeValue !== 'dark'
                    ? 'text-raisin-black'
                    : 'text-crystal-bell'
                } lg:group-hover:flex flex gap-1 items-center transition-opacity duration-300 ease-in-out`}
              >
                <div onClick={onEditFolder}>
                  <PencilIcon className="w-4 h-4" />
                </div>
                <div onClick={onDeleteFolder}>
                  <TrashIcon className="w-4 h-4" />
                </div>
              </div>
            ) : (
              <div className="lg:hidden lg:group-hover:flex flex items-center gap-[3px] transition-opacity duration-300 ease-in-out">
                <div
                  onClick={() => {
                    props.onClickChat(data);
                    setOpenShareChatModal(true);
                  }}
                >
                  <ArrowUpOnSquareIcon className="w-4 h-4" />
                </div>
                <div
                  onClick={() => {
                    props.onClickChat(data);
                    setToDelete(props.node.id);
                    setOpenDeleteChatModal(true);
                  }}
                >
                  <TrashIconSolid className="w-4 h-4" />
                </div>
                <Tooltip
                  title={
                    <>
                      <p className="text-sm font-bold">{text.slice(0, 300)}</p>
                      <p>
                        Modified at {''}
                        {moment(new Date(data?.last_modified)).format(
                          'DD MMMM[,] YYYY'
                        )}
                      </p>
                    </>
                  }
                  placement="right"
                >
                  <div>
                    <InformationCircleIcon className="w-4 h-4" />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.matchingMessages &&
        props.matchingMessages.length > 0 &&
        search !== '' && (
          <div style={{ paddingInlineStart: indent + 20 }} className="ml-4">
            {props.matchingMessages.map((message) => {
              const messageContent = Array.isArray(message.content)
                ? message.content.join(' ')
                : message.content;

              return (
                <div
                  key={message.messageId}
                  className="text-xs text-cool-gray dark:text-quicksilver cursor-pointer message-hover font-figtree"
                  onClick={() => handleMessageClick(message)}
                  dangerouslySetInnerHTML={{
                    __html: truncateMessage(messageContent, search),
                  }}
                />
              );
            })}
          </div>
        )}
    </>
  );
};

export default CustomNode;
