import { memo } from 'react';
import useThemeStore from 'Theme/store';

// eslint-disable-next-line react/display-name
export const HighlightedText = memo(({ text }) => {
  const getParts = (text) => {
    let parts = [];
    text.map((frg) => {
      switch (typeof frg) {
        case 'string': {
          let arr = frg.split(regex);
          parts = [...parts, ...arr];
          break;
        }
        default: {
          return parts.push(frg);
        }
      }
    });
    return parts;
  };
  // Create a regular expression with capturing groups for the opening tag, content, and closing tag
  const regex = /<\/(?:span|div|p|a)>/g;

  function extractPartsForTemplate(text) {
    const regex = /<span id='template'>[^<]*<\/span>/g;

    // Encontrar todas las coincidencias del patrón en el texto
    const matches = text.match(regex) || [];

    // Dividir el texto en partes basadas en las coincidencias
    const parts = text.split(regex);

    // Concatenar las partes y las coincidencias en orden
    const result = [];
    for (let i = 0; i < parts.length; i++) {
      result.push(parts[i]);
      if (i < matches.length) {
        result.push(matches[i]);
      }
    }

    return result;
  }

  // Split the text into an array of substrings based on the regex
  const parts =
    typeof text == 'string' ? extractPartsForTemplate(text) : getParts(text);
  const imgParts = [];
  const { theme: themeValue } = useThemeStore();
  // Map over the parts array and apply styles to the highlighted text
  const highlightedText = parts.map((part, index) => {
    if (typeof part == 'string') {
      return regex.test(part) ? (
        <span
          key={index}
          className={`w-fit whitespace-pre-wrap rounded-md px-4 py-[4px] font-semibold ${
            themeValue == 'dark'
              ? 'dark:text-crystal-bell dark:bg-ship-grey'
              : 'text-raisin-black bg-lavender'
          }`}
        >
          {part.replace('</span>', '').replace("<span id='template'>", '')}
        </span>
      ) : (
        part
      );
    } else {
      if (parts[index + 1] && typeof parts[index + 1] != 'string') {
        imgParts.push(part);
        return;
      }

      imgParts.push(part);
      return (
        <div
          key={index}
          className={`py-2 grid gap-2 justify-items-center ${
            imgParts.length > 1 && 'grid-cols-2'
          }`}
        >
          {imgParts}
        </div>
      );
    }
  });

  return (
    <div className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 whitespace-pre-wrap text-base dark:text-crystal-bell">
      {highlightedText}
    </div>
  );
});
