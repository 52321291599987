// Mobx
import { observer, inject } from 'mobx-react';
// MUI
import { Button } from '@mui/material';
// Store
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
// Toast
import { toast } from 'react-hot-toast';
// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import { useChat } from 'Context/Chat/Chat.context';
import useThemeStore from 'Theme/store';

const DeleteFolder = ({ store }) => {
  const {
    deleteFolderModal: open,
    setDeleteFolderModal: setOpen,
    setSelectedFolder,
    selectedFolder,
  } = useTreeDNDTemplatesStore();
  const { templatesFolders, setTemplatesFolders } = useChat();

  const handleClose = () => {
    setOpen(false);
    setSelectedFolder(null);
  };

  const onDelete = async () => {
    try {
      await store.api.delete(`/template/folders/${selectedFolder._id}`);
      setTemplatesFolders(
        templatesFolders.filter((f) => f._id !== selectedFolder._id)
      );
      toast.success('Folder delete successfully');
    } catch (err) {
      toast.error(err?.data?.message);
    } finally {
      handleClose();
    }
  };

  const { theme } = useThemeStore();

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={open}
      handleClose={handleClose}
    >
      <p
        className={`font-figtree line-clamp-1 font-bold uppercase text-md ${
          theme == 'dark' ? 'dark:text-crystal-bell' : 'text-raisin-black'
        }`}
      >
        Delete template folder
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <p
          className={`font-figtree text-md ${
            theme == 'dark' ? 'dark:text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          Are you sure you want to delete your folder? If you delete it your
          contained templates will go to the "My templates" directory again.
        </p>
      </div>
      <div className="w-full flex justify-between mt-6 gap-4">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          onClick={onDelete}
        >
          <span className="font-figtree font-bold">Delete</span>
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClose}
        >
          <span className="font-figtree font-bold">Cancel</span>
        </Button>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(DeleteFolder));
