import React from 'react';
// Icons
import { XMarkIcon } from '@heroicons/react/20/solid';
// MUI
import { Button, IconButton, Slide } from '@mui/material';
// Icons
import { ArrowsPointingOutIcon } from '@heroicons/react/20/solid';
//Hooks
import useModal from 'Hooks/useModal';
// Theme
import useThemeStore from 'Theme/store';
// Components
import TextAreaHightlight from 'Components/Chat/TextAreaHightlight/TextAreaHightlight';
import FullScreenModal from 'Components/Common/FullScreenModal';
import CustomItemVariable from 'Features/CreateTemplatesForm/Components/CustomItemVariable';

const ViewPromptSection = ({
  selectedTool,
  promptValue = '',
  formValues = {},
}) => {
  // Modal state and methods
  const { isOpen, openModal, closeModal } = useModal();

  // Input validation
  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
    if (
      Object.entries(formValues).filter(([, value]) => value !== '').length > 0
    ) {
      let newInputValue = promptValue;
      Object.entries(formValues).forEach(([key, value]) => {
        newInputValue = newInputValue.replaceAll(
          '${' + key + '}',
          '${' + value + '}'
        );
      });
      setInputValue(newInputValue);
    } else {
      setInputValue(promptValue);
    }
  }, [formValues, promptValue]);

  // Highlighting words
  const highligthingWords = [
    {
      highlight: /\$\{([\w\s.,?!;-]*)\}/g,
      className:
        'bg-seasalt text-raisin-black rounded-md py-1 px-2 font-semibold',
    },
  ];

  // Handle see file
  const handleSeeFile = (file) => {
    window.open(file?.url ?? file, '_blank');
  };

  const { theme } = useThemeStore();

  return (
    <div className="font-figtree">
      <TextareaRender
        inputValue={inputValue}
        highligthingWords={highligthingWords}
        filesForm={formValues?.files}
        defaultFiles={selectedTool?.files ?? []}
        handleSeeFile={handleSeeFile}
        withoutMaxHeight
      />
      <div className="flex justify-center items-center mt-3">
        <Button
          onClick={openModal}
          startIcon={<ArrowsPointingOutIcon className="w-4 h-4" />}
        >
          <span className="font-bold">Full screen</span>
        </Button>
      </div>
      <FullScreenModal
        open={isOpen}
        onClose={closeModal}
        transition={Transition}
      >
        <div className="h-full w-full overflow-hidden">
          <div
            className="border-ghost-white border-solid bg-violet-blue h-[3px] w-full"
            content=" "
          />
          <div className="flex flex-col justify-center items-center h-full p-2 md:px-8 md:py-4">
            <div className="uppercase mb-2 text-xl font-bold text-raisin-black dark:text-crystal-bell flex justify-between px-4 w-full">
              <p>{selectedTool?.title}</p>
              <IconButton onClick={closeModal}>
                <XMarkIcon className="w-7 h-7" />
              </IconButton>
            </div>
            <div className="h-[95%] px-4 py-8 w-full bg-seasalt">
              <TextareaRender
                withoutMaxHeight={false}
                inputValue={inputValue}
                highligthingWords={highligthingWords}
                filesForm={formValues?.files}
                defaultFiles={selectedTool?.files ?? []}
                handleSeeFile={handleSeeFile}
                textareaProps={{
                  classNameMainContainer: `max-h-full ${
                    theme == 'dark' ? 'dark:bg-night-black' : 'bg-white '
                  }`,
                }}
              />
            </div>
          </div>
        </div>
      </FullScreenModal>
    </div>
  );
};

// TextAreaHightlight component
export const TextareaRender = ({
  inputValue,
  highligthingWords,
  filesForm,
  defaultFiles,
  handleSeeFile,
  textareaProps,
  withoutMaxHeight = false,
}) => {
  const { theme } = useThemeStore();
  const localHandleSeeFile = (file) => () => {
    if (handleSeeFile) handleSeeFile(file);
  };
  return (
    <TextAreaHightlight
      classNameMainContainer={`${
        theme == 'dark' ? 'dark:bg-night-black' : 'bg-white '
      } ${withoutMaxHeight ? 'max-h-[10.5rem]' : ''}`}
      classNameContainer={`resize-none outline-none focus:outline-none text-md rounded min-w-full font-regular 
        shadow-none
        font-figtree text-base  ${
          theme == 'dark'
            ? 'dark:bg-night-black dark:text-crystal-bell'
            : 'bg-white text-raisin-black'
        }`}
      autoFocus
      maxRows={10}
      placeholder=""
      value={inputValue}
      disabled={true}
      highlightWords={highligthingWords}
      {...textareaProps}
    >
      <div className="flex flex-wrap gap-1 mt-3 mx-2">
        {Object.entries(defaultFiles ?? {})?.map(([, i]) => {
          if (i) {
            return (
              <CustomItemVariable
                key={i?.name ?? i}
                {...i}
                name={i?.name ?? i}
                type="attachment"
                dontShowCopyField
                onSee={i.url ? localHandleSeeFile(i) : undefined}
              />
            );
          }
        })}
        {Object.entries(filesForm ?? {})?.map(([key, file]) => {
          return (
            <CustomItemVariable
              key={file?._id}
              {...file}
              name={file?.name}
              type="attachment"
              dontShowCopyField
              onSee={file?.url ? localHandleSeeFile(file) : undefined}
            />
          );
        })}
      </div>
    </TextAreaHightlight>
  );
};

// Transition component for the modal
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ViewPromptSection;
