import { useCallback, useEffect, useState } from 'react';
// Store
import useCreateTemplatesStore from './Store';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
// MUI
import { LoadingButton } from '@mui/lab';
import { Button, LinearProgress, SwipeableDrawer } from '@mui/material';
// Toast
import { toast } from 'react-hot-toast';
// RHF
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { validationSchema } from './validation.schema';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// Components
import useThemeStore from 'Theme/store';
import ModalAttachment from './Components/ModalAttachment';
import ModalConfirmClose from './Components/ModalConfirmClose/ModalConfirmClose';
import ModalDropdownVariable from './Components/ModalDropdownVariable';
import ModalImage from './Components/ModalImage';
import ModalTextVariable from './Components/ModalTextVariable';
import SectionGeneral from './Components/SectionGeneral';
import SectionPrompt from './Components/SectionPrompt';
import SectionReview from './Components/SectionReview';
import { replaceAllVariablesPrompt } from './helpers';

const ANCHOR = 'right';

const INITIAL_FORM = {
  name: '',
  description: '',
  default_model: '',
  variables: [],
  files: [],
  location: '',
  promptVariables: [],
  multi_default_models: [],
};

const CreateTemplatesForm = ({ store }) => {
  const { createTool, editTool, selectedPrompt } = useChat();
  //Store
  const {
    openModal,
    setOpenModal,
    step,
    maxSteps,
    setStep,
    loadingCreateTemplate,
    setLoadingCreateTemplate,
    edit,
    defaultData,
    setDefaultData,
    setEdit,
    modalConfirmClose,
    setModalConfirmClose,
  } = useCreateTemplatesStore();

  const toggleDrawer = () => {
    setOpenModal(!openModal);
    setEdit(false);
    reset(INITIAL_FORM);
  };

  const handleCloseConfirmClose = () => {
    setModalConfirmClose(false);
    setOpenModal(false);
    setEdit(false);
    reset(INITIAL_FORM);
  };
  const handleCancelConfirmClose = () => setModalConfirmClose(false);

  const handleClose = () => setModalConfirmClose(true);

  // Steps methods
  const handleNext = () => setStep(step + 1);

  const handleBack = () => setStep(step - 1);

  // Get models
  const [models, setModels] = useState([]);
  const getModels = useCallback(async () => {
    try {
      const response = await store.api.get(`/model`);
      const data = await response.data;
      let transformedModels = data.models.map((model) => ({
        id: model._id,
        title: model.name,
      }));
      setModels(transformedModels);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getModels();
  }, []);

  const stepsContent = {
    1: <SectionGeneral models={models} />,
    2: <SectionPrompt />,
    3: <SectionReview models={models} />,
  };

  // Reset step
  useEffect(() => {
    setStep(1);
    setDefaultData({});
  }, [openModal]);

  // useEffect(() => {
  //   console.log('defaultData', defaultData);
  // }, [defaultData]);

  // Form
  const methods = useForm({
    defaultValues: edit ? defaultData : INITIAL_FORM,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // Toast errors show errors in form
  useEffect(() => {
    if (Object.entries(errors).length > 0) {
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full  bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <p className="font-bold text-black">
              ❌ {Object.keys(errors).length} errors in form
            </p>
            <ul className="list-disc list-inside">
              {Object.entries(errors).map(([key, value]) => (
                <li key={key}>{value.message}</li>
              ))}
            </ul>
          </div>
          <div className="fixed top-0 right-2 z-50">
            <div
              onClick={() => toast.dismiss(t.id)}
              className="w-full rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-white "
            >
              <XMarkIcon className="h-6 w-6" />
            </div>
          </div>
        </div>
      ));
    }
  }, [errors]);

  useEffect(() => {
    if (edit) {
      methods.reset(defaultData);
    }
  }, [edit, defaultData]);

  // Submit
  const onSubmit = async (data) => {
    // Check if the user select gpt-4 vision model and add at least one image

    if (
      data.default_model === '65651500e319cd7affbf71b6' &&
      data?.variables?.filter((i) => i?.type === 'image').length === 0
    ) {
      toast.error(
        'You need to add at least one image to this template, remember that you select gpt-4 vision model'
      );
      return;
    }

    if (data.multi_default_models.length === 0) {
      toast.error('You must select at least 1 LLMs');
      return;
    }

    // Submit logic
    setLoadingCreateTemplate(true);

    // Replace all variables in prompt to correct format
    const validatedPrompt = replaceAllVariablesPrompt(data.prompt, data);
    const variablesClean = data.variables.map((i) => {
      if (i.variable != '') {
        return i;
      }
    });

    try {
      if (edit) {
        await editTool(
          {
            name: data?.name,
            description: data?.description,
            value: validatedPrompt,
            files: data?.files?.map((i) => i._id),
            default_model: data?.default_model,
            parent_folder: data?.location,
            variables: [...variablesClean, ...(data?.promptVariables ?? [])],
            idPrompt: selectedPrompt?._id,
            multi_default_models: data?.multi_default_models,
          },
          data.id
        );
      } else {
        await createTool({
          name: data?.name,
          description: data?.description,
          files: data?.files?.map((i) => i._id),
          value: validatedPrompt,
          parent_folder: data?.location,
          default_model: data?.default_model,
          variables: [...variablesClean, ...(data?.promptVariables ?? [])],
          multi_default_models: data?.multi_default_models,
        });
      }
      toast.success(edit ? 'Template updated' : 'Template created', {
        icon: '🚀',
      });
      toggleDrawer();
    } catch (error) {
      toast.error(
        edit ? 'Error updating template' : 'Error creating template',
        {
          icon: '🚨',
        }
      );
    } finally {
      setLoadingCreateTemplate(false);
    }
  };

  const { theme } = useThemeStore();

  return (
    <>
      <FormProvider {...methods}>
        <SwipeableDrawer
          anchor={ANCHOR}
          open={openModal}
          onClose={handleClose}
          onOpen={toggleDrawer}
          PaperProps={{
            sx: {
              bgcolor: theme == 'dark' ? '#252425' : '#F7F7F8',
              overflow: 'hidden',
            },
          }}
        >
          <div className="w-full">
            <LinearProgress
              variant="determinate"
              sx={{
                backgroundColor: '#D4D5E6',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#5256A6',
                },
              }}
              value={(step * 100) / maxSteps}
            />
          </div>
          <div className="flex items-center justify-center w-[400px] relative px-4 py-7 h-full font-figtree">
            <div className="absolute top-6 right-6">
              <XMarkIcon
                className={`h-6 w-6 text-battleship-gray ${
                  theme == 'dark'
                    ? 'dark:text-crystal-bell'
                    : 'text-raisin-black'
                } cursor-pointer`}
                onClick={handleClose}
              />
            </div>
            {/* // Content */}
            <div className="w-full h-full flex flex-col">
              <div className="mb-7">
                <p
                  className={`text-md font-bold text-left ${
                    theme == 'dark'
                      ? 'dark:text-crystal-bell'
                      : 'text-raisin-black'
                  } uppercase`}
                >
                  {edit ? 'Edit' : 'New'} prompt template
                </p>
              </div>
              <hr className="border-platinum w-full" />
              <div className="flex-1 flex flex-col py-6 overflow-y-auto mb-4">
                {stepsContent[step]}
              </div>
              <hr className="border-platinum w-full mb-7" />
              <div className="flex items-center justify-between">
                <Button
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  onClick={handleBack}
                  disabled={step === 1}
                  variant="outlined"
                  size="small"
                  startIcon={<ChevronLeftIcon className="h-4 w-4" />}
                >
                  <span className="font-semibold">Previous</span>
                </Button>
                {step < maxSteps ? (
                  <Button
                    startIcon={<ChevronRightIcon className="h-4 w-4" />}
                    onClick={handleNext}
                    disabled={step === maxSteps}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <span className="font-bold">Next</span>
                  </Button>
                ) : (
                  <LoadingButton
                    startIcon={<CheckCircleIcon className="h-4 w-4" />}
                    loading={loadingCreateTemplate}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="font-bold">Save</span>
                  </LoadingButton>
                )}
              </div>
            </div>
          </div>
        </SwipeableDrawer>
        {/* MODALS */}
        <ModalConfirmClose
          open={modalConfirmClose}
          onCancel={handleCancelConfirmClose}
          onClose={handleCloseConfirmClose}
        />
        <ModalTextVariable />
        <ModalDropdownVariable />
        <ModalAttachment />
        <ModalImage />
      </FormProvider>
    </>
  );
};

export default inject('store')(observer(CreateTemplatesForm));
