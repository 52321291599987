function Button({
  title,
  onClick,
  Icon,
  disabled,
  noMarginTop,
  children,
  color,
  type,
  CustomIcon,
  fontColor,
  fontSize,
  customRound,
  customPaddig,
}) {
  return (
    <button
      type={type}
      className={
        disabled
          ? `select-none ${
              customPaddig ? customPaddig : 'py-2 px-6'
            }  bg-cool-gray hover:bg-battleship-gray disabled hover:to-raisin-black ${
              fontColor ? fontColor : 'text-ghost-white'
            }  ${
              customRound ? customRound : 'rounded-md'
            } flex md:inline-flex ${
              fontSize ? fontSize : 'text-lg'
            } cursor-pointer ${
              !noMarginTop && 'mt-4'
            } md:mx-0 transition uppercase font-figtree font-bold`
          : `select-none ${customPaddig ? customPaddig : 'py-2 px-6'} ${
              !color ? 'bg-nue-blue' : color
            } hover:${!color ? 'bg-nue-blue/70' : color + '/70'} ${
              fontColor ? fontColor : 'text-ghost-white'
            }  ${
              customRound ? customRound : 'rounded-md'
            } flex md:inline-flex ${
              fontSize ? fontSize : 'text-lg'
            } cursor-pointer ${
              !noMarginTop && 'mt-4'
            } md:mx-0 transition uppercase font-figtree font-bold flex items-center justify-center gap-2`
      }
      onClick={disabled ? null : onClick}
    >
      {Icon && (
        <Icon
          className={
            disabled
              ? `h-6 w-6 mr-1 text-ghost-white`
              : 'h-6 w-6 mr-1 text-platinum'
          }
        />
      )}
      {CustomIcon && CustomIcon}
      {title}
      {children}
    </button>
  );
}

export default Button;
