import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import StraicoSymbolIcon from '../../../Theme/icons/straicoSymbol';
import ModelsIcon from '../../../Theme/icons/modelsIcon';
import TemplatesIcon from '../../../Theme/icons/templatesIcon';
import * as modelIcons from '../../../Theme/icons/modelsIcons/index';
import {
  PlusCircleIcon,
  ChatBubbleLeftRightIcon,
  StarIcon,
  SpeakerWaveIcon,
  PhotoIcon,
  PencilSquareIcon,
  BookOpenIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Components
import ConversationSide from 'Components/Chat/ConversationSide/ConversationSide';
import useChatStore, {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
  LOADING_FAV_TOOLS,
} from 'Components/Chat/store';
import { useChat } from '../../../Context/Chat/Chat.context';
import PromptSide from 'Components/Chat/PromptSide/PromptSide';
import Loader from 'Components/Loader';

const PowerPanel = ({ store }) => {
  const { theme: themeValue } = useThemeStore();

  const [openModels, setOpenModels] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [loadingFavTools, setLoadingFavTools] = useState(false);
  const [favTools, setFavTools] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);
  const setPowerPanelOpen = useChatStore((state) => state.setPowerPanelOpen);
  const loadingChats = useChatStore((state) => state.loadingChats);
  const selectedChat = useChatStore((state) => state.selectedChat);
  const chatList = useChatStore((state) => state.chatList);
  const selectedModels = useChatStore((state) => state.selectedModels);
  const setShowModelsDialog = useChatStore(
    (state) => state.setShowModelsDialog
  );
  const addEvent = useChatStore((state) => state.addEvent);
  const currentEvent = useChatStore((state) => state.currentEvent);
  const runEvent = useChatStore((state) => state.runEvent);

  const {
    setSelectedTool,
    toggleShowPromptModal,
    setShowPromptList,
    sidebarTools,
  } = useChat();

  const baseAccordionStyles = () => {
    return {
      backgroundColor: themeValue == 'dark' ? '#1D1C1E' : '#EDEDF2',
      color: '#9091A4',
      '&:before': {
        display: 'none',
      },
      overflow: 'hidden',
    };
  };

  const accordionStyles = () => {
    return {
      ...baseAccordionStyles(),
      '&.Mui-expanded': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      '& > .MuiCollapse-root': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 266px)',
      },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region > .MuiAccordionDetails-root':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
    };
  };

  const getIcon = (icon) => {
    const Icon = get(modelIcons, icon);
    return Icon ? (
      <Icon className="h-6 w-6" />
    ) : (
      <ModelsIcon className="h-6 w-6 stroke-cool-gray" />
    );
  };

  const handleIconClick = (accordionId) => {
    setPowerPanelOpen(true);
    if (accordionId == 'models') {
      setOpenModels(true);
    } else {
      setOpenAccordion(accordionId);
    }
    navigate('/chat');
  };

  const handleAccordionChange = (accordionId) => (event, isExpanded) => {
    setOpenAccordion(isExpanded ? accordionId : false);
  };

  const sortArrayAlphabetically = (array) => {
    return array.sort((a, b) => a.title.localeCompare(b.title));
  };

  const fetchFavs = async () => {
    try {
      setLoadingFavTools(true);
      const userId = store.profile._id;
      const response = await store.api.get(`/tool/favorite-tools/${userId}`);
      setLoadingFavTools(false);
      if (response.data && response.data.tools) {
        setFavTools(sortArrayAlphabetically(response.data.tools));
      } else {
        // Handle scenario when no tools are found or response is not as expected
        console.error(
          'No favorite tools found or unexpected format:',
          response
        );
      }
    } catch (error) {
      console.error('Failed to fetch favorite tools:', error);
      setLoadingFavTools(false);
    }
  };

  const handleSelectPrompt = (id) => {
    const selectedToolLocal = sidebarTools.filter(
      (tool) => tool?._id === id
    )[0];
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
    setShowPromptList(false);
  };

  const onClickTemplate = (tool) => {
    handleSelectPrompt(tool?._id);
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  useEffect(() => {
    fetchFavs();
  }, []);

  useEffect(() => {
    if (currentEvent && currentEvent.name == LOADING_FAV_TOOLS) {
      runEvent(fetchFavs);
    }
  }, [currentEvent]);

  return (
    <div
      className={`h-full z-50 pt-5 flex flex-col items-center bg-ghost-white dark:bg-umbra duration-200 max-md:hidden ${
        powerPanelOpen ? 'w-64' : 'w-14 gap-4'
      }`}
    >
      <div
        className={`${
          powerPanelOpen && 'w-full mb-4'
        } flex items-center justify-between px-5`}
      >
        <StraicoSymbolIcon
          className={`h-9 fill-nue-blue cursor-pointer select-none duration-500 ${
            powerPanelOpen && 'rotate-[360deg]'
          }`}
          onClick={() => setPowerPanelOpen(!powerPanelOpen)}
        />
        {powerPanelOpen && (
          <>
            <div
              className="w-8 h-8 flex justify-center items-center bg-lavender rounded-xl cursor-pointer select-none"
              onClick={() => addEvent(SCRIPT_FOR_TTS)}
            >
              <SpeakerWaveIcon className="w-[18px] h-[18px] text-violet-blue" />
            </div>
            <div
              className="w-8 h-8 flex justify-center items-center bg-lavender rounded-xl cursor-pointer select-none"
              onClick={() => {
                setPowerPanelOpen(false);
                navigate('/images');
              }}
            >
              <PhotoIcon className="w-[18px] h-[18px] text-violet-blue" />
            </div>
            <div
              className="flex items-center justify-center gap-2 px-2 h-[38px] text-white bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl select-none"
              onClick={() => {
                addEvent(EVENT_NEW_CHAT);
              }}
            >
              <PlusCircleIcon className="h-[21px] w-[21px]" />
              <span>New chat</span>
            </div>
          </>
        )}
      </div>

      {/* new chat */}
      {!powerPanelOpen && (
        <div
          className="flex items-center justify-center w-[34px] h-[34px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl"
          onClick={() => addEvent(EVENT_NEW_CHAT)}
        >
          <PlusCircleIcon className="h-[21px] w-[21px] text-white" />
        </div>
      )}

      {powerPanelOpen ? (
        <div className="w-full h-full flex-1 flex flex-col overflow-auto">
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={baseAccordionStyles()}
            expanded={openModels}
            onChange={(event, isExpanded) => setOpenModels(isExpanded)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="models-content"
              id="models-header"
            >
              <ModelsIcon className="h-[21px] w-[21px] stroke-cool-gray mr-3" />
              <div className="flex-1">Current models</div>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className="flex gap-2 items-center">
                <div className="flex-1 flex flex-col gap-2 bg-white dark:bg-night-black rounded-xl p-2">
                  {selectedModels.map((model, index) => (
                    <div key={index} className="flex gap-2">
                      {getIcon(model.icon)}
                      <span className="flex-1 line-clamp-1">
                        {model.abbreviation}
                      </span>
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => {
                    navigate('/chat');
                    setShowModelsDialog(true);
                  }}
                  className="w-8 h-8 flex justify-center items-center bg-lavender dark:bg-ship-grey rounded-xl cursor-pointer select-none"
                >
                  <PencilSquareIcon className="w-[18px] h-[18px] text-violet-blue" />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'chats'}
            onChange={handleAccordionChange('chats')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="chats-content"
              id="chats-header"
            >
              <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] mr-3" />
              <div className="flex-1">My chats</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <motion.div className="z-10 h-full relative flex-1 flex flex-col overflow-auto">
                <ConversationSide
                  show={true}
                  onClick={() => addEvent(EVENT_NEW_CHAT)}
                  onSelectChat={(chat) => addEvent(EVENT_SELECT_CHAT, chat)}
                  loading={loadingChats}
                  chatSelectedIndex={selectedChat?.index}
                  chats={chatList}
                  currentChatId={selectedChat?.chat}
                  newDesing
                />
              </motion.div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'templates'}
            onChange={handleAccordionChange('templates')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="tamplates-content"
              id="tamplates-header"
            >
              <TemplatesIcon className="h-[21px] w-[21px] stroke-cool-gray mr-3" />
              <div className="flex-1">Templates</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <PromptSide newDesing />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'favorites'}
            onChange={handleAccordionChange('favorites')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="favotites-content"
              id="favotites-header"
            >
              <StarIcon className="h-[21px] w-[21px] mr-3" />
              <div className="flex-1">Favorite Templates</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="flex-1 h-full flex flex-col">
                {loadingFavTools ? (
                  <div className="flex items-center justify-center h-full">
                    <Loader />
                  </div>
                ) : favTools.length > 0 ? (
                  <>
                    <div className="px-5">
                      <input
                        id="keyword"
                        className="dark:bg-night-black dark:text-crystal-bell bg-white text-raisin-black text-base focus:outline-none w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray"
                        autoComplete="off"
                        type="text"
                        name="keyword"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="flex-1 overflow-auto px-5">
                      {favTools
                        .filter((tool) =>
                          tool.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((tool) => (
                          <div
                            key={tool._id}
                            className="flex cursor-pointer select-none items-start hover:bg-lavender gap-2 p-1 group rounded"
                            onClick={() => onClickTemplate(tool)}
                          >
                            <BookOpenIcon className="w-5 h-5 mt-2" />
                            <div className="flex-1">
                              {tool.title}
                              {tool?.multi_default_models?.length > 1 ? (
                                <div className={'flex items-center'}>
                                  <Tooltip
                                    title={
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: tool.multi_default_models
                                            .map((model) => model.name)
                                            .join('<br>'),
                                        }}
                                      />
                                    }
                                    arrow
                                  >
                                    <p className="flex items-center font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                      {tool?.multi_default_models[0]?.name} +{' '}
                                      {tool?.multi_default_models?.length - 1}
                                      <SparklesIcon className="w-[10px] h-[10px] mr-1 ml-1" />
                                    </p>
                                  </Tooltip>
                                </div>
                              ) : tool?.multi_default_models?.length == 1 ? (
                                <>
                                  <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                    {tool?.multi_default_models[0]?.name}
                                  </p>
                                </>
                              ) : tool?.default_model ? (
                                <>
                                  <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                    {tool?.default_model?.name}
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="px-5 text-center">
                    You don't have favorite templates.
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <>
          <div
            onClick={() => handleIconClick('models')}
            className="flex items-center gap-3 text-cool-gray cursor-pointer select-none"
          >
            <ModelsIcon className="h-[21px] w-[21px] stroke-cool-gray" />
          </div>
          <div
            onClick={() => handleIconClick('chats')}
            className="flex items-center gap-3 text-cool-gray cursor-pointer select-none"
          >
            <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] " />
          </div>
          <div
            onClick={() => handleIconClick('templates')}
            className="flex items-center gap-3 text-cool-gray cursor-pointer select-none"
          >
            <TemplatesIcon className="h-[21px] w-[21px] stroke-cool-gray" />
          </div>
          <div
            onClick={() => handleIconClick('favorites')}
            className="flex items-center gap-3 text-cool-gray cursor-pointer select-none"
          >
            <StarIcon className="h-[21px] w-[21px]" />
          </div>
        </>
      )}
    </div>
  );
};

export default inject('store')(observer(PowerPanel));
