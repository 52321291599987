function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill="#0071A9" />
      <path
        d="M4 6.18712C4 6.26807 4.1031 6.39528 4.23197 6.46467C4.43817 6.56875 4.45106 6.97352 4.48972 11.8423C4.52838 17.0579 4.52838 17.1042 4.25775 17.3471C3.85824 17.7056 4.05155 17.8328 4.915 17.7749C5.75268 17.7287 5.98465 17.6246 5.63669 17.4511C5.43049 17.3471 5.41761 17.0464 5.41761 14.028C5.41761 12.2008 5.44338 10.7436 5.46916 10.7667C5.49493 10.7899 5.61092 11.3797 5.7269 12.0851C5.84289 12.779 5.95887 13.4613 5.98465 13.5885C6.01042 13.7157 6.07486 14.0511 6.11352 14.3402C6.34549 15.8668 6.68057 17.5899 6.745 17.6477C6.78366 17.6824 7.0543 17.7287 7.33782 17.7403L7.8662 17.7634L7.87909 13.7736C7.87909 7.48236 7.90486 6.62658 8.09817 6.4531C8.44613 6.14086 8.26571 6.01365 7.49247 6.01365C6.69345 6.01365 6.40993 6.19868 6.78366 6.47623C6.92543 6.59188 6.96409 7.11229 6.9512 9.41367C6.92543 12.5246 6.89965 12.5014 6.37127 9.02047C6.19085 7.78305 6.02331 6.66127 5.99754 6.53406C5.97176 6.40685 5.94599 6.23338 5.94599 6.15242C5.9331 5.93269 4 5.95582 4 6.18712Z"
        fill="white"
      />
      <path
        d="M9.98982 6.09478C9.59031 6.22199 9.20369 6.64989 9.06193 7.10091C8.95883 7.43629 8.92017 8.98596 8.93306 12.2472C8.95883 17.4398 8.94594 17.3819 9.83517 17.8445C10.312 18.0989 10.8275 18.0411 11.3559 17.671C12.026 17.1853 12.0518 17.0234 12.0518 12.3282C12.0518 7.63289 11.9874 7.01996 11.4203 6.46485C11.0852 6.12948 10.4022 5.95601 9.98982 6.09478ZM10.6857 7.42472C10.7373 7.63289 10.7631 9.73766 10.7631 12.0969C10.7502 15.5431 10.7115 16.4336 10.5697 16.6071C10.4795 16.7343 10.3507 16.7806 10.2862 16.7343C10.1574 16.6187 10.0671 8.25738 10.1831 7.54037C10.2733 6.96214 10.5697 6.89275 10.6857 7.42472Z"
        fill="white"
      />
      <path
        d="M12.4661 6.16446C12.4661 6.25698 12.5821 6.40732 12.7238 6.48827C12.9687 6.65018 12.9816 6.82365 13.0073 11.7386C13.046 16.6421 13.0589 16.8387 13.3166 17.2897C13.8192 18.1571 14.876 18.1686 15.3915 17.3128C15.5977 16.9775 15.6235 16.457 15.6621 11.7733C15.7008 6.77739 15.7137 6.59235 15.9585 6.4767C16.0874 6.40732 16.2034 6.2801 16.2034 6.18759C16.2034 6.06038 16.0359 6.01412 15.4946 6.01412H14.7858L14.7729 10.9522C14.7729 13.6815 14.7213 16.1332 14.6827 16.4108C14.5796 16.9775 14.3218 17.1625 14.2059 16.7462C14.1672 16.5958 14.1156 14.1325 14.1028 11.2761L14.077 6.07194L13.278 6.03725C12.6465 6.01412 12.4661 6.03725 12.4661 6.16446Z"
        fill="white"
      />
      <path
        d="M17.3452 6.30297C16.7266 6.60366 16.572 7.12407 16.6235 8.63904C16.6751 10.1656 16.8039 10.5241 17.9123 12.1431C18.2602 12.6751 18.5437 13.2649 18.6211 13.6119C18.7757 14.3867 18.8273 15.9942 18.6855 15.9248C18.6339 15.8901 18.6082 16.0983 18.6339 16.3758C18.6726 16.8269 18.6468 16.8847 18.4149 16.8847C18.0154 16.8847 17.938 16.5146 17.9767 14.7105C17.9896 13.8085 17.9767 13.0683 17.938 13.0683C17.8994 13.0683 17.5772 13.2071 17.2163 13.369L16.5591 13.6581L16.6106 15.069C16.6493 16.2486 16.7008 16.5609 16.9457 16.9656C17.5901 18.0643 19.1623 18.099 19.7551 17.035C19.9613 16.6649 20 16.3296 20 14.9187C20 12.9989 19.9227 12.7908 18.5824 10.7091L17.8736 9.59891V8.26897C17.8736 7.17033 17.9123 6.9159 18.0669 6.85808C18.1829 6.82339 18.3118 6.83495 18.3633 6.88121C18.4149 6.9159 18.4664 7.63291 18.4922 8.45401C18.518 9.28667 18.5824 9.94585 18.6211 9.92273C18.6726 9.8996 18.9561 9.76082 19.2525 9.59891C19.8711 9.2751 19.884 9.21728 19.6778 7.63291C19.5876 6.88121 19.5232 6.73087 19.1752 6.45331C18.7113 6.08324 17.9251 6.02542 17.3452 6.30297Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
