// MUI
import { Button } from '@mui/material';
// Components
import CommonModal from '../CommonModal';

const ModalConfirmClose = ({
  open,
  onClose,
  onCancel,
  title = 'Work Not Saved!',
  description = 'Exiting now will discard any changes made to your template. Are you sure you want to proceed?',
  confirmButtonText = 'Discard & Exit',
  cancelButtonText = 'Continue Editing',
}) => {
  //Method's
  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CommonModal
      open={open}
      handleClose={() => {}}
      sx={{
        minHeight: '200px',
      }}
    >
      <div className="h-full flex flex-col justify-center items-center gap-6 font-figtree text-raisin-black dark:text-crystal-bell">
        <div className="uppercase text-lg font-bold mb-0">{title}</div>
        <p>{description}</p>
        <div className="flex w-full gap-4 left-0 items-center justify-center">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
            fullWidth
          >
            <span className="font-bold">{confirmButtonText}</span>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleCancel}
            fullWidth
          >
            <span className="font-bold">{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalConfirmClose;
