import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ArrowLeftIcon, InboxArrowDownIcon } from '@heroicons/react/24/outline';

function EmailSent() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 8000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>{`Check your inbox - Straico`}</title>
      </Helmet>
      <div className="h-screen container m-auto lg:px-4 py-4 flex flex-col md:items-center md:justify-center px-4">
        <div className="text-center mb-12 mt-4">
          <a href="https://straico.com" target="_blank" rel="noreferrer">
            <img
              className="md:h-10 md:w-auto"
              src="/pr-logo-tran.png"
              alt="Logo"
            />
          </a>
        </div>
        <div className="min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all hover:shadow-2xl focus:shadow-2xl w-1/2">
          <div className="align-bottom transform sm:align-middle transition flex divide-x divide-gray-300">
            <div className="px-4 py-4 md:px-12 md:py-12">
              <NavLink to="/login">
                <ArrowLeftIcon
                  className="h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
              </NavLink>

              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-300`}
              >
                <InboxArrowDownIcon
                  className={`h-8 w-8 text-green-700 orange-500`}
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center ">
                <div className="text-3xl font-medium text-gray-900">
                  Verify your email!
                </div>
                <p className="text-lg text-gray-500">
                  Kindly check your inbox and click on the verification link to
                  finalize registration.
                </p>
              </div>
              {errorMessage ? (
                <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                  {errorMessage}
                </div>
              ) : null}
              {successMessage ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  {successMessage}
                </div>
              ) : null}
            </div>
          </div>
          <a
            href={`https://straico.com/`}
            className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer"
          >
            Back to landing page
          </a>
        </div>
      </div>
    </>
  );
}

export default EmailSent;
