import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Hooks
import useModal from '../../Hooks/useModal';
// Material UI
import { useMediaQuery, useTheme } from '@mui/material';
import { controller, signal } from '../../Features/Core/utils/apiControllers';
// import { useMemo } from 'react';

const ChatContext = React.createContext();

const ChatProvider = inject('store')(
  observer(({ store, children }) => {
    // Prompt logic

    // refs
    const promptListRef = useRef(null);
    const textareaRef = useRef(null);
    const latestRequestId = useRef(null);
    // states
    const [tools, setTools] = useState([]);
    const [templatesFolders, setTemplatesFolders] = useState([]);
    const [sidebarTools, setSidebarTools] = useState([]);
    const [loadingSideTools, setLoadingSideTools] = useState(false);
    const [activePromptIndex, setActivePromptIndex] = useState(0);
    const [selectedTool, setSelectedTool] = useState(null);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [optionsPrompt, setOptionsPrompt] = useState(null);
    const [promptValue, setPromptValue] = useState('');
    // lastest prompt and tool
    const [lastestPrompt, setLastestPrompt] = useState(null);
    const [lastestTool, setLastestTool] = useState(null);
    const [promptListByTool, setPromptListByTool] = useState([]);
    // values
    const [toolCoins, setToolCoins] = useState(
      store?.profile.tier_plan?.coins_chat
    );

    // const [onSendFunction, setOnSendFunction] = useState();

    const [onSendRef, setOnSendRef] = useState(null);

    // The context value now also includes a method to update the onSend function
    // const sendMessage = useMemo(() => ({ onSend, setOnSend }), [onSend]);

    //SERVICES CALLBACKS
    // get tools and filter
    const getToolsAndFilter = useCallback(async (text, requestId) => {
      controller.abort();
      try {
        const response = await store.api.get('/tool', {
          params: { keyword: text.replace('/', '') },
          signal: signal,
        });
        if (requestId === undefined || requestId === latestRequestId.current) {
          // Filter tools by permissions
          const filterData = response.data.tools
            ?.filter((tool) =>
              tool.permissions.some((r) =>
                store.profile.permissions.includes(r)
              )
            )
            ?.filter((tool) => tool.category !== 'Media')
            .filter((tool) =>
              tool.title?.toLowerCase().includes(text.replace('/', ''))
            )
            .sort((a, b) => a.title.localeCompare(b.title));
          // Folders Data
          const foldersData = response?.data?.folders ?? [];

          // Set tools
          setTools(filterData ?? []);

          // Filter folders by tools
          if (!['', null, undefined].includes(text)) {
            const idsParentFolders = filterData.map(
              (tool) => tool.parent_folder
            );
            const filterFolders = foldersData.filter((folder) =>
              idsParentFolders.includes(folder._id)
            );
            setTemplatesFolders(filterFolders);
          } else {
            setTemplatesFolders(foldersData);
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // The request was aborted, handle it if needed
          console.log('Request aborted');
        } else {
          // Handle other errors
          console.error('API error:', error);
        }
      }
    }, []);
    //
    const getToolsAndFilterSidebar = useCallback(async (text) => {
      setLoadingSideTools(true);
      try {
        const response = await store.api.get('/tool', {
          params: { keyword: text.replace('/', '') },
        });
        // Filter tools by permissions
        const filterData = response.data.tools
          ?.filter((tool) => tool.category !== 'Media')
          ?.filter((tool) =>
            tool.permissions.some((r) => store.profile.permissions.includes(r))
          );
        // Folders Data
        const foldersData = response?.data?.folders ?? [];

        // Set tools
        setSidebarTools(filterData ?? []);

        // Filter folders by tools
        if (!['', null, undefined].includes(text)) {
          const idsParentFolders = filterData.map((tool) => tool.parent_folder);
          const filterFolders = foldersData.filter((folder) =>
            idsParentFolders.includes(folder._id)
          );
          setTemplatesFolders(filterFolders);
        } else {
          setTemplatesFolders(foldersData);
        }

        setLoadingSideTools(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoadingSideTools(false);
      }
    }, []);
    // get prompts by tool
    const getPromptsByTool = async (toolId) => {
      const response = await store.api.get('/prompt', {
        params: { toolId },
      });
      setPromptListByTool(response?.data?.prompts ?? []);
      return response?.data?.prompts ?? [];
    };
    // create tool
    const [loadingCreateTool, setLoadingCreateTool] = useState(false);
    const createTool = async (toolData) => {
      setLoadingCreateTool(true);
      const response = await store.api.post('/tool/create', toolData);
      if (response.status === 200) {
        await getToolsAndFilterSidebar('');
      }
      setLoadingCreateTool(false);
      return response?.data;
    };
    // edit tool
    const [loadingEditTool, setLoadingEditTool] = useState(false);
    const editTool = async (toolData, toolId) => {
      setLoadingEditTool(true);
      const regex = /"{2,}{{(.*?[^"]?)}}"{2,}/g;
      let sendData = {
        ...toolData,
        value: toolData?.value?.replace(regex, '{{$1}}'),
      };
      if (!toolData?.value) delete sendData.value;

      const response = await store.api.put(`/tool/update/${toolId}`, sendData);
      if (response.status === 200) {
        await getToolsAndFilterSidebar('');
      }
      setLoadingEditTool(false);
      return response?.data;
    };

    // Modals and sidebars
    const {
      isOpen: showPromptSide,
      toggleModal: toggleShowPromptSide,
      closeModal: closeShowPrompSide,
      openModal: openShowPrompSide,
    } = useModal(true);
    const {
      isOpen: showPromptModal,
      toggleModal: toggleShowPromptModal,
      openModal: openShowPromptModal,
    } = useModal();
    const [showMobileChats, setShowMobileChats] = useState(false);
    const [showMobilePrompts, setShowMobilePrompts] = useState(false);
    const [showPromptList, setShowPromptList] = useState(false);
    const [showConversationsSide, setShowConversationsSide] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleShowMobilePrompts = () => {
      if (isMobile) {
        setShowMobilePrompts(true);
      } else {
        openShowPrompSide();
      }
    };

    // analytics values
    const setValuesAfterInsert = (promptValue, toolValue) => {
      setLastestPrompt(promptValue);
      setLastestTool(toolValue);
    };

    const cleanLastestFields = () => {
      setLastestPrompt(null);
      setLastestTool(null);
    };

    const value = {
      setSidebarTools,
      promptListByTool,
      setPromptListByTool,
      openShowPrompSide,
      handleShowMobilePrompts,
      cleanLastestFields,
      lastestPrompt,
      setValuesAfterInsert,
      lastestTool,
      promptListRef,
      textareaRef,
      tools,
      setTools,
      getToolsAndFilter,
      getPromptsByTool,
      showPromptSide,
      toggleShowPromptSide,
      closeShowPrompSide,
      showMobileChats,
      setShowMobileChats,
      showMobilePrompts,
      setShowMobilePrompts,
      showPromptModal,
      toggleShowPromptModal,
      openShowPromptModal,
      showPromptList,
      setShowPromptList,
      selectedTool,
      setSelectedTool,
      selectedPrompt,
      setSelectedPrompt,
      activePromptIndex,
      setActivePromptIndex,
      optionsPrompt,
      setOptionsPrompt,
      createTool,
      loadingCreateTool,
      editTool,
      loadingEditTool,
      sidebarTools,
      getToolsAndFilterSidebar,
      latestRequestId,
      loadingSideTools,
      promptValue,
      setPromptValue,
      toolCoins,
      showConversationsSide,
      setShowConversationsSide,
      templatesFolders,
      setTemplatesFolders,
      onSendRef,
      setOnSendRef,
    };

    return (
      <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
    );
  })
);

function useChat() {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}

export { ChatProvider, useChat };
