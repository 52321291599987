import { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Navigate } from 'react-router-dom';

import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon,
} from '@heroicons/react/24/outline';

import toast from 'react-hot-toast';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { TranslateContext } from '../../Context/translate-context';

import { inject, observer } from 'mobx-react';

function Remember({ store }) {
  const [code, setCode] = useState('');
  const [goToLogin, setGoToLogin] = useState(false);
  const [active, setActive] = useState(false);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [, setErrorMessage] = useState('');
  const [, setSuccessMessage] = useState('');
  const { email } = useContext(TranslateContext);

  const checkCode = async () => {
    setCode(window.location.pathname.split('/').pop());
    try {
      await store.api
        .post('/forgot/check', {
          code: code,
        })
        .then(() => setActive(true));
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
        // this.errorMessage = err?.response?.data?.message
      }
    }
  };

  useEffect(() => {
    checkCode();
  }, [code]);

  const onChangeAny = (val, attr) => {
    if (attr === 'passwordOne') {
      setPasswordOne(val);
    } else if (attr === 'passwordTwo') {
      setPasswordTwo(val);
    }
    setErrorMessage('');
  };

  const onSave = async (e) => {
    e.preventDefault();

    if (passwordOne !== passwordTwo) {
      // this.errorMessage = "Both passwords must be the same"
      toast.error('Both passwords must be the same');
      return;
    }

    try {
      setSuccessMessage('');
      setErrorMessage('');
      await store.api
        .post('/forgot/save', {
          code: code,
          password: passwordOne,
        })
        .then(({ data }) => {
          // this.successMessage = data.message
          toast.success(data.message);
          setTimeout(() => {
            // this.successMessage = ""
            setGoToLogin(true);
          }, 4000);
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
        // this.errorMessage = err?.response?.data?.message
        // setTimeout(() => { this.errorMessage = "" }, 4000);
      }
    }
  };

  return (
    <>
      {goToLogin ? <Navigate to="/" /> : null}

      <Helmet>
        <title>{`Remember - Straico`}</title>
      </Helmet>

      <div className="h-screen flex flex-col">
        <header className="h-auto bg-nue-blue font-medium text-2xl py-3">
          <NavLink to="/login">
            <img
              className="h-7 pl-4 -mt-1"
              src="/logo-snow-100.svg"
              alt="Logo"
            />
          </NavLink>
        </header>

        <div className="lg:grid lg:grid-cols-3 flex-1 bg-seasalt h-full overflow-auto">
          {/* Image container */}
          <div className="login-banner-wrapper bg-ghost-white h-full lg:col-span-2">
            <LazyLoadImage
              className="h-[calc(100vh_-_48px)] w-full object-cover"
              src="/straico-wall-16.png"
              placeholderSrc="/straico-wall-16-blur.png"
              effect="blur"
            />
          </div>
          {/* Forms container */}
          <div className="flex items-center justify-center mt-8 lg:mt-0 bg-seasalt font-figtree text-raisin-black overflow-auto">
            {active ? (
              <form onSubmit={onSave}>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full ${
                    passwordOne && passwordTwo ? 'bg-nue-blue' : 'bg-lavender'
                  }`}
                >
                  <ArchiveBoxArrowDownIcon
                    className={`h-8 w-8 ${
                      passwordOne && passwordTwo
                        ? 'text-white'
                        : 'text-violet-blue'
                    }`}
                    aria-hidden="true"
                  />
                </div>

                <div className="text-center mt-2">
                  <div className="text-3xl font-bold mb-1 text-center uppercase">
                    Set a new password
                  </div>
                  <p className="text-base text-cool-gray">
                    Please fill this data
                  </p>
                  <div className="flex flex-col flex-1">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      New password
                    </label>
                    <input
                      value={passwordOne}
                      onChange={(e) =>
                        onChangeAny(e.target.value, 'passwordOne')
                      }
                      type="password"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="******"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      Write again new password
                    </label>
                    <input
                      value={passwordTwo}
                      onChange={(e) =>
                        onChangeAny(e.target.value, 'passwordTwo')
                      }
                      type="password"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="******"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="flex flex-col mt-6">
                    <button
                      type="submit"
                      className="bg-nue-blue hover:bg-lavender text-white font-bold py-2 px-4 rounded-md uppercase"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                <div>
                  <div
                    className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-lavender mt-8 lg:mt-0`}
                  >
                    <ArchiveBoxXMarkIcon
                      className={`h-8 w-8 text-violet-blue`}
                      aria-hidden="true"
                    />
                  </div>

                  <div className="mt-3 text-center ">
                    <div className="text-3xl font-bold mb-1 text-center uppercase">
                      Wrong information
                    </div>
                    <p className="text-base text-cool-gray">
                      Misspell code or it expired
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default inject('store')(observer(Remember));
