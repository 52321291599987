import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';

import { useController } from 'react-hook-form';
import toast from 'react-hot-toast';

const SelectMultipleControlled = ({
  name,
  options,
  control,
  label,
  formControlProps = {},
  selectProps = {},
  variant = 'outlined',
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl {...formControlProps} error={!!error}>
      <Select
        id="demo-multiple-checkbox"
        variant={variant}
        // label={label}
        size={selectProps.size ?? 'small'}
        name={field.name}
        value={field.value}
        onChange={(event) => {
          // Check if the user is trying to select more than 4 options
          if (event.target.value.length > 4) {
            // Log a message and prevent selection
            toast('You only can select up to 4 LLMs simultaneously', {
              icon: '⚠️',
            });
            // Optionally, you can prevent the addition here by not calling
            // field.onChange, depending on whether you want to strictly enforce
            // this limit or just advise the user.
          } else {
            // If 4 or fewer options are selected, proceed with the update
            if (event.target.value.length < 1) {
              toast('You must select at least 1 LLMs', {
                icon: '⚠️',
              });
            } else {
              field.onChange(event);
            }
          }
        }}
        onBlur={field.onBlur}
        inputRef={field.ref}
        {...selectProps}
        multiple
        displayEmpty
        inputProps={{
          ...selectProps.inputProps,
          'aria-label': 'Without label',
        }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              fontFamily: 'Figtree',
            }}
          >
            {selected.map((value) => {
              // Find the option object where the option's id matches the selected value
              const option = options.find((opt) => opt.id === value);
              if (option) {
                return (
                  <Chip
                    key={value}
                    label={option ? option.title : 'Select a LLM'}
                    className="font-figtree"
                  />
                );
              }
              // Use the option's title for the Chip's label. If the option or title is not found, display 'Unknown'
            })}
          </Box>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8, // Adjust the maximum height if needed
              width: 250,
            },
          },
        }}
      >
        {options.map(({ id, title }) => (
          <MenuItem key={`${id}-${title}`} value={id} className="font-figtree">
            <Checkbox checked={field.value.includes(id)} />
            <ListItemText primary={title} sx={{ fontFamily: 'Figtree' }} />
          </MenuItem>
        ))}
      </Select>
      {error && error.message && (
        <FormHelperText sx={{ marginLeft: 0 }} id={`${name}-helper-text`}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMultipleControlled;
