import {
  TagIcon,
  UserCircleIcon,
  RocketLaunchIcon,
  BoltIcon,
  PaperClipIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import { useChat } from 'Context/Chat/Chat.context';
import { useEffect } from 'react';
import Emoji from 'react-emojis';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import Tooltip from '@mui/material/Tooltip';

const TemplateFav = ({ tool, isSmallMobile }) => {
  // TODO cuando hace onClick tiene que abir el tool
  const navigate = useNavigate();

  const { theme: themeValue } = useThemeStore();

  const {
    setSelectedTool,
    toggleShowPromptModal,
    setShowPromptList,
    sidebarTools,
    showPromptSide: show,
    toggleShowPromptSide: toggleSide,
    closeShowPrompSide: closeSide,
    loadingSideTools,
    setShowMobilePrompts,
  } = useChat();

  const handleSelectPrompt = () => {
    const selectedToolLocal = tool;
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
    setShowPromptList(false);
  };

  const onClickTemplate = (e) => {
    setShowMobilePrompts(false);
    handleSelectPrompt();
    // url params
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const veriftyEmoji = (tool) => {
    let iconTag = 'high-voltage';
    if (tool.Icon) {
      if (tool.Icon !== ' ') {
        iconTag = tool.Icon;
      }
    }
    return <Emoji emoji={iconTag} />;
  };

  const imageGenerationTools = [
    '65805947d92e370a3d5625c6',
    '66c786d7e26442bc593e575d',
  ];

  const setImageToolNames = (tool) => {
    if (tool?.id == '65805947d92e370a3d5625c6') {
      return 'OpenAi: Dall-E 3';
    } else if (tool?.id == '66c786d7e26442bc593e575d') {
      return 'Fal-Ai: FLUX.1 [pro]';
    }
    return '';
  };

  return (
    <div
      className={`${
        themeValue == 'dark' ? 'bg-black' : 'bg-white'
      } font-figtree rounded-[14px] w-[260px] p-3 my-3 cursor-pointer flex flex-col gap-3 shadow-[4px_4px_7.6px_0px_rgba(0,0,0,0.10)]`}
      onClick={onClickTemplate}
    >
      <div className="flex items-center text-cool-gray dark:text-sonic-silver">
        <TagIcon className={'h-4 w-4 mr-1'} />
        <h2 className="text-xs uppercase font-normal">{tool.category}</h2>
        <div className="flex-1"></div>
        <div className="flex space-x-1 items-center">
          {/* <UserCircleIcon className={'h-4 w-6 '} /> */}
          {tool.userInfo?.avatar.svg ? (
            <div className="avatar">
              <div className="w-4 h-4 rounded">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tool.userInfo?.avatar.svg,
                  }}
                />
              </div>
            </div>
          ) : (
            <BoltIcon className="w-4 h-4 transition rounded-full text-white bg-nue-blue" />
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="text-xl">{veriftyEmoji(tool)}</div>
        <h2 className="text-sm font-semibold leading-[14px] text-left text-raisin-black dark:text-crystal-bell">
          {tool.title}
        </h2>
      </div>
      <div className="text-agate-violet text-xs leading-[14px] line-clamp-2">
        {tool.desc}
      </div>
      <div className="flex justify-between">
        {tool?.multi_default_models?.length > 1 ? (
          <div className={'flex items-center'}>
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: tool.multi_default_models
                      .map((model) => model.name)
                      .join('<br>'),
                  }}
                />
              }
              arrow
            >
              <p className="flex items-center uppercase text-cool-gray group-hover:bg-lavender bg-platinum px-[6px] rounded text-[10px] leading-[14px] w-fit dark:bg-quicksilver dark:text-sonic-silver dark:group-hover:text-ship-grey">
                {tool?.multi_default_models[0]?.name} +{' '}
                {tool?.multi_default_models?.length - 1}
                <SparklesIcon className="w-[10px] h-[10px] ml-1" />
              </p>
            </Tooltip>
          </div>
        ) : tool?.multi_default_models?.length == 1 ? (
          <>
            <p className=" uppercase text-cool-gray group-hover:bg-lavender bg-platinum px-[6px]  rounded text-[10px] leading-[14px] w-fit dark:bg-quicksilver dark:text-sonic-silver dark:group-hover:text-ship-grey">
              {tool?.multi_default_models[0]?.name}
            </p>
          </>
        ) : tool?.default_model ? (
          <>
            <p className="uppercase text-cool-gray group-hover:bg-lavender bg-platinum px-[6px]  rounded text-[10px] leading-[14px] w-fit dark:bg-quicksilver dark:text-sonic-silver dark:group-hover:text-ship-grey">
              {tool?.default_model?.name}
            </p>
          </>
        ) : imageGenerationTools.includes(tool?._id) ? (
          <p className="uppercase text-cool-gray group-hover:bg-lavender bg-platinum px-[6px] rounded-md text-[10px] leading-[14px] w-fit dark:bg-quicksilver dark:text-sonic-silver dark:group-hover:text-ship-grey">
            {setImageToolNames(tool)}
          </p>
        ) : null}
        {/* {!isSmallMobile && (
          <p className="text-cool-gray dark:text-sonic-silver text-sm mt-1 overflow-hidden h-20">
            {tool.desc}
          </p>
        )} */}

        {tool.files.length > 0 && (
          <div className="flex items-center gap-[2px]">
            <PaperClipIcon
              className={'w-4 h-4 text-raisin-black dark:text-crystal-bell'}
            />
            <span className="text-raisin-black dark:text-crystal-bell text-[9px] leading-3 uppercase">
              {tool.files.length}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateFav;
