function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill="#CC9B7A" />
      <path
        d="M15.9824 5H13.2262L18.2439 18.5L21 18.5003L15.9824 5ZM8.0176 5L3 18.5003H5.81252L6.82504 15.6653L12.0901 15.6651L13.1138 18.5003H15.9263L10.8976 5H8.0176ZM7.74744 13.1564L9.45764 8.40896L11.1788 13.1564H7.74744Z"
        fill="#1F1F1E"
      />
    </svg>
  );
}

export default SvgComponent;
