// Mobx
import SelectMultipleControlled from 'Components/Forms/SelectMultipleControlled';
import { observer, inject } from 'mobx-react';
// RHF
import { useFormContext } from 'react-hook-form';
// Components
import useThemeStore from 'Theme/store';

const SectionGeneral = ({ models }) => {
  // Form
  const { register, control } = useFormContext();

  const { theme } = useThemeStore();

  /* TODO make it multi model */

  return (
    <div className="flex flex-col font-figtree">
      <p
        className={`${
          theme == 'dark' ? 'dark:text-crystal-bell' : 'text-raisin-black'
        } text-sm font-bold`}
      >
        Step 1: General adjustments
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label className="uppercase text-xs font-medium">
            Template name *
          </label>
          <input
            name="name"
            className={`w-full border ${
              theme == 'dark'
                ? 'dark:bg-lead dark:text-crystal-bell'
                : 'bg-seasalt text-raisin-black'
            } border-platinum border-solid text-sm p-2 rounded-md `}
            placeholder="Name of your prompt"
            {...register('name')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-xs font-medium">Description *</label>
          <textarea
            className={`w-full border ${
              theme == 'dark'
                ? 'dark:bg-lead dark:text-crystal-bell'
                : 'bg-seasalt text-raisin-black'
            } border-platinum border-solid p-2 text-sm rounded-md `}
            style={{ resize: 'none' }}
            placeholder="A description for your prompt"
            rows={3}
            {...register('description')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-xs font-medium">
            Default models *
          </label>
          <p className="text-xs py-2 text-pretty">
            Need assistance selecting LLMs for your template?{' '}
            <a
              className="text-violet-blue font-bold"
              target="_blank"
              href="https://straico.com/multimodel"
              rel="noreferrer"
            >
              Click here{' '}
            </a>{' '}
            to view related documentation.
          </p>
          <SelectMultipleControlled
            name="multi_default_models"
            options={models}
            control={control}
            label="Default model"
            formControlProps={{
              className: `w-full ${
                theme === 'dark'
                  ? 'dark:bg-lead dark:text-crystal-bell'
                  : 'bg-seasalt text-raisin-black'
              } border border-platinum text-sm border-solid p-2 rounded-md`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(SectionGeneral));
