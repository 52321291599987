import React, { useMemo } from 'react';
// Icons
import {
  FolderIcon,
  FolderOpenIcon,
  BookOpenIcon,
  Bars2Icon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import {
  TrashIcon as TrashIconSolid,
  PencilIcon as PencilIconSolid,
  EllipsisHorizontalIcon,
  ArrowUpOnSquareIcon,
  InformationCircleIcon,
  DocumentDuplicateIcon,
  BoltIcon,
  UserIcon,
  PhotoIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import Tooltip from '@mui/material/Tooltip';

// MUI
import { useMediaQuery, Menu, MenuItem } from '@mui/material';
// Store
import useChatStore from 'Components/Chat/store';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
import { useCreateTemplateFolder } from 'Components/Chat/CreateTemplateFolder/store';
// Components
import { cn } from 'utils/styles';
import useThemeStore from 'Theme/store';

const CustomNode = ({ isOpen, ...props }) => {
  const { droppable, text, id, data } = props.node;
  const {
    onClickTemplate,
    onEditTemplate,
    onDeleteTemplate,
    // onShareTemplate,
    onDuplicateTemplate,
  } = props;
  // Zustand store
  const [selectedChat] = useChatStore((state) => [state.selectedChat]);

  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const onClick = (e) => {
    handleToggle(e);
    if (!droppable) {
      onClickTemplate(data);
    }
  };

  const { setDeleteFolderModal, setSelectedFolder } =
    useTreeDNDTemplatesStore();
  const { setOpen: setEditModal } = useCreateTemplateFolder();

  // onEdit folder
  const onEditFolder = (e) => {
    e.stopPropagation();
    setEditModal(true);
    setSelectedFolder(data);
  };
  // onDelete folder
  const onDeleteFolder = (e) => {
    e.stopPropagation();
    setDeleteFolderModal(true);
    setSelectedFolder(data);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const notStraicoFolders = ['My templates', 'Shared With Me'].map((item) =>
    item?.toUpperCase()
  );
  const newFoldersTemplates = [
    // 'Discretion advised',
    // 'Product research',
    // 'Hiring',
    // 'Role playing',
    // 'Research',
    // 'Business',
    // 'For fun',
    // 'Career Development',
    // 'Image Prompting',
    // 'Image Generation',
  ].map((item) => item?.toUpperCase());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const showFolderActions = useMemo(() => {
    if (
      text?.toLowerCase() === 'my templates' ||
      text?.toLowerCase() === 'shared with me'
    ) {
      return false;
    }
    if (data?.type === 'folder' && Boolean(data?.creator)) {
      return true;
    }
    return false;
  }, []);

  const { theme } = useThemeStore();

  return (
    <>
      <div
        className="group tree-node flex items-center max-h-[72px] box-border w-full cursor-pointer font-figtree"
        style={{ paddingInlineStart: indent }}
      >
        <div
          onClick={onClick}
          className="flex items-center justify-between text-cool-gray py-[5px] px-[6px] w-full"
        >
          <div
            className={cn(
              'flex items-center gap-[4px] py-[2px] px-[6px] w-full group-hover:bg-lavender dark:group-hover:bg-night-black group-hover:rounded',
              {
                ' bg-lavender rounded':
                  selectedChat?.chat?._id === id && !droppable,
              }
            )}
          >
            <div
              className="block md:hidden cursor-grabbing drag-handle col-span-1"
              ref={isMobile ? props.handleRef : null}
            >
              <Bars2Icon className="w-6 h-6 cursor-grabbing" />
            </div>
            <div className="self-start pt-1">
              {!droppable ? (
                <BookOpenIcon
                  className={cn('w-5 h-5', {
                    'text-violet-blue':
                      selectedChat?.chat?._id === id && !droppable,
                  })}
                />
              ) : (
                <>
                  {isOpen ? (
                    <FolderOpenIcon className="text-violet-blue w-6 h-6" />
                  ) : (
                    <FolderIcon className="w-6 h-6" />
                  )}
                </>
              )}
            </div>
            <div className="flex items-center w-[80%]">
              <div className="flex flex-col gap-2 w-fit">
                <p
                  id={
                    text === 'Image Generation'
                      ? 'straico-userguiding-category-image'
                      : text === 'Writing'
                      ? 'straico-userguiding-category-writing'
                      : text === 'My Templates'
                      ? 'straico-userguiding-category-mytemplates'
                      : data?.id_userguiding
                      ? data.id_userguiding
                      : ''
                  }
                  className={`text-cool-gray dark:text-ghost-white text-sm ${
                    !droppable
                      ? 'line-height-[1.2] text-pretty' +
                        (selectedChat?.chat?._id === id
                          ? ' font-bold text-cool-gray'
                          : '')
                      : 'uppercase font-[500] truncate' +
                        (isOpen ? ' text-raisin-black font-bold' : '')
                  }`}
                >
                  {text === 'My Tools'
                    ? 'My Templates'
                    : text === 'Share With Me'
                    ? 'Shared with me'
                    : text}{' '}
                </p>
                {/* TODO in the side list of templates */}
                {data?.multi_default_models?.length > 1 ? (
                  <div className={'flex items-center'}>
                    <Tooltip
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.multi_default_models
                              .map((model) => model.name)
                              .join('<br>'),
                          }}
                        />
                      }
                      arrow
                    >
                      <p className="flex items-center font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                        {data?.multi_default_models[0]?.name} +{' '}
                        {data?.multi_default_models?.length - 1}
                        <SparklesIcon className="w-[10px] h-[10px] mr-1 ml-1" />
                      </p>
                    </Tooltip>
                  </div>
                ) : data?.multi_default_models?.length == 1 ? (
                  <>
                    <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                      {data?.multi_default_models[0]?.name}
                    </p>
                  </>
                ) : data?.default_model ? (
                  <>
                    <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                      {data?.default_model?.name}
                    </p>
                  </>
                ) : null}
              </div>
              {droppable &&
                ['image generation', 'image prompting'].includes(
                  data?.name?.toLowerCase()
                ) && (
                  <div className="ml-2">
                    <PhotoIcon className="w-4 h-4 text-violet-blue" />
                  </div>
                )}
              {droppable && data?.creator && !data?.public && (
                <div className="ml-2">
                  <UserIcon className="w-5 h-5  text-violet-blue" />
                </div>
              )}
              {droppable && data?.public && (
                <div className="ml-2">
                  <BoltIcon className="w-4 h-4 text-violet-blue" />
                </div>
              )}
              {droppable &&
                newFoldersTemplates.includes(text?.toUpperCase()) && (
                  <span className="text-white bg-nue-blue leading-4 uppercase rounded-sm ml-1 text-[10px] font-bold px-1">
                    New!
                  </span>
                )}
            </div>
            {/* Actions component */}
            {droppable ? (
              <>
                {showFolderActions && (
                  <div className="col-span-3  lg:hidden flex-[0.2] text-raisin-black lg:group-hover:flex flex gap-[4px] items-center transition-opacity duration-300 ease-in-out">
                    <div onClick={onEditFolder}>
                      <PencilIcon
                        className={`w-4 h-4 ${
                          theme == 'dark'
                            ? 'dark:text-crystal-bell'
                            : 'text-cool-gray'
                        }`}
                      />
                    </div>
                    <div onClick={onDeleteFolder}>
                      <TrashIcon
                        className={`w-4 h-4 ${
                          theme == 'dark'
                            ? 'dark:text-crystal-bell'
                            : 'text-cool-gray'
                        }`}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex items-center gap-[3px] transition-opacity duration-300 ease-in-out">
                  <div onClick={handleClick}>
                    <EllipsisHorizontalIcon className="w-4 h-4" />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      '& .MuiMenuItem-root': {
                        fontSize: '12px !important',
                        fontFamily: 'Barlow !important',
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickTemplate(data);
                        handleClose(e);
                      }}
                    >
                      <InformationCircleIcon className="w-4 h-4 mr-1" />
                      See info
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickTemplate(data);
                        handleClose(e);
                      }}
                    >
                      <ArrowUpOnSquareIcon className="w-4 h-4 mr-1" />
                      Share
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onDuplicateTemplate(data);
                      }}
                    >
                      <DocumentDuplicateIcon className="w-4 h-4 mr-1" />
                      Duplicate
                    </MenuItem>
                    {!data?.public && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditTemplate(data);
                          handleClose(e);
                        }}
                      >
                        <PencilIconSolid className="w-4 h-4 mr-1" /> Edit
                      </MenuItem>
                    )}
                    {!data?.public && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteTemplate(data);
                          handleClose(e);
                        }}
                      >
                        <TrashIconSolid className="w-4 h-4 mr-1" /> Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomNode;
