import { useState } from 'react';
import {
  QuestionMarkCircleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import StraicoIcon from '../../../Theme/icons/straico';
import StraicoSymbolIcon from '../../../Theme/icons/straicoSymbol';

import HelpModal from '../../../Components/HelpModal';
import DiscordModal from 'Components/DiscordModal';
import usePublicLayoutStore from '../State';
import ToggleTheme from 'Components/Common/ToggleTheme';
import useThemeStore from 'Theme/store';

const SidebarComponent = ({ ...props }) => {
  const [openDialogHelp, setOpenDialogHelp] = useState(false);
  const [openDialogDiscord, setOpenDialogDiscord] = useState(false);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialogHelp(false);
      setOpenDialogDiscord(false);
    }
  };

  const { openRegisterModal } = usePublicLayoutStore();
  const { theme } = useThemeStore();

  return (
    <>
      <div className="bg-seasalt dark:bg-lead sticky top-0 z-40 font-figtree">
        <div className="flex mx-auto px-1 md:px-1 select-none p-1">
          <div className="flex-1 flex md:justify-center">
            <div className="items-center hidden md:block">
              <StraicoIcon className="fill-nue-blue" />
            </div>
            <div className="flex items-center md:hidden">
              <StraicoSymbolIcon className="fill-nue-blue" />
            </div>
          </div>

          <div className="flex mr-2 justify-end absolute right-0 h-10">
            <div
              onClick={openRegisterModal}
              className={`flex px-2 cursor-pointer hover:bg-platinum rounded-md transition items-center dark:hover:bg-night-black`}
            >
              <UserCircleIcon className="w-7 h-7 transition text-cool-gray dark:text-palladium" />
            </div>
          </div>
        </div>
      </div>

      {props.children}
    </>
  );
};

export default SidebarComponent;
