import React, { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// React Router Dom
import {
  Navigate,
  useOutlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';
// Components
import Header from 'Features/App/Components/Header';
import PowerPanel from 'Features/App/Components/PowerPanel';
import ComingSoonModal from 'Components/Common/ComingSoonModal/ComingSoonModal';
import { ChatProvider } from 'Context/Chat/Chat.context';
import useChatStore, {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
} from 'Components/Chat/store';

const AuthLayout = ({ store }) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const currentEvent = useChatStore((state) => state.currentEvent);

  useEffect(() => {
    localStorage.setItem('totalRewards', null);
  }, []);

  useEffect(() => {
    redirectEvent();
  }, [currentEvent]);

  const redirectEvent = () => {
    switch (currentEvent.name) {
      case EVENT_NEW_CHAT:
      case EVENT_SELECT_CHAT:
      case SCRIPT_FOR_TTS:
        if (location.pathname !== '/chat') navigate('/chat');
        break;
    }
  };

  if (!store.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <ChatProvider>
      <div className="flex-1 flex w-full h-full">
        <PowerPanel />
        <div className="flex-1 h-full w-full flex flex-col relative">
          <Header open={open} onOpen={() => setOpen(true)} />
          {outlet}
          <ComingSoonModal />
        </div>
      </div>
    </ChatProvider>
  );
};
export default inject('store')(observer(AuthLayout));
