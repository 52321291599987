function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill="#7479AA" />
      <path
        d="M17.3825 4.81254C16.4658 6.74449 15.1242 7.99221 13.2644 8.60936C12.1485 8.98501 11.8164 9.34725 11.3249 10.6889C10.9662 11.6683 10.395 12.4867 10.0629 12.4867C9.98322 12.4867 9.9168 12.2854 9.9168 12.0439C9.9168 11.467 9.59799 11.0377 8.93378 10.7291C8.26958 10.4206 7.77807 10.4071 6.9146 10.6755C6.27697 10.8633 5.53306 11.4536 5.70575 11.628C5.7456 11.6817 6.18398 11.7219 6.66221 11.7354C7.12715 11.7354 7.52567 11.7622 7.52567 11.7756C7.52567 11.7756 7.37955 12.0842 7.19357 12.4464C6.71534 13.3721 6.42309 14.7004 6.5028 15.4651C6.59579 16.1627 6.9146 16.914 7.15372 16.914C7.37955 16.914 8.26958 15.9212 8.9205 14.915C9.70426 13.721 9.94337 13.5868 10.8467 13.8954C11.6969 14.1771 12.733 14.11 13.5699 13.6941C13.8887 13.5331 14.5928 12.9562 15.1374 12.4062C15.6821 11.8695 16.2135 11.4134 16.3197 11.4134C16.6385 11.4134 17.6083 10.4876 17.9138 9.88391C18.1662 9.42775 18.2193 9.05209 18.2592 7.71047C18.3123 6.16759 18.1264 4.22223 17.9138 4.00757C17.8474 3.94049 17.6083 4.31614 17.3825 4.81254Z"
        fill="white"
      />
      <path
        d="M5.16136 4.42373C6.18423 5.08113 6.91486 6.69108 7.07427 8.5962C7.1274 9.1731 7.20711 9.72317 7.26024 9.81708C7.32666 9.91099 7.60563 9.97808 7.95102 9.96466C8.8012 9.92441 9.63809 10.394 10.0233 11.1185C10.3687 11.749 10.5148 11.7356 10.7938 11.0648C11.0861 10.3671 11.0329 9.64267 10.6477 9.11943C10.0632 8.30104 9.90377 7.91197 9.77093 7.08016C9.61152 6.08735 9.25285 5.4702 8.60193 5.04088C8.07057 4.67864 5.75914 4.03466 5.02852 4.03466H4.55029L5.16136 4.42373Z"
        fill="white"
      />
      <path
        d="M4.07186 13.2785C4.00544 13.4261 3.97887 13.8152 4.01872 14.1506C4.09843 14.6738 4.15157 14.7677 4.48367 14.8348C4.93533 14.9421 5.06817 15.1031 5.18772 15.68C5.33385 16.485 5.66595 17.0217 6.09104 17.1693C6.71539 17.3973 6.74196 17.3705 6.39657 16.6058C6.22388 16.2301 6.0379 15.6666 5.98477 15.3715C5.93163 15.0763 5.77222 14.7543 5.63938 14.647C5.49326 14.5531 5.17444 14.1372 4.92204 13.7481C4.41725 12.9565 4.28441 12.8895 4.07186 13.2785Z"
        fill="white"
      />
      <path
        d="M14.5529 13.9626L14.2341 14.2175L14.8053 14.2309C15.6024 14.2309 16.1205 14.4993 16.9839 15.3445C17.9404 16.2836 18.4053 16.4044 19.3086 15.9616C19.747 15.7336 19.9596 15.5457 19.9861 15.3311C20.0127 15.1701 19.9994 15.0359 19.9596 15.0359C19.933 15.0359 19.6009 15.2238 19.2289 15.4518C18.4053 15.9751 18.0201 15.908 17.5684 15.1969C16.7183 13.8419 15.35 13.2918 14.5529 13.9626Z"
        fill="white"
      />
      <path
        d="M12.9723 14.2846C11.883 14.5261 11.6572 14.7005 11.2454 15.6262C11.0461 16.0824 10.6874 16.6861 10.4616 16.941C10.2358 17.2093 10.0498 17.4508 10.0498 17.5045C10.0498 17.6252 10.8601 17.5984 11.3782 17.4508C12.1354 17.2362 12.7598 16.6056 13.3575 15.4518C13.8889 14.4053 13.9952 14.0833 13.8092 14.1102C13.7428 14.1236 13.3708 14.2041 12.9723 14.2846Z"
        fill="white"
      />
      <path
        d="M9.94356 14.3248C9.85057 14.365 9.39891 14.8748 8.94725 15.4651C8.49559 16.0555 8.01737 16.6726 7.87124 16.847C7.49929 17.3166 7.71183 17.491 8.5753 17.4239C9.74429 17.3434 10.4351 16.7129 11.0196 15.2102C11.2321 14.6602 11.2321 14.6333 10.9531 14.4321C10.6609 14.2308 10.2757 14.1906 9.94356 14.3248Z"
        fill="white"
      />
      <path
        d="M13.995 15.2642C13.8091 15.6265 13.6364 16.1363 13.5832 16.4046C13.3973 17.4511 13.9552 17.8133 15.9212 17.9341C17.3559 18.028 17.7544 18.1353 17.7544 18.4573C17.7544 18.7256 17.409 18.8866 16.5589 18.994C15.7485 19.1013 15.4961 19.2489 15.4961 19.6111C15.4961 19.9331 16.2666 20.0807 16.8378 19.8794C17.4223 19.6782 19.2157 18.2829 19.2157 18.0414C19.2157 17.9207 19.0961 17.7999 18.95 17.7597C18.6445 17.6792 18.6179 17.5718 18.8304 17.3572C18.9367 17.2498 18.8172 17.1425 18.4053 16.9413C18.0865 16.7937 17.3825 16.2705 16.8378 15.7875C15.7884 14.8081 15.4563 14.6337 14.7655 14.6337C14.3537 14.6337 14.274 14.7008 13.995 15.2642Z"
        fill="white"
      />
      <path
        d="M12.1885 17.679C11.8963 18.2425 11.3118 18.6853 10.5147 18.9268C9.49187 19.2487 9.19962 19.7988 9.98338 19.9464C10.6077 20.0537 11.6439 19.8927 11.6439 19.6781C11.6439 19.4097 12.1885 18.967 12.9059 18.645C13.9553 18.1889 13.9819 18.1486 13.4771 17.6254C12.9059 17.0216 12.5206 17.0485 12.1885 17.679Z"
        fill="white"
      />
      <path
        d="M6.42305 18.1887C6.0511 18.618 5.70571 18.8461 5.16106 19.0339C4.60313 19.2217 4.39059 19.3559 4.36402 19.5571C4.32417 19.8657 4.6297 19.9999 5.41346 19.9999C5.77213 19.9999 5.98468 19.8925 6.34335 19.5169C6.84814 18.9802 7.39279 18.6717 8.41566 18.3363C9.06658 18.1216 9.54481 17.7996 9.01344 17.9472C8.8806 17.974 8.40238 17.9069 7.964 17.7996C7.51234 17.6789 7.10054 17.5849 7.0474 17.5984C6.98098 17.5984 6.70202 17.8667 6.42305 18.1887Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
