import React from 'react';
// Mobx
import { observer, inject } from 'mobx-react';
// Store
import useTemplateModalStore from '../../store';
import { useChat } from 'Context/Chat/Chat.context';
// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { cn } from 'utils/styles';
import useThemeStore from 'Theme/store';

const DeleteTemplateModal = ({ store }) => {
  const [loading, setLoading] = React.useState(false);
  const {
    // selectedTool, // Template base info
    sidebarTools,
    setSidebarTools,
  } = useChat();
  // Store
  const { deleteTemplateModal, setDeleteTemplateModal, toDelete } =
    useTemplateModalStore();
  // Modal
  const handleClose = () => setDeleteTemplateModal(false);

  const onDelete = async () => {
    setLoading(true);
    try {
      await store.api.post('/tool/delete', {
        tool: toDelete._id,
      });
      store.tools.forEach((x) => {
        if (x._id === toDelete._id) {
          x.in_trash = true;
        }
      });
      setSidebarTools(sidebarTools.filter((x) => x._id !== toDelete._id));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const { theme } = useThemeStore();

  return (
    <CommonModal
      open={deleteTemplateModal}
      handleClose={handleClose}
      sx={{
        minHeight: '200px',
      }}
    >
      <div className="h-full flex flex-col justify-center items-center gap-8 self-center font-figtree">
        <div className="uppercase text-raisin-black dark:text-crystal-bell text-lg font-bold mb-0 mt-2">
          Delete Template
        </div>
        <h1
          className={cn('', {
            'text-raisin-black': theme === 'light',
            'text-crystal-bell': theme === 'dark',
          })}
        >
          Are you sure you want to delete this template?
        </h1>
        <div className="flex w-full gap-4 left-0 items-center justify-center">
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={onDelete}
            fullWidth
            loading={loading}
          >
            Delete
          </LoadingButton>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(DeleteTemplateModal));
