/* eslint-disable no-constant-condition */
import {
  InformationCircleIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { useMediaQuery, useTheme } from '@mui/material';
import useTextAreaFocus from 'Components/Chat/TextAreaHightlight/state';
import TextAreaHightlight from 'Components/Chat/TextAreaHightlight/TextAreaHightlight';
import { highlightWordsTextAreaV2 } from 'Constants/chat';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import usePublicLayoutStore from '../State';
import usePublicPromptStore from '../State/prompts';

const Input = () => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // textarea
  const textareaRef = useRef(null);
  const { focus } = useTextAreaFocus();
  const [inputValue, setInputValue] = useState('');
  const handleChangeInput = (e) => {
    setInputValue(e);
  };

  const { openRegisterModal } = usePublicLayoutStore();
  const { updateVariables, selectedTool, selectedPrompt, submit, setSubmit } =
    usePublicPromptStore();

  const sendToChatPromptValue = async () => {
    let newContent = selectedPrompt[0]?.value;
    setInputValue('');
    await updateVariables?.forEach(([key, value]) => {
      if (value !== '' && value)
        newContent = newContent?.replace('${' + key + '}', `"' ${value} '"`);
    });

    setInputValue(newContent);
    focus();

    setSubmit(false);
  };

  useEffect(() => {
    if (
      updateVariables?.length > 0 &&
      selectedTool &&
      selectedPrompt?.length > 0 &&
      submit
    ) {
      sendToChatPromptValue();
    }
  }, [submit]);

  return (
    <div
      className={` p-4 pb-2 font-figtree bg-seasalt dark:bg-lead ${
        !isSmallMobile ? 'flex items-start' : 'block'
      }`}
    >
      {isSmallMobile && (
        <div className="flex justify-between mb-2">
          <div className="flex gap-2">
            {!false && (
              <Shortcut
                className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group text-raisin-black dark:text-crystal-bell hover:text-violet-blue"
                onClick={openRegisterModal}
              >
                <PlusCircleIcon className="w-6 h-6" />
              </Shortcut>
            )}
          </div>
          <div className="flex gap-2">
            <Shortcut
              className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group text-raisin-black dark:text-crystal-bell hover:text-violet-blue"
              onClick={openRegisterModal}
            >
              <MicrophoneIcon className="w-6 h-6" />
            </Shortcut>
            <Shortcut
              className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-nue-blue/70 relative group flex flex-col items-center group text-cool-gray bg-nue-blue"
              onClick={openRegisterModal}
            >
              <PaperAirplaneIcon className="w-6 h-6 text-white" />
            </Shortcut>
          </div>
        </div>
      )}
      {!false && !isSmallMobile && (
        <Shortcut
          className="h-12 px-3 fle justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group text-raisin-black dark:text-crystal-bell hover:text-violet-blue"
          onClick={openRegisterModal}
        >
          <PlusCircleIcon className="w-6 h-6" />
        </Shortcut>
      )}
      <label
        htmlFor="user-message"
        className="px-2 relative -mt-2 transition text-cool-gray focus-within:text-raisin-black block w-full "
      >
        <TextAreaHightlight
          classNameContainer={`resize-none outline-none text-md rounded min-w-full font-regular  ${
            false ? 'border-red-400' : ''
          } text-base text-raisin-black`}
          textareaRef={textareaRef}
          autoFocus
          minRows={1}
          maxRows={7}
          value={inputValue}
          onChange={handleChangeInput}
          highlightWords={highlightWordsTextAreaV2}
        />
        {true && (
          <div className="flex gap-1 mt-1 text-xs items-center justify-end">
            <div className="text-cool-gray font-light">.</div>
            <a
              href="https://straico.com/multimodel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Shortcut className="rounded-full cursor-pointer relative group flex flex-col items-center group text-raisin-black">
                <InformationCircleIcon className="w-4 h-4" />
              </Shortcut>
            </a>
          </div>
        )}
      </label>
      {!isSmallMobile && (
        <div className="flex gap-2">
          <Shortcut
            className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-lavender relative group flex flex-col items-center group text-raisin-black dark:text-crystal-bell hover:text-violet-blue"
            onClick={openRegisterModal}
          >
            <MicrophoneIcon className="w-6 h-6" />
          </Shortcut>
          <Shortcut
            className="h-12 px-3 justify-center rounded cursor-pointer hover:bg-nue-blue/70 relative group flex flex-col items-center group text-cool-gray bg-nue-blue"
            onClick={openRegisterModal}
          >
            <PaperAirplaneIcon className="w-6 h-6 text-white" />
          </Shortcut>
        </div>
      )}
    </div>
  );
};
export const Tooltip = styled.div`
  display: none;
`;
export const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

export default Input;
