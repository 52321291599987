import React, { useEffect, useState } from 'react';
import Button from './Button';
import { observer, inject } from 'mobx-react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';

import TutorialModal from './TutorialModal';
import QualifyExperience from './QualifyExperience';

const ShortModal = inject('store')(
  observer(({ mode, onInteraction }) => {
    const [openDialogTutorial, setOpenDialogTutorial] = useState(false);
    const [modeDialogTutorial, setModeDialogTutorial] = useState(false);

    const { theme } = useThemeStore();

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50 font-figtree">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-raisin-black opacity-75"></div>
          </div>

          <div
            className={`relative ${
              theme == 'dark'
                ? 'dark:bg-lead dark:text-crystal-bell'
                : 'bg-seasalt text-raisin-black'
            } ounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col`}
          >
            <div className="px-6 overflow-auto relative">
              <button
                onClick={() => {
                  onInteraction('close');
                }}
                className="absolute right-1 top-2 p-1 hover:bg-lavender text-cool-gray text-sm rounded-md dark:text-quicksilver dark:hover:bg-ship-grey"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>

              <div className="dialog-content-container overflow-auto pr-2 mt-8">
                <div className="font-medium text-xl text-center">
                  🚀 Discover Straico in 30 Seconds
                </div>
                <br></br>
                <div className="flex justify-center items-center">
                  <iframe
                    width="315"
                    height="560"
                    src="https://www.youtube.com/embed/lCFmYo0A8Go?autoplay=1&loop=1&showinfo=0"
                    title="Straico"
                    allowfullscreen
                  ></iframe>
                </div>
                <br></br>
                <div className="font-normal text-cool-gray text-justify dark:text-crystal-bell">
                  Watch this short video to get a quick overview of how Straico
                  works! Learn how our platform empowers you with AI tools and
                  simplifies the complex world of AI.
                </div>
                <br></br>
                <div className="font-normal text-cool-gray text-justify dark:text-crystal-bell">
                  Want to explore more? Visit our YouTube channel for in-depth
                  tutorials and demonstrations.
                </div>
                <br></br>
                <div className="font-normal text-cool-gray text-center dark:text-crystal-bell">
                  Watch more on
                </div>
                <br></br>
              </div>
            </div>
            <div className="flex justify-center w-full pb-6">
              <a
                href="https://www.youtube.com/@Straico/videos"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <button className="flex items-center justify-center px-4 py-4 rounded-md hover:bg-lavender dark:hover:bg-ship-grey text-cool-gray dark:text-quicksilver dark:border-sonic-silver text-sm border border-platinum">
                  <img className="h-7 px-1" src="/youtube-logo.svg" />
                </button>
              </a>
            </div>
          </div>
        </div>

        {openDialogTutorial ? (
          <TutorialModal
            mode={modeDialogTutorial}
            onInteraction={onInteraction}
          ></TutorialModal>
        ) : (
          <></>
        )}
      </>
    );
  })
);

export default ShortModal;
