function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill="#5269BC" />
      <g clipPath="url(#clip0_325_16174)">
        <path
          d="M4.94422 13.9162C4.94422 14.6073 5.09508 15.1357 5.29225 15.4561C5.39733 15.6385 5.54864 15.7898 5.73081 15.8945C5.91298 15.9992 6.11953 16.0537 6.32946 16.0524C6.83649 16.0524 7.30032 15.9267 8.19419 14.6852C8.91028 13.6907 9.75407 12.2978 10.3224 11.4182L11.2839 9.9355C11.9518 8.90576 12.7236 7.76105 13.6112 6.98262C14.3342 6.35183 15.1148 6 15.9003 6C17.2192 6 18.4755 6.76712 19.437 8.20586C20.4892 9.78157 21.0019 11.7663 21.0019 13.8144C21.0019 15.032 20.7628 15.9267 20.3559 16.6335C19.9634 17.3171 19.1947 18 17.9059 18V16.0524C19.0095 16.0524 19.283 15.0346 19.283 13.8697C19.283 12.2098 18.8974 10.3677 18.048 9.05152C17.4452 8.11791 16.664 7.54743 15.8046 7.54743C14.875 7.54743 14.127 8.2511 13.2863 9.50576C12.8394 10.1724 12.3806 10.9847 11.8654 11.9014L11.2983 12.9066C10.1591 14.934 9.8705 15.3958 9.30088 16.1579C8.30435 17.4974 7.4518 18 6.32946 18C4.99805 18 4.15614 17.4214 3.63785 16.5493C3.20907 15.8387 3 14.9064 3 13.844L4.94422 13.9162Z"
          fill="white"
        />
        <path
          d="M4.53296 8.34346C5.42432 6.9644 6.71066 6 8.18604 6C9.04047 6 9.88989 6.25131 10.7769 6.98073C11.7471 7.7755 12.7799 9.08419 14.0713 11.241L14.5339 12.0151C15.6506 13.8823 16.2865 14.8423 16.6577 15.2959C17.136 15.8777 17.4715 16.0498 17.9059 16.0498C19.0095 16.0498 19.283 15.032 19.283 13.8672L20.9981 13.8132C20.9981 15.0308 20.759 15.9254 20.3521 16.6323C19.9634 17.3171 19.1947 18 17.9059 18C17.1047 18 16.3948 17.8253 15.6099 17.0821C15.0065 16.5116 14.301 15.4982 13.7583 14.5872L12.144 11.8806C11.334 10.5223 10.591 9.50953 10.1609 9.0534C9.69773 8.5577 9.10369 7.96209 8.15474 7.96209C7.38669 7.96209 6.73445 8.50304 6.18861 9.33047L4.53296 8.34346Z"
          fill="white"
        />
        <path
          d="M8.15475 7.96209C7.3867 7.96209 6.73446 8.50304 6.18862 9.33047C5.41682 10.4997 4.94422 12.2413 4.94422 13.9162C4.94422 14.6073 5.09508 15.1357 5.29225 15.4561L3.63472 16.5493C3.20907 15.8387 3 14.9064 3 13.844C3 11.912 3.52831 9.89843 4.53297 8.34346C5.42433 6.9644 6.71067 6 8.18605 6L8.15475 7.96209Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_16174">
          <rect
            width="18"
            height="12"
            fill="white"
            transform="translate(3 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
