import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// Icons
import { PencilSquareIcon, ShareIcon } from '@heroicons/react/24/outline';
import { CheckIcon, LinkIcon } from '@heroicons/react/24/solid';
// Hooks
import useModal from 'Hooks/useModal';
// config
import config from 'config';
// Components
import Modal from 'Components/Common/Modal/Modal';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import ShareInput from 'Components/Common/ShareInput/ShareInput';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import useThemeStore from 'Theme/store';
import ChatMessage from 'Components/Common/Markdown/ChatMessage';
import { formatedDate } from '../../utils/format';

const ShareChat = inject('store')(
  observer(({ store, chat, avatar }) => {
    const { copy } = useClipboardComponent();
    const { isOpen, closeModal, toggleModal } = useModal();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(chat.title);
    const [showEditDialog, setShowEditDialog] = useState(false);
    //
    useEffect(() => {
      setTitle(chat.title);
    }, [chat]);
    const [shareLink, setShareLink] = useState(null);
    useEffect(() => {
      setShareLink(null);
    }, [isOpen]);
    // modify share state chat
    const onShare = async () => {
      setLoading(true);
      try {
        const lastMessage = Array.isArray(chat.messages[0])
          ? chat.messages[0].filter((m) => m.selected == true)
          : [chat.messages[0]];
        const res = await store.api.post('/shareChats', {
          title: title === '' ? chat.title : title,
          chat_id: chat._id,
          message_id: lastMessage[0]._id,
        });
        const data = await res.data;
        let link = `${config.baseFrontUrl}/share/chat/${data.share_id}`;
        if (store.profile.nickname_fpr)
          link = `${link}?fpr=${store.profile.nickname_fpr}`;
        setShareLink(link);
        copy(link);
        toast.success('Chat shared successfully');
      } catch (err) {
        toast.error('Error sharing chat');
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    // handle edit title
    const toggleTittle = () => {
      setShowEditDialog(!showEditDialog);
    };
    //handle change title
    const handleChangeTitle = (e) => {
      setTitle(e.target.value);
    };

    const {
      setOpenShareChatModal,
      openShareChatModal: openShareChatModalTree,
    } = useTreeDNDChatStore();

    useEffect(() => {
      if (openShareChatModalTree) {
        toggleModal();
        setOpenShareChatModal(false);
      }
    }, [openShareChatModalTree]);

    const { theme } = useThemeStore();

    // return
    return (
      <>
        <div
          className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white cursor-pointer"
          onClick={toggleModal}
        >
          <ShareIcon className={'h-5 w-5'} />
        </div>
        <Modal
          open={isOpen}
          title="Share chat"
          iconTitle={
            <ShareIcon
              className="h-5 w-5 text-raisin-black"
              aria-hidden="true"
            />
          }
          onClose={closeModal}
        >
          <div className="my-4 font-barlow">
            <div className="dialog-content-container-grade overflow-auto pr-2">
              <div className="mb-2">
                <p className="text-raisin-black dark:text-crystal-bell">
                  Anyone with the URL will be able to see the chat up to this
                  point. Further messages you send after creating the link will
                  remain private.
                </p>
              </div>
            </div>
            <div className="flex text-violet-blue bg-lavender h-8 rounded mt-4">
              <div className="w-full flex mx-4 items-center">
                <div className="flex-grow truncate w-40">
                  {!showEditDialog ? (
                    <p className={`text-base font-medium`}>{title}</p>
                  ) : (
                    <input
                      className="border w-full rounded bg-transparent"
                      value={title}
                      onChange={handleChangeTitle}
                    />
                  )}
                </div>
              </div>
              <div
                className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white cursor-pointer"
                onClick={toggleTittle}
              >
                {!showEditDialog ? (
                  <PencilSquareIcon className={'h-5 w-5'} />
                ) : (
                  <CheckIcon className={'h-5 w-5'} />
                )}
              </div>
            </div>
            <div
              className={`mb-3 h-[250px] overflow-scroll ${
                theme == 'dark' ? 'dark:bg-lead' : 'bg-seasalt'
              }   rounded-b-2xl shadow-sm flex flex-col-reverse`}
            >
              {chat.messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  item={message}
                  formatedDate={formatedDate}
                  readOnlyMessage={true}
                  chatId={chat._id}
                  avatar={avatar}
                />
              ))}
            </div>
            {shareLink && (
              <div className="my-4">
                <ShareInput shareLink={shareLink} />
              </div>
            )}
            <div className="flex justify-center mt-4">
              <LoadingButton
                loading={loading}
                onClick={onShare}
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  color: 'white',
                  fontWeight: 'bold',
                  minWidth: '200px',
                  fontSize: '18px',
                  fontFamily: 'lexend deca',
                  textTransform: 'uppercase',
                }}
                startIcon={<LinkIcon className="h-5 w-5" />}
              >
                Copy Link
              </LoadingButton>
            </div>
            <p className="pt-6 text-raisin-black dark:text-crystal-bell">
              🚀 Boost sign-ups and earn with every share! Join{' '}
              <strong>Straico's affiliate program</strong> and convert your
              shares into a revenue stream. 💰{' '}
              <a
                className="text-violet-blue cursor-pointer"
                href="https://straico.com/affiliate/"
                target="__blank"
              >
                Start here!
              </a>
            </p>
          </div>
        </Modal>
      </>
    );
  })
);

export default ShareChat;
