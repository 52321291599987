import { forwardRef, useEffect, useState } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import ModelsIcon from '../../../Theme/icons/modelsIcon';
import TemplatesIcon from '../../../Theme/icons/templatesIcon';
import * as modelIcons from '../../../Theme/icons/modelsIcons/index';
import {
  PlusCircleIcon,
  ChatBubbleLeftRightIcon,
  StarIcon,
  PhotoIcon,
  PencilSquareIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  TrophyIcon,
  CreditCardIcon,
  ArrowsRightLeftIcon,
  BookOpenIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Components
import ConversationSide from 'Components/Chat/ConversationSide/ConversationSide';
import useChatStore, {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  LOADING_FAV_TOOLS,
} from 'Components/Chat/store';
import { useChat } from '../../../Context/Chat/Chat.context';
import PromptSide from 'Components/Chat/PromptSide/PromptSide';
import ToggleTheme from 'Components/Common/ToggleTheme';
import Loader from 'Components/Loader';

const MobilePowerPanel = forwardRef(
  ({ store, onClose, setOpenDialogDiscord }, ref) => {
    const { theme: themeValue } = useThemeStore();

    const [openModels, setOpenModels] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [openSettings, setOpenSettings] = useState(false);
    const [loadingFavTools, setLoadingFavTools] = useState(false);
    const [favTools, setFavTools] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const loadingChats = useChatStore((state) => state.loadingChats);
    const selectedChat = useChatStore((state) => state.selectedChat);
    const chatList = useChatStore((state) => state.chatList);
    const selectedModels = useChatStore((state) => state.selectedModels);
    const setShowModelsDialog = useChatStore(
      (state) => state.setShowModelsDialog
    );
    const addEvent = useChatStore((state) => state.addEvent);
    const currentEvent = useChatStore((state) => state.currentEvent);
    const runEvent = useChatStore((state) => state.runEvent);

    const {
      setSelectedTool,
      toggleShowPromptModal,
      setShowPromptList,
      sidebarTools,
    } = useChat();

    const baseAccordionStyles = () => {
      return {
        backgroundColor: themeValue == 'dark' ? '#1D1C1E' : '#EDEDF2',
        color: '#9091A4',
        '&:before': {
          display: 'none',
        },
        overflow: 'hidden',
      };
    };

    const accordionStyles = () => {
      return {
        ...baseAccordionStyles(),
        '&.Mui-expanded': {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
        '& > .MuiCollapse-root': {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 266px)',
        },
        '& > .MuiCollapse-root > .MuiCollapse-wrapper': {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
        '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner':
          {
            flex: '1 1 0%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          },
        '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region':
          {
            flex: '1 1 0%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          },
        '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region > .MuiAccordionDetails-root':
          {
            flex: '1 1 0%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          },
      };
    };

    const getIcon = (icon) => {
      const Icon = get(modelIcons, icon);
      return Icon ? (
        <Icon className="h-6 w-6" />
      ) : (
        <ModelsIcon className="h-6 w-6 stroke-cool-gray" />
      );
    };

    const handleAccordionChange = (accordionId) => (event, isExpanded) => {
      navigate('/chat');
      setOpenSettings(false);
      setOpenAccordion(isExpanded ? accordionId : false);
    };

    const getImageProfile = () => store.profile.avatar.svg;

    const handleOpenSettings = () => {
      setOpenModels(false);
      setOpenAccordion(null);
      setOpenSettings(!openSettings);
    };

    const sortArrayAlphabetically = (array) => {
      return array.sort((a, b) => a.title.localeCompare(b.title));
    };

    const fetchFavs = async () => {
      try {
        setLoadingFavTools(true);
        const userId = store.profile._id;
        const response = await store.api.get(`/tool/favorite-tools/${userId}`);
        setLoadingFavTools(false);
        if (response.data && response.data.tools) {
          setFavTools(sortArrayAlphabetically(response.data.tools));
        } else {
          // Handle scenario when no tools are found or response is not as expected
          console.error(
            'No favorite tools found or unexpected format:',
            response
          );
        }
      } catch (error) {
        console.error('Failed to fetch favorite tools:', error);
        setLoadingFavTools(false);
      }
    };

    const handleSelectPrompt = (id) => {
      const selectedToolLocal = sidebarTools.filter(
        (tool) => tool?._id === id
      )[0];
      setSelectedTool(selectedToolLocal);
      toggleShowPromptModal();
      setShowPromptList(false);
    };

    const onClickTemplate = (tool) => {
      handleSelectPrompt(tool?._id);
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('selectedTool', tool?._id);
      navigate(
        {
          pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
          search: urlParams.toString(),
        },
        { shallow: true }
      );
    };

    useEffect(() => {
      fetchFavs();
    }, []);

    useEffect(() => {
      if (currentEvent && currentEvent.name == LOADING_FAV_TOOLS) {
        runEvent(fetchFavs);
      }
    }, [currentEvent]);

    return (
      <motion.div
        initial={{ x: -320, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -320, opacity: 0 }}
        ref={ref}
        className="absolute top-0 left-0 h-full z-50 pt-5 flex flex-col items-center bg-ghost-white dark:bg-umbra duration-2000 w-80"
      >
        <div className="w-full mb-4 flex items-center justify-between px-5">
          <div onClick={handleOpenSettings}>
            {store.profile.avatar.svg ? (
              <div className="avatar">
                <div className="w-12 rounded">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getImageProfile(),
                    }}
                  />
                </div>
              </div>
            ) : (
              <UserCircleIcon
                className={`w-12 h-12 transition ${
                  themeValue == 'dark'
                    ? 'dark:text-palladium'
                    : 'text-cool-gray'
                }`}
              />
            )}
          </div>
          <div
            className="flex items-center justify-center gap-2 px-2 h-[38px] text-white bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl select-none"
            onClick={() => {
              addEvent(EVENT_NEW_CHAT);
              onClose();
            }}
          >
            <PlusCircleIcon className="h-[21px] w-[21px]" />
            <span>New chat</span>
          </div>
          <div
            className="w-8 h-8 flex justify-center items-center rounded-xl cursor-pointer select-none"
            onClick={() => {
              navigate('/images');
              onClose();
            }}
          >
            <PhotoIcon className="w-[46px] h-[46px] text-cool-gray" />
          </div>
        </div>

        {openSettings && (
          <motion.div
            initial={{ y: -242, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -242, opacity: 0 }}
            className={`${
              themeValue == 'dark' ? 'bg-umbra' : 'bg-ghost-white'
            } w-full px-5`}
          >
            <div className="flex justify-around">
              <button
                onClick={() => {
                  setOpenDialogDiscord(true);
                  onClose();
                }}
                className={`flex items-center justify-center px-1 rounded-md text-sm ${
                  themeValue == 'dark'
                    ? 'hover:bg-ship-grey text-crystal-bell'
                    : 'hover:bg-lavender text-cool-gray'
                }`}
              >
                <img
                  className="h-6 px-1 block"
                  src={
                    themeValue == 'dark'
                      ? '/discord-mark-white.svg'
                      : '/discord-mark-blue.svg'
                  }
                />
              </button>
              <ToggleTheme />
              <a
                href="https://straico.com/faq/"
                target="_blank"
                rel="noreferrer"
                className="flex "
                onClick={onClose}
              >
                <button
                  className={`flex items-center justify-center px-1 rounded-md ${
                    themeValue == 'dark'
                      ? 'text-crystal-bell hover:bg-ship-grey'
                      : 'text-cool-gray hover:bg-ship-grey'
                  }  text-sm`}
                >
                  <QuestionMarkCircleIcon className="w-7 h-7 mx-1" />
                </button>
              </a>
            </div>
            <div className="bg-cool-gray h-[3px] w-auto mx-8 my-3 rounded-full"></div>
            <div className="text-cool-gray text-sm leading-6">
              <NavLink
                to="/my-profile"
                onClick={onClose}
                className={({ isActive }) =>
                  `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                    isActive
                      ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                      : `${
                          themeValue == 'dark'
                            ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                            : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                        }`
                  }`
                }
                end
              >
                <li className="flex items-center justify-center">
                  {store.profile.avatar.svg ? (
                    <div className="avatar">
                      <div className="w-6 rounded">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: store.profile.avatar.svg,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <UserCircleIcon
                      className={`w-5 h-5 transition`}
                      to="/my-profile"
                    />
                  )}
                  <span className="origin-left duration-200 ml-2">
                    My profile
                  </span>
                </li>
              </NavLink>

              <NavLink
                to="/transactions"
                onClick={onClose}
                className={({ isActive }) =>
                  `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                    isActive
                      ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                      : `${
                          themeValue == 'dark'
                            ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                            : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                        }`
                  }`
                }
              >
                <li className="flex items-center justify-center">
                  <ArrowsRightLeftIcon
                    className={`w-5 h-5 transition`}
                    to="/transactions"
                  />
                  <span className="origin-left duration-200 ml-2">
                    Transactions
                  </span>
                </li>
              </NavLink>

              <NavLink
                to="/my-profile/pricing"
                onClick={onClose}
                end
                className={({ isActive }) =>
                  `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                    isActive
                      ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                      : `${
                          themeValue == 'dark'
                            ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                            : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                        }`
                  }`
                }
              >
                <li className="flex items-center justify-center">
                  <CreditCardIcon
                    to="/my-profile/pricing"
                    end
                    className={`w-5 h-5 transition`}
                  />
                  <span className="origin-left duration-200 ml-2">
                    Billing and plans
                  </span>
                </li>
              </NavLink>

              <NavLink
                to="/rewards"
                onClick={onClose}
                className={({ isActive }) =>
                  `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                    isActive
                      ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                      : `${
                          themeValue == 'dark'
                            ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                            : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                        }`
                  }`
                }
              >
                <li className="flex items-center justify-center">
                  <TrophyIcon className={`w-5 h-5 transition`} to="/rewards" />
                  <span className="origin-left duration-200 ml-2">Rewards</span>
                </li>
              </NavLink>
            </div>
            <div className="bg-cool-gray h-[3px] w-auto mx-8 my-3 rounded-full"></div>
          </motion.div>
        )}

        <div className="w-full h-full flex-1 flex flex-col overflow-auto">
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={baseAccordionStyles()}
            expanded={openModels}
            onChange={(event, isExpanded) => {
              setOpenModels(isExpanded);
              setOpenSettings(false);
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="models-content"
              id="models-header"
            >
              <ModelsIcon className="h-[21px] w-[21px] stroke-cool-gray mr-3" />
              <div className="flex-1">Current models</div>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className="flex gap-2 items-center">
                <div className="flex-1 flex flex-col gap-2 bg-white dark:bg-night-black rounded-xl p-2">
                  {selectedModels.map((model, index) => (
                    <div key={index} className="flex gap-2">
                      {getIcon(model.icon)}
                      <span className="flex-1 line-clamp-1">
                        {model.abbreviation}
                      </span>
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => {
                    navigate('/chat');
                    onClose();
                    setShowModelsDialog(true);
                  }}
                  className="w-8 h-8 flex justify-center items-center bg-lavender dark:bg-ship-grey rounded-xl cursor-pointer select-none"
                >
                  <PencilSquareIcon className="w-[18px] h-[18px] text-violet-blue" />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'chats'}
            onChange={handleAccordionChange('chats')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="chats-content"
              id="chats-header"
            >
              <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] mr-3" />
              <div className="flex-1">My chats</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <motion.div className="z-10 h-full relative flex-1 flex flex-col overflow-auto">
                <ConversationSide
                  show={true}
                  onClick={() => {
                    addEvent(EVENT_NEW_CHAT);
                    onClose();
                  }}
                  onSelectChat={(chat) => {
                    addEvent(EVENT_SELECT_CHAT, chat);
                    onClose();
                  }}
                  loading={loadingChats}
                  chatSelectedIndex={selectedChat?.index}
                  chats={chatList}
                  currentChatId={selectedChat?.chat}
                  newDesing
                />
              </motion.div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'templates'}
            onChange={handleAccordionChange('templates')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="tamplates-content"
              id="tamplates-header"
            >
              <TemplatesIcon className="h-[21px] w-[21px] stroke-cool-gray mr-3" />
              <div className="flex-1">Templates</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <PromptSide newDesing />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={accordionStyles()}
            expanded={openAccordion === 'favorites'}
            onChange={handleAccordionChange('favorites')}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: '0.9rem', color: '#9091A4' }} />
              }
              aria-controls="favotites-content"
              id="favotites-header"
            >
              <StarIcon className="h-[21px] w-[21px] mr-3" />
              <div className="flex-1">Favorite Templates</div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="flex-1 h-full flex flex-col">
                {loadingFavTools ? (
                  <div className="flex items-center justify-center h-full">
                    <Loader />
                  </div>
                ) : favTools.length > 0 ? (
                  <>
                    <div className="px-5">
                      <input
                        id="keyword"
                        className="dark:bg-night-black dark:text-crystal-bell bg-white text-raisin-black text-base focus:outline-none w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray"
                        autoComplete="off"
                        type="text"
                        name="keyword"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="flex-1 overflow-auto px-5">
                      {favTools
                        .filter((tool) =>
                          tool.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((tool) => (
                          <div
                            key={tool._id}
                            className="flex cursor-pointer select-none items-start hover:bg-lavender gap-2 p-1 group rounded"
                            onClick={() => onClickTemplate(tool)}
                          >
                            <BookOpenIcon className="w-5 h-5 mt-2" />
                            <div className="flex-1">
                              {tool.title}
                              {tool?.multi_default_models?.length > 1 ? (
                                <div className={'flex items-center'}>
                                  <Tooltip
                                    title={
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: tool.multi_default_models
                                            .map((model) => model.name)
                                            .join('<br>'),
                                        }}
                                      />
                                    }
                                    arrow
                                  >
                                    <p className="flex items-center font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                      {tool?.multi_default_models[0]?.name} +{' '}
                                      {tool?.multi_default_models?.length - 1}
                                      <SparklesIcon className="w-[10px] h-[10px] mr-1 ml-1" />
                                    </p>
                                  </Tooltip>
                                </div>
                              ) : tool?.multi_default_models?.length == 1 ? (
                                <>
                                  <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                    {tool?.multi_default_models[0]?.name}
                                  </p>
                                </>
                              ) : tool?.default_model ? (
                                <>
                                  <p className="font-normal uppercase group-hover:bg-violet-blue group-hover:text-white bg-lavender px-2 rounded-md text-[9px] leading-3 w-fit dark:bg-ship-grey dark:text-tropical-indigo">
                                    {tool?.default_model?.name}
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="px-5 text-center">
                    You don't have favorite templates.
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </motion.div>
    );
  }
);

MobilePowerPanel.displayName = 'MobilePowerPanel';

export default inject('store')(observer(MobilePowerPanel));
